import axios from "./axiosConfig";

export async function domainVerifyLogin(data) {
    try {
        const response = await axios.post("/api/v1/tenant-verification", data);
        return response.data;
    } catch (error) {
        if (error.data.message) {
            throw new Error(error.data.message || "Domain verification failed.");
        } else if (error) {
            throw new Error("No response from the server. Please check your connection.");
        } else {
            throw new Error(error.data.message || "An error occurred.");
        }
    }
}
