import { useEffect, useRef } from 'react';

/**
 * Use interval hook.
 * @param callback
 * @param delay
 */
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(
        () => {
            savedCallback.current = callback;
        },
        [ callback ]
    );

    useEffect(
        () => {
            const handler = (...args) => savedCallback.current(...args);

            if (delay !== null) {
                const id = setInterval(handler, delay);
                return () => clearInterval(id);
            }
        },
        [ delay ]
    );
};

export default useInterval;
