import React, { useEffect, useState } from "react";
import { ReactComponent as QuizIcon } from "../../assets/images/quiz.svg";
import { ReactComponent as PDFIcon } from "../../assets/images/test.svg";
import { Document, Page, pdfjs } from "react-pdf";
import { ReactComponent as VideoIcon } from "../../assets/images/video.svg";
import greenTick from "../../assets/images/greenTick.png";
import progressCourse from "../../assets/images/progressCourse.png";
import { ReactComponent as TextIcon } from "../../assets/images/textIcon.svg"
import "./SelfLearning.scss";
import { Box, Button, Modal } from '@material-ui/core';
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions/selfLearningVideoUrl";
import { useDispatch } from "react-redux";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import ClearIcon from '@material-ui/icons/Clear';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getProgressBarDetails } from "examsbook-react-sdk/actions/getSelfLearningAction";
import { ReactComponent as InProgress } from "../../assets/images/progrss.svg";
import { ColorsConstant } from "../../constants/colorConstant";
export default function SelfLearningSubmenu(props) {
  let subMenuName = props;
  let iconName = props?.data?.refModel;
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPath, setpdfPath] = useState("");
  const [openpdf, setOpenpdf] = useState(false);
  const handleOpenPdf = () => setOpenpdf(true);
  const handleClosePdf = () => setOpenpdf(false);
  const t = useTranslations();

  const openPdfViewer = (path) => {
    setpdfPath(path);
    handleOpenPdf();
  };
  const closePdfViewer = () => {
    setpdfPath("");
    setNumPages(null);
    setPageNumber(1);
    handleClosePdf();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });

  const handleGreenTick = (data) => {
    if (data?.isRead && data.percent === 100) {
      return <Box component="img" src={greenTick} alt="Completed" />;
    }
     else {
      return (
        <Box>
        </Box>
      );
    }
  };

  const handleOnClick = (id) => {
    props.setSelectedSubmenu(id);
    if (props?.data?.refModel === "RecordedVideo") {
      dispatch(selfLearningVideoUrl(subMenuName));
      props.setSectionChanged(false);
    } else if (props?.data?.refModel === "TextNotes") {
      // TODO need to implement it after course tracking
    } else if (props?.data?.refModel === "Questions") {
      dispatch(selfLearningVideoUrl(subMenuName));
      let data = {
        sectionId: props?.sectionId,
        dataId: props?.data?._id,
        percent: 100,
        courseId: props?.section?.data?.courseId?._id
      }
      dispatch(getProgressBarDetails(data))
    } else if (props?.data?.refModel === "PdfNotes") {
      let data = {
        sectionId: props?.sectionId,
        dataId: props?.data?._id,
        percent: 100,
        courseId: props?.section?.data?.courseId?._id
      }
      dispatch(getProgressBarDetails(data))
      props.onPdfOpen(!props?.pdfOpen);
      openPdfViewer(props?.data?.contentData?.featured);
    }
    if (props.onSubmenuClick) {
      props.onSubmenuClick(props);
    }
  };

  return (
    <>
      <div
        id={props?.index}
        className="outerBox2 submenu isHover"
        style={{
          backgroundColor: props?.selectedSubmenu === props?.data?._id ? ColorsConstant.backgroundHighlight : 'transparent'
        }}
      onClick={() => handleOnClick(props?.data?._id)}
      >
        <div className="innerBox">
          <div className="submenu-image">
            {iconName === "Quiz" && <QuizIcon />}
            {iconName === "RecordedVideo" && <VideoIcon />}
            {iconName === "PdfNotes" && <PDFIcon />}
            {iconName === "TextNotes" && <TextIcon />}
            {iconName === "Questions" && <QuizIcon />}
          </div>
          <p className={classes.genustext}>{props?.data?.name}</p>
        <Box>{handleGreenTick(props?.data)}</Box>
        </div>
      </div>
      <Modal
        open={openpdf}
        onClose={closePdfViewer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "scroll",
          zIndex: "10000",
        }}
      >
        <div style={{ display: 'block', margin: 'auto', position: 'relative' }}>
          <Document
            file={pdfPath}
            onLoadSuccess={onDocumentLoadSuccess}
            error={
              <div className={classes.Loader}>
                <ClearIcon style={{ marginLeft: 'auto', display: "block", cursor: 'pointer' }} onClick={closePdfViewer} />
                <p className={classes.errorText}>{t("Failed_to_load_pdf_file")}</p>
              </div>
            }
            loadinguseEffect={
              <div className={classes.Loader}>
                <p>{t("Loading...")}</p>
              </div>
            }
          >
            <ClearIcon className={classes.clearIcon} onClick={closePdfViewer} />
            <Page pageNumber={pageNumber} width={window.innerWidth * 0.9} />
            {numPages ? (
              <div className={classes.btnBox}>
                <div className={classes.btnBoxInside}>
                  <Button
                    disabled={pageNumber === 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    className={
                      pageNumber === 1 ? classes.disableBtn : classes.enableBtn
                    }
                  >
                    <NavigateBeforeIcon style={{ fontSize: "24px" }} />
                  </Button>
                  <p className={classes.page}>
                    {pageNumber} of {numPages}
                  </p>
                  <Button
                    disabled={pageNumber === numPages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    className={
                      pageNumber === numPages
                        ? classes.disableBtn
                        : classes.enableBtn
                    }
                  >
                    <NavigateNextIcon style={{ fontSize: "24px" }} />
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Document>
        </div>
      </Modal>
    </>
  );
}

const styles = {
  disableBtn: {
    color: "black",
    padding: "10px",
  },
  errorText: {
    textAlign: "center",
    fontSize: "16px",
  },
  enableBtn: {
    background: "white",
    color: "black",
    padding: "10px",
    "&:hover": {
      background: "#E6E6E6",
      color: "black",
    },
  },
  genustext: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    gap: '10px',
    width: '-webkit-fill-available',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.7',
    fontWeight: 400
  },
  btnBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
    position: "sticky",
    bottom: "5%",
  },
  btnBoxInside: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    background: "white",
    boxShadow: "0px 0px 20px 0 rgb(16 36 94 / 30%)",
    borderRadius: "8px",
  },
  page: {
    fontSize: "15px",
    margin: "0px 10px",
  },
  Loader: {
    background: "white",
    borderRadius: '8px',
    minHeight: "100px",
    minWidth: "300px"

  },
  crossIcon: {
    color: 'color',
    background: 'white',
    fontWeight: 'bold',
    margin: '5px 0px 10px 0px',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    padding: '5px',
    "&:hover": {
      background: "#5F4BAB",
      color: 'white'
    },
  },
  clearIcon: {
    cursor: 'pointer',
    zIndex: '999',
    display: 'block',
    position: 'absolute',
    marginLeft: 'auto',
    right: '10px',
    top: '10px',
    borderRadius: '12px',
    border: '2px solid black'
  }
};
