import { isUrlAbsolute } from "../common";
import status from "../constants/actionStatus";

export default (
    state = {
        courses: [],
    },
    action = {}
) => {
    switch (action.type) {
    case "FILTERED_COURSES_REQUEST":
        return {
            status: status.PENDING,
            courses: [],
        };
    case "FILTERED_COURSES_SUCCESS":
        return {
            status: status.SUCCESS,
            courses: action.payload.map((course) => {
                return {
                    ...course,
                    featuredMobile: isUrlAbsolute(course.featured.thumbnail.jpg)
                        ? course.featured.thumbnail.jpg
                        : '', //we need to see if this is even used.
                };
            }),
        };

    case "FILTERED_COURSES_FAILURE":
        return {
            status: status.ERROR,
            courses: [],
        };
    default:
        return state;
    }
};
