import { useEffect } from "react";
import {
    changeQuizViewMode,
    getQuiz,
    getQuizResponse,
    getUser,
} from "examsbook-react-sdk/actions";
import { useDispatch, useSelector } from "react-redux";

/**
 * Get data of single quiz for quiz player.
 * @param slug
 * @param viewMode
 * @returns {*}
 */
export default function useQuizData(slug, viewMode, quizMode) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeQuizViewMode(viewMode));
    }, [ viewMode ]);

    //quiz response and get data get.
    useEffect(() => {
        // dispatch(getUser());
        if (viewMode) {
            dispatch(getQuizResponse(slug));
        } else {
            dispatch(getQuiz(slug, quizMode));
        }
    }, [ slug, viewMode ]);
    const quizData = useSelector((state) => state.quiz);
    return quizData;
}
