import { addMyCourse } from "../services/courseService";

export const addCourse = (data) => (dispatch) => {
    dispatch(request());
    return addMyCourse(data)
        .then((response) => {
            // handle success
            return dispatch(success(response));
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "ADD_MY_COURSE_REQUEST" };
    }

    function success(response) {
        return { type: "ADD_MY_COURSE_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "ADD_MY_COURSE_FAILURE", payload: error };
    }
};
