import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import hn from "./locales/hn.json";

// the translations
const resources = {
    en: {
        translation: en,
    },
    hn: {
        translation: hn,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
