const initialState = {
    "duration": 0,
    "timeTaken": 0,
    "init": false
};

export default (state = initialState, action = {}) => {
    if (action.type === 'INIT_TIMER') {
        return {
            duration: action.duration,
            timeTaken: action.timeTaken,
            init: true
        };
    } else if (action.type === 'CLEAR_TIMER') {
        return {
            duration: 0,
            timeTaken: 0,
            init: false
        };
    } else if (action.type === 'TICK_SECOND') {
        return {
            duration: state.duration,
            timeTaken: state.timeTaken + 1,
            init: true
        };
    }
    return state;
}
