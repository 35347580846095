import React, { useEffect, useState } from 'react';
import '../common/cardView.scss';
import './quizCard.scss';
import { useHistory } from "react-router-dom";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useTranslations, useQuizButtonStatus, useStyles, useQuizData } from "examsbook-react-sdk/custom-hooks";
import { ReactComponent as TestImg } from "../../assets/images/evaluationTestIcon.svg";
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '../../constants/themeProvider';
import { ReactComponent as TryImage } from "../../assets/images/tryImage.svg";
import OverlayButton from '../../constants/OverlayButton';
import InstructionBlock from '../Quiz/InstructionBlock/InstructionBlock';

import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { useDispatch } from 'react-redux';

function QuizCard(props) {
    const { quizData, singleTestSeriesLoading, onQuizCardClick, startQuiz, course_id } = props;
    const t = useTranslations();
    const history = useHistory();
    const [isModalVisible, setModalVisible] = useState(false);
    const { disabled, action } = useQuizButtonStatus(quizData);
    const bestScore = quizData.quizAttempts !== 0 ? Math.max(...quizData.testScore) : 0
    const theme = useTheme();

    const styles = {
        startBtn: {
            textTransform: 'capitalize',
            fontWeight: '600',
            "&:hover": {
              background: "#0c3450e0 !important",
            }
        },
        viewResult: {
            textTransform: 'capitalize',
            fontWeight: '600',
            background: 'transparent !important',
            border: `1px solid ${theme?.primaryColor}`,
            borderRadius: '4px',
            color: `${theme?.primaryColor}`,
            "&:hover": {
                background: "#e7e7e7 !important",
              }
        }
    }
    const classes = useStyles(styles)();
    const [dynamicSeoSettingsData, setDynamicSeoData] = useState([]);
    const [defaultSeoSettingsData, setDefaultSupportData] = useState([]);
    const [quizAttemptsCount, setQuizAttemptsCount] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        getDynamicSeoSettingApiData();
        getDefaultSeoSettingApiData();
    }, []);
   
    const getDynamicSeoSettingApiData = async () => {
        try {
            dispatch(getSupportAction()).then((res) => {
              setDynamicSeoData(res);
            });
        } catch (error) {
          console.log('Error -', error.message);
        }
    };
    
    const getDefaultSeoSettingApiData = async () => {
        try {
          dispatch(getDefaultSupportDataAction()).then((res) => {
            setDefaultSupportData(res);
          });
        } catch (error) {
          console.error(error.message);
        }
    };

    useEffect(() => {
        const defaultQuizAttemptsCount = defaultSeoSettingsData?.data?.['Quiz Attempts'][0]?.defaultValue;
        const dynamicQuizAttemptsCount = dynamicSeoSettingsData?.data?.find(item =>  item.key === 'Quiz Attempts')?.value;       
        setQuizAttemptsCount(dynamicQuizAttemptsCount ? dynamicQuizAttemptsCount : defaultQuizAttemptsCount);
    },[defaultSeoSettingsData, dynamicSeoSettingsData]);

    const hideModal = () => {
        setModalVisible(false);
    };

    function handleClickAction() {
        if (action === 'PAYMENT') {
            history.push("/payments");
        }
        else if (action === 'START') {
            if (quizData.quizAttempts >=  quizAttemptsCount) {
                setModalVisible(true);
            }
            else {
                props.showInstructionScreen();
            onQuizCardClick(quizData.testId);
            reactGaEvent("Live Test", "Start Quiz");
            }
        } else {
            history.push('/tests/result/' + quizData._id);
        }
    }

    const QuizSucessful = () => {
        return (
            <Box className='modal-bg'>
                <Box className='modal-content' onClick={(e) => e.stopPropagation()}>
                    <Box className='modal-heading'>
                        <h2 className='modal-heading-text'>{t('You have exceeded the number of quiz attempts!')}</h2>
                        <Box className='ok-align'>
                            <Box onClick={hideModal}
                                className='ok-btn'>{t('Ok')}</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const showResult = ()=>{
        history.push('/reports',  {courseId:course_id, slug:quizData?.testId?.slug, pageType: "Instruction"  })
      }

    function renderList() {
        return (
            <>
                <Box className='certificate-box' disabled={disabled}>
                    <Box className='scrollable-container'>
                        <Box>
                            <Typography className='certifiedText'>*Score minimum {quizData?.testId?.EvaluationUnlockPercent}% in evaluation test to unlock certificate</Typography>
                            <Box className="certificate-text-div">
                                <Box className='certificate-main-div'>
                                    <TestImg className='certificate-img'/>
                                    <Box className='live-text'>
                                        <p className='genus-text'>{quizData.testId.name}</p>
                                        <p className='best-score'>{`Best Score: ${bestScore}/${quizData.testId.mark}`}</p>
                                    </Box>
                                </Box>
                                <Box className='completion-box'>
                                    <TryImage style={{marginRight: '-7px'}}/>
                                    <Typography variant='p' className='completion' style={{color: `${theme?.primaryColor}`}}>{`${quizData?.quizAttempts}/${quizAttemptsCount}`} {t("Attempt")}</Typography>
                                    <OverlayButton onClick={handleClickAction} title={t("Get Started")} classStyleHeading={classes.startBtn}/>
                                    {props?.quizData?.quizAttempts > 0 && <Button onClick={showResult} className={classes.viewResult}>{t("view_result")}</Button>}
                                </Box>
                            </Box>
                            <Box className="certificate-text-div mobile-certificate-text-div">
                                <Box className='certificate-main-div'>
                                    <TestImg className='certificate-img'/>
                                    <Box className='live-text'>
                                        <p className='genus-text'>{quizData.testId.name}</p>
                                        <p className='best-score'>{`Best Score: ${bestScore}/${quizData.testId.mark}`}</p>
                                    </Box>
                                </Box>
                                <Box className='completion-box'>
                                    <TryImage style={{marginRight: '-7px'}}/>
                                    <Typography variant='p' className='completion' style={{color: `${theme?.primaryColor}`}}>{`${quizData?.quizAttempts}/${quizAttemptsCount}`} {t("Attempt")}</Typography>
                                </Box>
                                <Box className="btn-container">
                                    <OverlayButton onClick={handleClickAction} title={t("Get Started")} classStyleHeading={classes.startBtn}/>
                                    {props?.quizData?.quizAttempts > 0 && <Button onClick={showResult} className={classes.viewResult}>{t("view_result")}</Button>}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {
                    isModalVisible &&
                    <QuizSucessful />
                }
            </>
        );
    }

    function renderLoader() {
        //we will show four cards to fill this space.
        return (
            <></>
        );
    }

    return (
        <React.Fragment>
            {
                !singleTestSeriesLoading ? renderList() : renderLoader()
            }
        </React.Fragment>
    );
}

export default QuizCard;
