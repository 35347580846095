import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
import Attempts from "../../assets/images/attempted.png";
import BestScore from "../../assets/images/best-score.png";
import CurrentScore from "../../assets/images/current-score.png";
import "./reports.scss";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

function ScoreCard(props) {
  const { courseResultData, quizAttemptsCount } = props;
  const t = useTranslations();
  let bestScore = courseResultData?.bestScore?.reduce(
    (accumulator, currentValue) => {
      return Math.max(accumulator, currentValue);
    },
    courseResultData?.bestScore[0]
  );
  let totalMarks = courseResultData?.mark;
  let currentScore =
    courseResultData?.bestScore[courseResultData?.bestScore?.length - 1];

  const cardData = [
    {
      title: t("Current_Score"),
      text: `${currentScore + "/" + totalMarks}`,
      imageUrl: CurrentScore,
    },
    {
      title: t("attempted"),
      text: `${courseResultData?.attempted}${
        courseResultData?.quizMode === "mock-test"
          ? `/${quizAttemptsCount}`
          : ""
      }`,
      imageUrl: Attempts,
    },
    {
      title: t("Best_Score"),
      text: `${bestScore + "/" + totalMarks}`,
      imageUrl: BestScore,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      className="custom-grid"
      style={{ paddingLeft: "15px" }}
    >
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={4} md={4} key={index}>
          <Card className="card-detail" style={{ backgroundColor: "#CFD8DC" }}>
            <Grid container className="card-data">
              <Grid item xs={10}>
                <CardContent className="card-content">
                  <div className="custom-subtitle">{card.text}</div>
                  <div className="heading-title card-title">{card.title}</div>
                </CardContent>
              </Grid>
              <Grid item xs={2}>
                <img
                  src={card?.imageUrl}
                  alt={card.title}
                  height="50px"
                  width="50px"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ScoreCard;
