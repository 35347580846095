import axios from './axiosConfig';

export function getReadAllNotifications() {
    return axios.put('api/v1/notification').then((response) => {
        //handle success
        return response.data;
    }).catch((error) => {
        //handle error
        return error;
    });
}
