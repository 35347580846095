import status from "../constants/actionStatus";

const initialState = {
    status: status.PENDING,
    activeCourse: {},
};
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case "SELECT_USER_COURSE_PENDING":
        return {
            status: status.PENDING,
            activeCourse: {},
        };
    case "SELECT_USER_COURSE_SUCCESS":
        return {
            status: status.PENDING,
            activeCourse: action.payload,
        };
    case "SELECT_USER_COURSE_FAILURE":
        return {
            status: status.ERROR,
            activeCourse: action.payload,
        };
    default:
        return state;
    }
};
