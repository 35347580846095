import React, { useEffect, useState } from 'react';
import './ForgotPassword.scss';
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, Input, InputLabel, Link } from '@material-ui/core';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { forgotPassword } from 'examsbook-react-sdk/actions/getUser';
import LearnifyIcon from "../../assets/images/LearnifyLogoDark.png"

const ForgotPassword = () => {
  const [bgColor, setBgColor] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [textColor, setTextColor] = useState('');
  const [email, setEmail] = useState('');
  const [productName, setProductName] = useState(null);
  const [supportData, setDynamicSeoData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const dispatch = useDispatch();
  const t = useTranslations();
  let inputValue;

  const getDynamicSeoSettingApiData = async () => {
    try {
        dispatch(getSupportAction()).then((res) => {
          setDynamicSeoData(res);
        });
    } catch (error) {
      console.log('Error', error.message);
    }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getDynamicSeoSettingApiData();
    getDefaultSeoSettingApiData();
  }, []);

  useEffect(() => {
    const dynamicLogo = supportData?.data?.find((item) => item.key === "Image")?.value;
    const defaultProductName = defaultSupportData?.data?.['Product Name'][0]?.defaultValue;
    const dynamicProductName = supportData?.data?.find(item =>  item.key === 'Product Name')?.value;
    setProductName(dynamicProductName ? dynamicProductName : defaultProductName)
    setBgColor("#0C3450");
    setTextColor("#fff");
  },[defaultSupportData, supportData]);

  useEffect(() => {
    if(!supportData)  {
        dispatch(getSupportAction());
    } if (!defaultSupportData) {
        dispatch(getDefaultSupportDataAction())
    }
  }, [supportData, defaultSupportData]);

  const focusTextField = (fieldId) => { // check if we want it.
    const field = document.getElementById(fieldId);
    if (field) {
      field.focus();
    }
  };

  const handleEmailChange = (event) => {
    inputValue = event.target.value.trim();
    setEmail(inputValue);
    const isValidEmail = (email) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase());
    const isMobileNumber = (str) => /^\d{10}$/.test(str);
    let errMsg = "";
    let emailValid = true;
    // Validate email or mobile number
    if (!isValidEmail(inputValue) && !isMobileNumber(inputValue)) {
      errMsg = "Please enter a valid email id or 10-digit mobile number.";
      emailValid = false;
    }
    // Handle error message and button state
    const errorElement = document.getElementById("reset-password-error");
    const changePasswordButton = document.getElementById("changePasswordBtn");
    if (!emailValid) {
      errorElement.innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      errorElement.style.display = "block";
      changePasswordButton.setAttribute("disabled", "disabled");
      setIsButtonDisabled(true);
      focusTextField("userForgotPassEmail");
    } else {
      errorElement.style.display = "none";
      changePasswordButton.removeAttribute("disabled");
      setIsButtonDisabled(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const getSubdomain = () => {
      const host = window.location.hostname;
      const domainParts = host.split('.');
      if (domainParts.length > 2) {
        return domainParts[0];
      }
      return '';
    };

    const subdomain = getSubdomain();

    let forgotPasswordData = {
      email,
      subdomain
    }

    const isValidEmail = (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase());
    const isMobileNumber = (str) => /^\d{10}$/.test(str);
    const errorElement = document.getElementById('reset-password-error');
    const inputType = isValidEmail(email) ? "email" : isMobileNumber(email) ? "mobileNumber" : null;
    if (!inputType) {
      errorElement.innerHTML = `<div class="errorMsg">Please enter a valid email id or 10-digit mobile number.</div>`;
      errorElement.style.display = 'block';
      return;
    }

    errorElement.style.display = 'none';
    const payload = {
      [inputType]: email,
      resetPassword: true
    };

    dispatch(forgotPassword(forgotPasswordData)).then((res) => {
      if (res.status) {
        errorElement.innerHTML = `<div class="successMsg">${res.message}</div>`;
        errorElement.style.display = 'block';
        document.getElementById('changePasswordBtn').setAttribute('disabled', 'disabled');
      } else {
        errorElement.innerHTML = `<div class="errorMsg">${res.message}</div>`;
        errorElement.style.display = 'block';
      }
    });
  };

  return (
    <Grid className="forgot-password-parent-div">
        <Grid className="forgot-password-child-div">
          <Box component="img" id="login-img-learn" className="forgot-password-logo" src={LearnifyIcon} alt={productName} />
        </Grid>
        <Grid className="forgot-password-right-side-box">
        <Box className="form-group error-msg" id="reset-password-error"></Box>
            <Box className="mdl-card__supporting-text input-fields">
                <Box className="forget-title" style={{ color: `${bgColor}` }}>{t('Forgot Password')}</Box>
                <Box>
                    <Box component="p" className="custom-sub-title">{t('Enter your email address and we will send a link to reset your password.')}</Box>
                </Box>
                <Box component="form" id="userForgotPassForm" className="form-horizontal" onSubmit={handleForgotPassword}>
                    <Box className="mdc-text-field mdc-text-field--outlined" id="forgot-input">
                    <Input type="text" className="input-box required_text" id="userForgotPassEmail" placeholder="Email/Mobile No." onChange={handleEmailChange} />
                    <Box>
                        <InputLabel className="mdc-text-field__label" style={{ color: `${bgColor}` }} htmlFor="userForgotPassEmail">{t('Email/Mobile No.')}</InputLabel>
                    </Box>
                    </Box>
                    <Box className="otg otg-items-1 text-center verify-proceed-button send-reset-link-div">
                      <Button
                         type="submit"
                         id="changePasswordBtn"
                         className={`send-reset-link-button ${isButtonDisabled ? 'disabled-btn' : ''}`}
                        style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
                         disabled={isButtonDisabled} // Add the disabled attribute
                       >
                         {t('Send Reset Link')}
                     </Button>
                    </Box>
                    <Box className="back-login">
                    <Link className="back-login" href="/login" style={{ color: `${bgColor}` }}>{t('Back to Login')}</Link>
                    </Box>
                </Box>
            </Box>
        </Grid>
    </Grid>
  );
};

export default ForgotPassword;
