import { Box, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as UnderMaintenanceImage } from "../../../assets/images/underMaintenance.svg";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";

const UnderMaintenance = (props) => {
    const classes = useStyles(styles)();
    const t = useTranslations();

    return (
        <Box>
            <Box className={classes.centerAlign}>
                <Box className={classes.underMaintenanceAlign}>
                    <UnderMaintenanceImage/>
                    <Typography variant="h4" className={classes.text}>{t("This site is Under Maintenance!")}</Typography>
                </Box>
            </Box>

        </Box>
    )
};
const styles = {
    parentDiv :{
        float: 'left',
        paddingTop: '12px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "100%"
    },
    centerAlign: {
        textAlign: '-webkit-center'
    },
    logo: {
        width: '160px',
        paddingLeft: '12px',
    },
    underMaintenanceAlign :{
        height: '80vh',
        width: '80vh',
        paddingTop: '160px'
    },
    "@media (max-width: 768px)": {
        parentDiv :{
            float: 'none'
        },
        logo: {
            height: '120px',
            paddingLeft: '0px',
            position: 'relative'
        },
        underMaintenanceAlign : {
            height: '60vh',
            paddingTop: '0px'
        },
        text : {
            fontSize : '18px',
            width: '100%'
        }

    },
}
export default UnderMaintenance;

