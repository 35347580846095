//quiz results.
import { useEffect, useState } from "react";
import { getQuizResult } from "../services/quizResults";

/**
 * Get result of quiz
 * @param quizId
 * @returns {{status: string}}
 */
export const getQuizResultResponse = (slug, courseId) => (dispatch) => {
    // get quiz result.
    dispatch({ type: "QUIZ_RESULT_REQUEST", payload: {} });
    return getQuizResult(slug, courseId)
        .then((data) => {
            dispatch({ type: "QUIZ_RESULT_SUCCESS", payload: data });
            return data;
        })
        .catch((error) => {
            dispatch({ type: "QUIZ_RESULT_ERORR", payload: error });
            return error;
        });
};
