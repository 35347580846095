import axios from "./axiosConfig";
//it was not suppose to a action. It is simple service
export const getQuizResult = (slug, courseId) => {
    return axios
        .get(`/api/v1/quizResult/${slug}?courseId=${courseId}`)
        .then((response) => {
            // handle success
            let data = response.data;
            return data;
        })
        .catch((error) => {
            // handle error
            if (error.data) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.status);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            let errorObj = error.data ? error.data.error : {};
            return {
                error: errorObj,
            };
        });
};

export function getAllQuizResults() {
    return axios
        .get("/api/v1/quiz/results")
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

export function getSolution({ uniqueId, questionId }) {
    const endpoint = uniqueId
        ? `/api/v1/question/solution?uniqueId=${uniqueId}`
        : `/api/v1/question/solution?questionId=${questionId}`;
    return axios
        .get(endpoint)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
