import status from "../constants/actionStatus";

export default (
    state = {
        recentCourseList: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "RECENT_COURSE_LIST_REQUEST":
        return {
            status: status.PENDING,
            recentCourseList: {},
        };
    case "RECENT_COURSE_LIST_SUCCESS":
        return {
            status: status.SUCCESS,
            recentCourseList: action.payload,
        };

    case "RECENT_COURSE_LIST_FAILURE":
        return {
            status: status.ERROR,
            recentCourseList: {},
        };
    default:
        return state;
    }
};