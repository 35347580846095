import status from '../constants/actionStatus';

export default (state = {
    unreadNotification: []
}, action = {}) => {
    switch (action.type) {
    case 'GET_UNREAD_NOTIFICATION_REQUEST':
        return {
            status: status.PENDING,
            unreadNotification: []
        };
    case 'GET_UNREAD_NOTIFICATION_SUCCESS':
        return {
            status: status.SUCCESS,
            unreadNotification: action.unreadNotificationList
        };
    case 'GET_UNREAD_NOTIFICATION_FAILURE':
        return {
            status: status.ERROR,
            unreadNotification: state
        };
    default:
        return state;
    }
};
