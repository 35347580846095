import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import './reports.scss';

function ChartComponent({ title, data, COLORS }) {
    const t = useTranslations();

    const legendData = data.map((entry, index) => ({
        name: entry.name,
        color: COLORS[index % COLORS.length],
    }));

    const renderTooltip = ({ payload }) => {
        if (payload && payload.length === 1) {
            const { value } = payload[0];
            const percentage = value;

            return (
                <div className="custom-tooltip">
                    <p>{`${percentage.toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Box className="graph-box-container">
            <Box className='title-heading'>{t(title)}</Box>
            <Box className="legends-right">
                <PieChart width={250} height={190} className='chart-data'>
                    <Pie
                        dataKey="value"
                        data={data}
                        cx={130}
                        cy={90}
                        innerRadius={55}
                        outerRadius={90}
                        fill="#8884d8"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={renderTooltip} />
                </PieChart>
                <Box className="legend">
                    {legendData.map((entry, index) => (
                        <div key={`legend-${index}`} className="legend-item">
                            <div className="legend-circle" style={{ backgroundColor: entry.color }}></div>
                            <p>{t(entry.name)}</p>
                        </div>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

function QuestionDistribution(props) {

    const data = [
        { name: 'Correct_Answer', value: props?.courseResultData?.correctAnswerArray[props?.activeKey] },
        { name: 'Unanswered', value: props?.courseResultData?.attemptsField[props?.activeKey]?.unAnsweredQuestion },
        { name: 'incorrect_answer', value: props?.courseResultData?.incorrectAnswerArray[props?.activeKey] },
    ];

    const COLORS = ['#698192', '#0C3450', '#3D5D73'];

    return <ChartComponent title="question_distribution" data={data} COLORS={COLORS} />;
}

function TimeSpent(props) {
    let timeTaken = (props?.courseResultData?.attemptsField[props.activeKey]?.timeSpent)?.toFixed(2)
    let newTime = timeTaken/60

    const data = [
        { name: 'total_time', value: props?.courseResultData?.duration},
        { name: 'time_taken', value: newTime},
    ];

    const COLORS = ['#698192', '#0C3450'];

    return <ChartComponent title='time_spent' data={data} COLORS={COLORS} />;
}

export { QuestionDistribution, TimeSpent };
