import React, { Component } from 'react';
import './errorBlock.scss';
import NotFoundPage from "./NotFoundPage";

class ErrorBlock extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidMount() {
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.path !== this.props.path && this.state.errorInfo) {
            this.setState({
                error: null,
                errorInfo: null
            });
        }
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <NotFoundPage/>
            );
        } else {
            // Normally, just render children
            return this.props.children;
        }
    }
}

export default ErrorBlock;
