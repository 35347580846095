export function timeRemainingFromNow(diffTime) {
    const now = new Date().getTime();
    const distance = diffTime - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    hours = hours + days * 24;
    const minutes = ('0' + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).slice(-2);
    const seconds = ('0' + Math.floor((distance % (1000 * 60)) / 1000)).slice(-2);
    if (days === 1) {
        return days + ' Day ';
    } else if (days > 1) {

        return days + ' Days ';
    } else {
        return hours + ":" + minutes + ":" + seconds;
    }
}

export function convertToDate(time) {
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
    const dateObj = new Date(time);
    const year = dateObj.getFullYear();
    const date = fixedToTwoDigit(dateObj.getDate());
    const hours = fixedToTwoDigit(dateObj.getHours());
    const mins = fixedToTwoDigit(dateObj.getMinutes());
    return date + ' ' + months[dateObj.getMonth()] + ' ' + year + ', ' + hours + ':' + mins;
}

function fixedToTwoDigit(num) {
    return (num < 10) ? '0' + num : num;
}
