import React from 'react';
import MyCoursesList from "../components/MyCoursesList/MyCoursesList";
import { useAnalyticsPageView } from "examsbook-react-sdk/custom-hooks";

/**
 * Test series page
 * @param props
 * @returns {*}
 * @constructor
 */
function MyCourses() {
    //analytic
    useAnalyticsPageView();

    return (
        <MyCoursesList />
    );
}

export default MyCourses;