import axios from './axiosConfig';

export function getActivePromotions() {
    // api to get all questions saved by user
    return axios.get('/api/v1/active-promotions')
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
