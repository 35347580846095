import status from "../constants/actionStatus";

export default (
    state = {
        topicList: []
    },
    action = {}
) => {
    switch (action.type) {
    case "TOPIC_REQUEST":
        return {
            status: status.PENDING,
            topicList: []
        };
    case "TOPIC_SUCCESS":
        return {
            status: status.SUCCESS,
            topicList: action.payload,
        };

    case "TOPIC_FAILURE":
        return {
            status: status.ERROR,
            topicList: [],
        };
    default:
        return state;
    }
};
