import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import SectionCard from './section';
import {TimeSpent, QuestionDistribution} from './time-spent';
import './reports.scss';
 
function DistributionCards(props) {
    const { courseResultData, setActiveAttempts, setActiveKey, activeAttempts, activeKey } = props;

    return (
        <Grid container spacing={2} className='distribution-grid' style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={7} md={7} className="section-data-grid">
                {courseResultData && <SectionCard setActiveKey={setActiveKey} setActiveAttempts={setActiveAttempts} courseResultData={courseResultData?.result} />}
            </Grid>

            <Grid item xs={12} sm={5} md={5} className="data-grid">
                <div style={{ height: '100%', backgroundColor: '#FFF', padding: '10px' }}>
                    {activeAttempts && <TimeSpent activeKey={activeKey} courseResultData={courseResultData} activeAttempts={activeAttempts} />}
                </div>
            </Grid>
        </Grid>
    );
}

export default DistributionCards;
