import React, { useEffect, useState } from "react";
import bellIcon from "../../../assets/images/notification-bell.png";
import { Box, Button, Typography, ClickAwayListener } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslations, useStyles } from "examsbook-react-sdk/custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import { getReadAllNotification } from "examsbook-react-sdk/actions";
import { useTheme } from "../../../constants/themeProvider";
import { ColorsConstant } from "../../../constants/colorConstant";
import DefaultCourseImage from "./../../../assets/images/course-image.jpg"
/**
 * Language switcher.
 * @returns {*}
 * @constructor
 */
function UserNotification(props) {
  const history = useHistory();
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const [showDiv, setShowDiv] = useState(false);
  const [readNotification, setReadNotification] = useState();
  const userNotification = useSelector(
    (state) => state.notificationList.notification
  );
  const t = useTranslations();
  const theme = useTheme();
  let isReadNotificationCount = userNotification.filter(
    (item) => item.isRead === false
  ).length;
  const [activeFilter, setActiveFilter] = useState("All");
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  const handleClickAway = () => {
    setShowDiv(false);
  };

  function handleGoToNotificationDetail() {
    dispatch(getReadAllNotification())
      .then((response) => {
        setReadNotification(response?.data);
      })
      .catch((error) => {
        console.error("Failed to fetch notifications:", error);
      });
    history.push("/notifications");
  }
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };
  const notificationFilterList = [
    {
      title: "All",
    },
    {
      title: "Unread",
    },
    {
      title: "Mark all as read",
    },
  ];
  return (
    <React.Fragment>
      <Box
        component="img"
        id="userNotificationId"
        src={bellIcon}
        className="notification-image"
        alt=""
        onClick={toggleDiv}
      />
      {isReadNotificationCount > 0 && (
        <Box className="badge">
          <Typography variant="inherit" className="notification-count">
            {isReadNotificationCount}
          </Typography>
        </Box>
      )}
      {showDiv && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            id="notification-sub-menus"
            className="notification-sub-menus sub-menu-align"
          >
            <Box className={classes.notificationHeader}>
              <Typography variant="h4">{t('Notifications')}</Typography>
              <Typography>
                {t('stayUpdatedNotificationText')}
              </Typography>
            </Box>
            {/* <Box className={classes.filterNotifyContainer}>
              {notificationFilterList.map((items, i) => (
                <Box
                  className={`list-item ${
                    activeFilter === items.title ? "active" : ""
                  }`}
                  onClick={() => handleFilterClick(items.title)}
                  key={i}
                >
                  {items.title}
                </Box>
              ))}
            </Box> */}
            {userNotification?.length ? (
              <>
                {userNotification
                  ?.sort(
                    (a, b) =>
                      new Date(b?.updated?.on) - new Date(a?.updated?.on)
                  )
                  .slice(0, 5)
                  .map((item, index) => {
                    const currentTime = Date.now();
                    const scheduledDate = Date.parse(item?.updated?.on);
                    const differenceInMilliseconds = Math.abs(
                      currentTime - scheduledDate
                    );
                    const differenceInSeconds = differenceInMilliseconds / 1000;
                    const differenceInMinutes = differenceInSeconds / 60;
                    const differenceInHours = differenceInMinutes / 60;
                    const differenceInDays = differenceInHours / 24;

                    let timeAgo;
                    if (differenceInMinutes < 60) {
                      timeAgo = `${Math.floor(differenceInMinutes)} min ago`;
                    } else if (differenceInHours < 24) {
                      timeAgo = `${Math.floor(differenceInHours)} h ago`;
                    } else {
                      timeAgo = `${Math.floor(differenceInDays)} d ago`;
                    }

                    return (
                      <Box
                        className="notification-items"
                        onClick={handleGoToNotificationDetail}
                        key={index}
                      >
                        <Box className="notification-list">
                          <Box className="notification">
                            <Box
                              component="img"
                              className="profile-pic"
                              src={DefaultCourseImage}
                              alt="Profile Picture"
                            />
                            <Box className="notification-text">
                              <Box className="notification-content">
                                <Typography
                                  variant="inherit"
                                  className="notification-heading"
                                >
                                  {item?.notificationId?.title}
                                </Typography>
                                <Typography
                                  variant="inherit"
                                  className="notification-info"
                                >
                                  {item?.notificationId?.message}
                                </Typography>
                              </Box>
                              <Typography
                                variant="inherit"
                                className="notification-time"
                              >
                                {timeAgo}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                <Button
                  className="see-all"
                  onClick={handleGoToNotificationDetail}
                >
                  <Typography variant="inherit" className="see-all-text">
                    {t("See All")} &gt;
                  </Typography>
                </Button>
              </>
            ) : (
              <Box>
                <Typography
                  variant="inherit"
                  className="no-notification-content"
                  style={{ color: theme?.primaryColor }}
                >
                  {t("No_new_notification")}
                </Typography>
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
}
const styles = (theme) => ({
  notificationHeader: {
    padding: 20,
    "& h4": {
      margin: 0,
      padding: 0,
      fontSize: 16,
      color: ColorsConstant.notifyHeaderText,
      fontWeight: 600,
      paddingBottom: 5,
    },
    "& p": {
      margin: 0,
      padding: 0,
      fontSize: 12,
      color: ColorsConstant.subTitleTextColor,
    },
  },
  filterNotifyContainer: {
    background: ColorsConstant.notifyNavBg,
    display: "flex",
    alignItems: "center",
    padding: "5px 20px",
    marginBottom: 12,
    "& .list-item": {
      fontSize: 10,
      color: ColorsConstant.subTitleTextColor,
      marginRight: 15,
      cursor: "pointer",
      "&.active": {
        color: ColorsConstant.defaultBlue,
        fontWeight: 600,
      },
      "&:last-child": {
        marginLeft: "auto",
        marginRight: 0,
      },
    },
  },
});
export default UserNotification;
