import status from '../constants/actionStatus';

export default (state = {
    readAllNotificationLists: []
}, action = {}) => {
    switch (action.type) {
    case 'GET_READ_NOTIFICATION_REQUEST':
        return {
            status: status.PENDING,
            readAllNotificationLists: []
        };
    case 'GET_RAED_NOTIFICATION_SUCCESS':
        return {
            status: status.SUCCESS,
            readAllNotificationLists: action.readAllNotificationLists
        };
    case 'GET_READ_NOTIFICATION_FAILURE':
        return {
            status: status.ERROR,
            readAllNotificationLists: state
        };
    default:
        return state;
    }
};
