import axios from './axiosConfig';

export function getNotificationList(length) {
    return axios.get('/api/v1/userNotifications?length=' + length).then((response) => {
        //handle success
        return response.data;
    }).catch((error) => {
        //handle error
        return error;
    });
}

export function postNotificationData(userId, data) {
    return axios.post(`api/v1/push-notification?userId=${userId}`, data)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            // handle error
            return error;
        });
}
