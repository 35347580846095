import status from '../constants/actionStatus';

export default (state = {
    deleteNotificationLists: []
}, action = {}) => {
    switch (action.type) {
    case 'GET_DELETE_NOTIFICATION_REQUEST':
        return {
            status: status.PENDING,
            deleteNotificationLists: []
        };
    case 'GET_DELETE_NOTIFICATION_SUCCESS':
        return {
            status: status.SUCCESS,
            deleteNotificationLists: action.deleteNotificationLists
        };
    case 'GET_DELETE_NOTIFICATION_FAILURE':
        return {
            status: status.ERROR,
            deleteNotificationLists: state
        };
    default:
        return state;
    }
};
