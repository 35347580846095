import { domainVerifyLogin } from "../services/domainVerifyService";

export const verifyDomainAction = (data) => (dispatch) => {
    dispatch(request());
    return domainVerifyLogin(data)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "VERIFY_DOMAIN_REQUEST" };
    }

    function success(response) {
        return { type: "VERIFY_DOMAIN_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "VERIFY_DOMAIN_FAILURE", payload: error };
    }
};
