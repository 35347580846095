import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { LinearProgressWithLabel } from "../MyCourseCard/MyCoursesCard";
import { useTheme } from "../../constants/themeProvider";
import { Box, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import userImage from "../../assets/images/person.png";
import suggestedCoursesImg from "../../assets/images/suggestedCourses.png";
import { useDispatch, useSelector } from "react-redux";
import {
  reactGaEvent,
  recentCourseListAction,
} from "examsbook-react-sdk/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
    borderRadius: "20px",
    background: "#FFFFFF",
    maxHeight: "240px",
    display: "flex",
    flexDirection: "column",
    boxShadow: '1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15)',
    "@media (max-width: 767px)": {
      maxHeight: "inherit",
      marginBottom: 20,
    },
  },
  title: {
    color: "#000000",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "21px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    "& p": {
      margin: 0,
      '@media (max-width: 767px)': {
        fontSize: '14px !important'
      }
    },
  },
  topTitle: {
    "@media (max-width: 767px)": {
      marginBottom: 10,
      fontSize: '14px !important'
    },
  },
  disableContinueBtn: {
    opacity: "0.5",
    cursor: "not-allowed !important"
  },
  expireTag: {
    fontStyle: "italic",
    color: "red",
    fontSize: "12px",
  },
  courseCreatorsBox: {
    display: "flex",
    gap: "12px",
    padding: "10px",
    alignItems: "center",
  },
  courseCreatorsName: {
    color: "#40433C",
    fontWeight: 400,
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "30ch",
    '@media (max-width: 767px)': {
      fontSize: 14,

    }
  },
  courseCreatorsDescription: {
    color: "#000000DE",
    fontSize: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "30ch",
  },
  learningBox: {
    background: "#FFFFFF",
    padding: "20px 12px",
    borderRadius: "20px",
    height: "calc(70vh - 40px)",
    boxShadow: '1.95px 1.95px 2.6px 0px rgba(0, 0, 0, 0.15)',
    "@media (max-width: 767px)": {
      height: "inherit",
    },
  },
  resumeLearningBox: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    cursor: "pointer",
    overflowY: "auto",
    height: 'calc(100svh - 340px)',
    // height: "40%",
    "@media (max-width: 767px)": {
      height: "inherit",
    },
  },
  resumeLearningContent: {
    display: "flex",
    gap: "0px",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #000000B2",
    borderRadius: "15px",
    cursor: "pointer",
  },
  suggestedCoursesBox: {
    display: "flex",
    gap: "10px",
    padding: "10px",
    marginBottom: "15px",
  },
  suggestedCoursesImgAlign: {
    background: "#ECEFF1",
    padding: "30px 5px",
  },
  suggestedCoursesData: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  resumeLearningDescription: {
    fontSize: "12px",
    color: "#000000B2",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: "3",
  },
  suggestedCoursesHeading: {
    margin: "20px 0px",
    borderTop: "1px solid #0000001F",
    paddingTop: "20px",
  },
  suggestedCoursesContent: {
    overflowY: "auto",
    height: "40%",
  },
  userImage: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
  },
  userDesc: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  scrollContainer: {
    height: 500
  }
}));

export default function CourseCard(props) {
  const classes = useStyles();
  const t = useTranslations();
  const percentageCompletion =
    props?.progressPercent !== undefined ? props?.progressPercent : 0;
  const [showExpireTag, setShowExpireTag] = useState(false);
  const [expireDays, setExpireDays] = useState(false);
  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width:960px)");
  let recentCourseData = useSelector((state) => state.recentCourseList);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    user?._id && dispatch(recentCourseListAction(user?._id));
  }, [user?._id]);

  useEffect(() => {
    if (props?.courseData !== undefined && props?.courseData?.endDate) {
      checkExpiration();
    }
  }, []);

  const checkExpiration = () => {
    const currentDate = new Date();
    let courseEndDate = new Date(props?.courseData?.endDate);
    const timeDifference = courseEndDate.getTime() - currentDate.getTime();
    setExpireDays(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference <= 7 && daysDifference > 0) {
      setShowExpireTag(true);
    }
  };

  const courseCreators = [
    {
      img: props?.courseData?.created?.by?.image
      ? props?.courseData?.created?.by?.image
      : userImage,
      name: props?.courseData?.created?.by?.name,
      description: props?.courseData?.created?.by?.department,
    },
  ];

  const suggestedCourses = [
    {
      img: suggestedCoursesImg,
      courseName: "Course 1",
      description:
        "If you know Salesforce Classic, the Lightning Experience navigation model will feel like a familar friend, only better.",
    },
    {
      img: suggestedCoursesImg,
      courseName: "Course 2",
      description:
        "If you know Salesforce Classic, the Lightning Experience navigation model will feel like a familar friend, only better.",
    },
    {
      img: suggestedCoursesImg,
      courseName: "Course 3",
      description:
        "If you know Salesforce Classic, the Lightning Experience navigation model will feel like a familar friend, only better.",
    },
  ];

  function handleGoToTestClick(item) {
    localStorage.setItem("dataKey", item?._id);
    window.dispatchEvent(new Event("storage"));
    reactGaEvent("Course Material", "Go To Course");
    localStorage.setItem("courseName", item?.name);
    localStorage.setItem("courseDescription", item?.description);
    localStorage.setItem("courseId", item?._id);
    history.push("/course-material/content", {
      courseId: item?._id,
      subjectId: "",
      topicId: "",
      subjectDetail: item,
      subjectName: "",
      topicName: "",
    });
  }


  return (
    <>
      <Box className={classes.root}>
        <Box className={`${classes.title} ${classes.topTitle}`}>
          <Typography variant="p">
            Course Creator
            {/* {t("Course Creators")} */}
            </Typography>
          {showExpireTag && (
            <Typography variant="p" className={classes.expireTag}>{`${t(
              "Expires in"
            )} ${expireDays} ${
              expireDays === 1 ? t("day") : t("days")
            }`}</Typography>
          )}
        </Box>
        <Box className="course-creators" style={{ overflowY: "auto" }}>
          {courseCreators.map((item, index) => {
            return (
              <Box className={classes.courseCreatorsBox} key={index}>
                <Box className={classes.userImage} component="img" src={item.img} />
                <Box className={classes.userDesc}>
                  <Tooltip title={item?.name} placement="top-start">
                    <Box className={classes.courseCreatorsName}>{item?.name}</Box>
                  </Tooltip>
                  <Tooltip title={item?.description} placement="top-start">
                    <Box className={classes.courseCreatorsDescription}>
                      {item?.description}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.learningBox}>
        <Box className={classes.title} style={{ paddingBottom: "20px" }}>
          <Typography
            variant="body1"
            style={{ fontSize: "18px", fontWeight: "400" }}
          >
            {t("Resume Learning")}
          </Typography>
        </Box>
        <Box className={classes.resumeLearningBox}>
          {recentCourseData?.recentCourseList?.length &&
            recentCourseData?.recentCourseList?.map((item, index) => {
              const isDisabled = item?.assignedCourseEndDate < new Date();
              return (
                <Box
                  onClick={() => { if (isDisabled) return; handleGoToTestClick(item)}}
                  className={`${classes.resumeLearningContent} ${isDisabled ? classes.disableContinueBtn : ""}`}
                  key={index}
                >
                  <Typography variant="body1" style={{ color: "#000000DE" }}>
                    {item?.name}
                  </Typography>
                  <LinearProgressWithLabel
                    value={item?.progressPercent ? item?.progressPercent : 0}
                  />
                  <Typography
                    variant="span"
                    className={classes.resumeLearningDescription}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></Typography>
                </Box>
              );
            })}
        </Box>

        {/* <Box className={classes.title + " " + classes.suggestedCoursesHeading}>
          <Typography
            variant="body1"
            style={{ fontSize: "18px", fontWeight: "400" }}
          >
            {t("Suggested Courses")}
          </Typography>
        </Box> */}
        {/* <Box className={classes.suggestedCoursesContent}>
          {suggestedCourses.map((item, index) => {
            return (
              <Box className={classes.suggestedCoursesBox} key={index}>
                <Box
                  className={classes.suggestedCoursesImgAlign}
                  component="img"
                  src={item?.img}
                ></Box>
                <Box className={classes.suggestedCoursesData}>
                  <Typography variant="body1" style={{ color: "#000000DE" }}>
                    {item?.courseName}
                  </Typography>
                  <Typography
                    variant="p"
                    style={{ fontSize: "12px", color: "#000000B2" }}
                  >
                    {item?.description}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box> */}
      </Box>
    </>
  );
}
