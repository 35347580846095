import status from '../constants/actionStatus';

export default (state = {
    status: status.PENDING
}, action = {}) => {
    switch (action.type) {
    case 'QUESTION_REQUEST':
        return {
            status: status.PENDING,
        };
    case 'UNSAVE_QUESTIONS_FAILURE':
        return {
            status: status.ERROR,
        };
    default:
        return state;
    }
}
