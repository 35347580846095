import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import QuestionTitle from "./QuestionTitle";
import ExplanationSection from "./ExplanationSection";
import QuestionOptionList from "./QuestionOptionList";
import QuizLoader from "./../Loader/QuizLoader";
import { captureAnswer } from "examsbook-react-sdk/actions";
import QuestionDirection from "./QuestionDirection";
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import LongMenu from "../../longMenu";
import { formatQuestion, TimeUtils } from "examsbook-react-sdk/common";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import QuestionSolutionModal from '../QuestionSolutionModal/QuestionSolutionModal';

/**
 * Single question box.
 * @param props
 * @returns {*}
 * @constructor
 */

function QuestionBox(props) {
    const dispatch = useDispatch();
    const t = useTranslations();
    const { activeQuestionIndex, isLoading, mode, number, viewMode, slug, total, drawerToggle, pauseQuiz, handleQuizPauseChange, setPauseQuiz } = props;
    const selectedQuestion = useSelector((state) => state.selectedQuestion);
    const language = useSelector((state) => state.user.language);
    const classes = useStyles(styles)();
    const history = useHistory();

    function onRadioChange(e, optionIndex, activeQuesIndex, quesType) {
        if (viewMode) {
            return;
        }
        let givenAns = selectedQuestion.givenAnswer || [];
        let clearRadio = false;
        if (quesType !== 'MultiChoice') {
            if (givenAns.indexOf(e.target.value) > -1) {
                clearRadio = true;
            }
            givenAns = [];
        }

        if (!clearRadio) {
            if (e.target.checked) {
                givenAns.push(e.target.value);
            } else {
                givenAns = givenAns ? givenAns.filter((item) => {
                    return item !== e.target.value;
                }) : [];
            }
        }
        dispatch(captureAnswer(givenAns));
    }

    function changeTimeFormat(spentTime) {
        return TimeUtils.changeTimeFormat(spentTime);
    }

    const {
        type,
        explanation,
        localesTitle,
        title,
        mark,
        penalty,
        givenAnswer,
        timeSpent,
        direction,
        options,
        markObtain,
        className,
        localesDirection
    } = formatQuestion(selectedQuestion, viewMode, language);
    const quizDirectionClass = direction && direction.type === 'LONG_TEXT' ? classes.quizDirectionBlock : '';
    // const videoData = `/video-player-screen?videoUrl=${selectedQuestion.id.solutionLink}`;

    return (
      <>
        <div className={ drawerToggle ? classes.questionBoxExpand : classes.questionBox }>
          <div className={classes.quizComprehension}>
            {direction && direction.name && direction.type === "LONG_TEXT" ? (
              <QuestionDirection direction={direction} localesDirection={localesDirection} />
            ) : null}
            <div className={classes.box + " " + quizDirectionClass}>
              {!isLoading ? (
                <React.Fragment>
                  <QuestionTitle
                    mark={markObtain ? markObtain : "0"}
                    questionMark={mark}
                    type={type}
                    penalty={penalty}
                    pauseQuiz={pauseQuiz}
                    setPauseQuiz = {setPauseQuiz}
                    handleQuizPauseChange = {handleQuizPauseChange}
                    classNameInView={
                      className ? className : classes.marksBadges
                    }
                    timeSpent={
                      timeSpent ? changeTimeFormat(timeSpent) : "00:00"
                    }
                    viewMode={viewMode}
                    direction={direction}
                    title={title}
                    total={total}
                    number={number}
                    localesTitle={localesTitle}
                    localesDirection={localesDirection}
                  />

                  <QuestionOptionList
                    options={options}
                    type={type}
                    showAnswer={viewMode}
                    viewMode={viewMode}
                    givenAnswer={givenAnswer}
                    mode={mode}
                    onRadioChange={onRadioChange}
                    activeQuestionIndex={activeQuestionIndex}
                  />

                  <div className={classes.showAnswer}>
                    <LongMenu question={selectedQuestion} />
                  </div>
                  <ExplanationSection explanation={explanation} viewMode={viewMode} mode={mode} showAnswer={viewMode} />
                </React.Fragment>
              ) : (
                <QuizLoader />
              )}
              {/* <div className={classes.solutionData}>
                {selectedQuestion &&
                  selectedQuestion.id &&
                  selectedQuestion.id.solutionType === "VIDEO" && (
                    <>
                      <span>{t('VIEW_SOLUTION')}</span>
                      <span className={classes.solutionText}>
                        <Button className={classes.buttonText} onClick={() => history.push(videoData, {videoURL:selectedQuestion.id.solutionLink, slug:slug, src: "tabs", type: selectedQuestion.id.solutionType})}>
                          {<SlideshowIcon />}{t('VIDEO')}
                        </Button>
                      </span>
                    </>
                  )}
              </div> */}
              <div className={classes.solutionData}>
                  <QuestionSolutionModal question={selectedQuestion} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const styles = theme => ({
    questionBox: {
        position: 'fixed',
        top: 68,
        left: '17%',
        right: -5,
        maxHeight: 'calc(100vh - 150px)',
        transition: '0.3s all linear',
        overflow: 'hidden',
        '@media (max-width:767px)': {
            left: 0,
            right: 0,
            top: 55,
            maxHeight: 'calc(100vh - 0px)',

        },
        '@media (min-width: 768px) and (max-width:1023px)': {
            left: 0,
            right: 0,
            maxHeight: 'calc(100vh - 0px)',
        },
        '@media (min-width: 1024px) and (max-width:1024px)': {
            left: 0,
            right: 0,
            top: 70,
            position: "fixed",
            maxHeight: 'calc(100vh - 0px)',
        },
        '@media (min-width: 1025px) and (max-width:1439px)': {
            left: 330,
            right: 0,
            top: 70,
            position: "fixed",
            maxHeight: 'calc(100vh - 0px)',
        },
        '@media (min-width: 1440px) and (max-width:1799px)': {
            left: 0,
            right: 0,
            position: "fixed",
            maxHeight: 'calc(100vh - 0px)',
            margin: "0 0 0 23%"
        },
        '@media (min-width: 1800px) and (max-width:1850px)': {
            left: 0,
            right: 0,
            position: "fixed",
            maxHeight: 'calc(100vh - 0px)',
            margin: "0 0 0 18%"
        },
        '@media (min-width: 2350px) and (max-width:2560px)': {
            left: 0,
            right: 0,
            position: "fixed",
            maxHeight: 'calc(100vh - 0px)',
            margin: "0 0 0 13.5%"
        }
    },
    questionBoxExpand: {
        position: 'absolute',
        top: 85,
        left: -110,
        right: 20,
        maxHeight: 'calc(100vh - 150px)',
        transition: '0.3s all linear',
        overflow: 'hidden',
        '@media (min-width:280px) and (max-width:320px)': {
            left: 0,
            right: 0,
            top: 13,
            maxHeight: 'calc(100vh - 0px)'
        },
        '@media (min-width:321px) and (max-width:767px)': {
            left: 0,
            right: 0,
            top: 15,
            maxHeight: 'calc(100vh - 0px)'
        },
        '@media (min-width:768px) and (max-width:830px)': {
            left: 330,
            right: 0,
            top: 50,
            maxHeight: 'calc(100vh - 0px)',
            position: 'fixed'
        },
        '@media (min-width:831px) and (max-width:950px)': {
            left: 370,
            right: 0,
            top: 50,
            maxHeight: 'calc(100vh - 0px)',
            position: 'fixed'
        },
        '@media (min-width:951px) and (max-width:1024px)': {
            left: 320,
            right: 0,
            top: 70,
            maxHeight: 'calc(100vh - 0px)',
            position: 'fixed'
        },
        '@media (min-width:1025px)': {
            left: 0,
            right: 0,
            top: 62,
            position: 'fixed',
            maxHeight: 'calc(100vh - 0px)'
        },
    },
    solutionData: {
        textAlign: 'right',
        display: 'flex',
        float: 'right'
    },
    textData: {
        marginLeft: '70%'
    },
    solutionText: {
        marginLeft: 15
    },
    buttonText: {
        boxShadow: '#D7CCFF',
        width: '100px',
        height: '35px',
        background: '#5F4BAB',
        color: '#ffffff',
        border: '1px solid #5F4BAB',
        bottom: 8,
        '&:hover': {
          background: '#5F4BAB'
      }
    },
    quizComprehension: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'calc(100vh - 145px)',
        '@media (max-width:767px)': {
            height: '100vh'
        }
    },
    quizDirectionBlock: {
        maxHeight: 'calc(100vh - 145px)',
        overflow: 'auto'
    },
    box: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 145px)',
        float: 'left',
        width: 'calc(100% - 20px)',
        margin: '50px 25px 10px 15px',
        position: 'relative',
        textAlign: 'left',
        padding: '0 72px 0 0',
        border: '1px solid #EAEAEA',
        backgroundColor: '#fff',
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d3d5d3'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#eeecec'
        },
        '@media (max-width:767px)': {
            width: 'calc(100% - 0px)',
            margin: 0,
            maxHeight: '100vh',
            padding: '0 11px',
            paddingBottom: 60
        }
    },
    showAnswerIcon: {
        position: 'absolute',
        top: -3,
        left: 0,
        color: '#9c9c9c',
        cursor: 'pointer',
        fontSize: 24,
        '@media (max-width:767px)': {
            top: 12,
            left: '50px',
            fontSize: 26,
            zIndex: 999,
            marginLeft: 10
        },
        '& .fa-bookmark-o': {
            color: '#008e76'
        },
        '& .fa-bookmark': {
            color: '#008e76'
        }
    },
    showAnswer: {
        position: 'absolute',
        top: -5,
        right: 0,
        fontSize: 20,
        '@media (max-width:767px)': {
            top: 3,
            right: 0
        }
    },
    reportError: {
        color: '#000',
        '&:hover': {
            color: '#000',
        }
    },
    minHeight: {
        maxHeight: 'inherit',
        overflow: 'hidden'
    },
    marksBadges: {
        backgroundColor: '#ffc65820',
        color: '#ffc658',
        border: '1px solid #ffc658',
        borderRadius: 2,
        padding: '2px 6px',
        fontSize: 13,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginRight: 0,
        display: 'flex',
    }
});

export default QuestionBox;
