const ES6Promise = require("es6-promise");
ES6Promise.polyfill();
const axios = require("axios");
//base URL.
let instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

// Alter defaults after instance has been created
instance.defaults.headers.common["language"] = "en";

//request interceptor
instance.interceptors.request.use(
    async function (config) {
        // Do something before request is sent
        try {
            config.headers.language = getLanguage();
            const token = localStorage.getItem("token"); // Fetch token
            if (token) {
                config.headers.Authorization = `Bearer ${token}`; // Add token to headers
            }
        } catch (e) {
            console.log("localstorage not found---->>>", e);
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error.response);
    }
);
//response interceptor
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        // Do something with response error
        if (error && error.response && error.response.status === 401) {
            // if web then logout.
            console.log("unauthorized, logging out ...", error.response);
            window.location.href = "/login";
        }
        return Promise.reject(error.response);
    }
);

function getLanguage() {
    return localStorage.getItem("language");
}

export default instance;
