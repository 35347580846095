import status from "../constants/actionStatus";
import { setActiveQuestionIndex } from "./setActiveQuestionIndex";
import { getQuizResponses } from "../services/quizService";

export const getQuizResponse = (slug) => (dispatch) => {
    dispatch({
        type: "GET_QUIZ_REQUEST",
        payload: {
            questions: [],
            totalQuestions: 0,
            id: "",
            status: status.PENDING,
        },
    });
    getQuizResponses(slug)
        .then(function (formattedQuiz) {
            // handle success
            dispatch({
                type: "GET_QUIZ_SUCCESS",
                payload: formattedQuiz,
            });
            return dispatch(setActiveQuestionIndex(0));
        })
        .catch(function (error) {
            // handle error
            console.log("error---------->", error);
            dispatch({
                type: "GET_QUIZ_ERROR",
                payload: {
                    questions: [],
                    totalQuestions: 0,
                    id: "",
                    status: status.ERROR,
                },
            });
        });
};
