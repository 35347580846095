import status from '../constants/actionStatus';
import checkIfAnswerInCorrect from "../common/checkIfAnswerInCorrect";

export default (state = {
    questions: []
}, action = {}) => {
    switch (action.type) {
    case 'SAVED_QUESTIONS_REQUEST':
        return {
            status: status.PENDING,
            questions: []
        };
    case 'SAVED_QUESTIONS_SUCCESS':
        return {
            status: status.SUCCESS,
            questions: action.questionList
        };
    case 'QUESTION_RESPONSE_SUCCESS': {
        try {
            let questionResponse = action.payload;
            let questions = state.questions.map((question) => {
                question = { ...question };// copy
                if (question._id === questionResponse.question) {
                    //responded question
                    question.givenAnswer = questionResponse.givenAnswer;
                    //update status
                    question.status = checkIfAnswerInCorrect(question.givenAnswer, question.options, "singleChoice") ? 'CORRECT' : 'INCORRECT';
                }
                return question;
            });
            return {
                status: status.SUCCESS,
                questions: questions
            };
        } catch (e) {
            return state;
        }
    }
    case 'TOGGLE_QUESTION__SAVED_SUCCESS': {
        //change state of question from list of questions being shown on screen.
        let questions = state.questions.map((question) => {
            question = { ...question };// copy
            if (question._id !== action.payload.questionId) {
                return question;
            } else {
                //toggle status.
                question.isSaved = !question.isSaved;

                return question;
            }
        });
        // returning questions after saved-> unsaved questions
        questions = questions && questions.length > 0 ? questions.filter((question) => {
            return question._id !== action.payload.questionId;
        }) : [];
        questions = [ ...questions ];
        return {
            status: status.SUCCESS,
            questions: questions
        };
    }
    case 'SAVED_QUESTIONS_FAILURE':
        return {
            status: status.ERROR,
            questions: state
        };
    default:
        return state;
    }
};
