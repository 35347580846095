import { getQuestionLocationIndex } from "examsbook-react-sdk/common";
import { startQuestionTimer, stopQuestionTimer } from "./questionTimer";

export const setActiveQuestionIndex = (index) => {
    return (dispatch, getState) => {
        const { quiz, isViewMode, activeQuestionIndex } = getState();
        //stop timer of old question...
        if (!isViewMode && activeQuestionIndex !== index) {
            dispatch(stopQuestionTimer(activeQuestionIndex));
        }
        dispatch({
            type: 'SET_ACTIVE_QUESTION_INDEX',
            payload: index
        });
        //update current question and select a new question.
        let { sectionNo, questionNo } = getQuestionLocationIndex(quiz, index);
        let questionsBlock = quiz.questions;
        let selectedQuestion = {};
        if (!isViewMode && questionsBlock[sectionNo]) {
            //mark selected question visited.
            questionsBlock[sectionNo].questions[questionNo].visited = true;
            quiz.questions = questionsBlock;
            //dispatch
            dispatch({
                type: 'QUIZ_UPDATED',
                payload: quiz
            });
        }
        if (questionsBlock[sectionNo]) {
            selectedQuestion = questionsBlock[sectionNo].questions[questionNo];
        }
        //change selected question.
        dispatch({
            type: 'CHANGE_SELECTED_QUESTION',
            payload: { ...selectedQuestion }
        });
        //start timeer of new question.
        if (!isViewMode) {
            return dispatch(startQuestionTimer());
        }
    };
};
