import React, { useEffect, useState } from 'react';
import { TimeUtils } from "examsbook-react-sdk/common";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useStyles, usePopupHandlerOnUnload, useQuizCountDown, useTranslations } from "examsbook-react-sdk/custom-hooks";
import TimerIcon from '../../../assets/images/Timer.png'
import CommonButton from '../../../constants/CommonButton';
import { Redirect } from 'react-router-dom';

/**
 * Quiz timer.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizTimer(props) {
    const classes = useStyles(styles)();
    const { mode, viewMode, duration, usedTime, setTimeTaken, pauseQuiz, submitQuiz, newTime, slug, handleClick, selectTimeFor, hideNext, reset, setReset } = props;
    const secondsLeft = useQuizCountDown(newTime, usedTime, pauseQuiz, viewMode, reset, selectTimeFor);
    const t = useTranslations();
    const [redirectToReports, setRedirectToReports] = useState(false);
    const [quizSubmitted, setQuizSubmitted] = useState(false);
    const [quizStarted, setQuizStarted] = useState(false);
   //set time taken.
    setTimeTaken((newTime * 60) - secondsLeft);
    usePopupHandlerOnUnload(secondsLeft > 0, viewMode);
    let time = TimeUtils.secondsToTime(secondsLeft);
   
    useEffect(() => {
        if(secondsLeft === 0 && !viewMode && !quizSubmitted && selectTimeFor && !hideNext && selectTimeFor === "QUESTION") {
            handleClick('next')
            setReset(prev => !prev);
        }
    },[secondsLeft, viewMode, selectTimeFor])

    useEffect(() => {
        if (!quizStarted) {
            setQuizStarted(true);
            return;
        }

        if (!quizSubmitted && (hideNext || selectTimeFor === "QUIZ") && secondsLeft === 0) {
            // Alert popup for submitting the quiz
            confirmAlert({
                customUI: ({ onClose }) => {
                    const handleClose = () => {
                        onClose();
                        submitQuiz();
                        setQuizSubmitted(true);
                        setRedirectToReports(true);
                    };
                    return (
                        <div className={classes.modalBg}>
                            <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                                <h1 className={classes.heading}>{t('Time over!')}</h1>
                                <p className={classes.quizTitle}>{t('Your quiz has been submitted successfully!')}</p>
                                <CommonButton onClick={handleClose} classStyleHeading={classes.okBtn} title={t('Ok')} />
                            </div>
                        </div>
                    );
                },
                willUnmount: () => {
                    setQuizSubmitted(true);
                }
            });
        }
    }, [quizSubmitted, hideNext, selectTimeFor, submitQuiz, t, classes, quizStarted]);

    function renderTimer() {
        return (
            <div
                className={classes.timer + ' ' + (((mode === 'mock-test' || mode === 'live-test') && time.h === 0 && +time.m < 3) ? classes.blinkTimer : '')}>
                {
                    !viewMode ?
                        <img alt='' className={classes.showTimerIcon} src={TimerIcon} style={{marginRight: 7}}></img> : null
                }
                {
                    !viewMode ?
                        ((time.h > 0 ? ("0" + time.h).slice(-2) + ':' : ' 00' + ':') + ("0" + time.m).slice(-2) + ':' + ("0" + time.s).slice(-2)) : ''
                }
            </div>
        );
    }

    return (
        <React.Fragment>
            {redirectToReports && (
                <Redirect
                    to={{
                        pathname: '/reports',
                        state: {
                            courseId: slug,
                            pageType: "afterTestResult",
                            params: window.location.pathname
                        }
                    }}
                />
            )}
            {!redirectToReports && 
                renderTimer()
            }
        </React.Fragment>
    );
}

const styles = theme => ({
    timer: {
        top: 7,
        left: 0,
        right: 'inherit',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        padding: '3px 10px',
        textAlign: 'left',
        lineHeight: '40px',
        color: '#000000',
        fontSize: 16,
        flexWrap: 'wrap',
        minWidth: 100,
        position: 'relative',
        '& i': {
            display: 'flex',
            fontSize: 18,
            marginRight: 5
        },
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#FFFFFF',
            marginTop: -4,
            width: '100%',
            display: 'flex',
            '& > div': {
                backgroundColor: '#FFFFFF'
            }
        },
        '@media (min-width:280px) and (max-width:320px)': {
            top: -1,
            left: 0,
            right: 'inherit',
            height: 'auto',
            display: 'contents',
            alignItems: 'center',
            padding: '3px 10px',
            textAlign: 'left',
            lineHeight: '40px',
            fontSize: 14,
            flexWrap: 'wrap',
            minWidth: 100,
        },
        '@media (min-width:321px) and (max-width:767px)': {
            top: -1,
            left: 0,
            right: 'inherit',
            height: 'auto',
            display: 'contents',
            alignItems: 'center',
            padding: '3px 10px',
            textAlign: 'left',
            lineHeight: '40px',
            fontSize: 14,
            flexWrap: 'wrap',
            minWidth: 100,
            width: 100,
        },
        '@media (min-width:768px) and (max-width:2560px)': {
            top: -1,
            left: 20,
            right: 'inherit',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            padding: '3px 10px',
            textAlign: 'left',
            lineHeight: '40px',
            fontSize: 20,
            flexWrap: 'wrap',
            minWidth: 100,
        }
    },
    okBtn: {
        backgroundColor: '#0C3450',
        color: '#fff',
        borderRadius: '4px',
        padding: '10px 30px',
        cursor: 'pointer',
        margin: '15px',
        fontSize: '15px',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    heading: {
        color: '#051733'
    },
    showTimerIcon: {
        display: 'none',
        '@media (min-width:767px)': {
            display: 'block'
        }

    },
    modalBg: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: '999999',
        position: 'fixed',
        background: '#00000080'
    },
    modalContent: {
        padding: '20px',
        background: 'white',
        textAlign: 'center',
        borderRadius: '6px',
        width: '30%',
        marginTop: '15%',
        marginLeft: '35%',
        '@media (max-width:1024px)': {
            width: '45%',
            marginLeft: "25%"
        }
    },
    quizTitle: {
        fontSize: '18px',
        color: '#000000'
    }
});
export default QuizTimer;
