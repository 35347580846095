import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import ScrollToTop from "react-router-scroll-top";
import Login from "../Auth/Login";
import ForgotPassword from "../Auth/ForgotPassword";
import ChangePassword from "../Auth/ChangePassword";
import MainContent from "../MainContent/MainContent";
import NewDashboard from "../../pages/NewDashboard/new-dashboard";
import MyCourses from "../../pages/MyCourses";
import Courses from "../CoursesList/Courses";
import CertificateList from "../CoursesList/CertificateList";
import Reports from "../../pages/Reports/reports";
import Survey from "../../pages/Survey/survey";
import SupportTicket from "../../pages/Support/SupportTicket";
import Support from "../../pages/Support/support";
import NotificationDetail from "../common/Header/NotificationDetail";
import TopicMaterial from "../../pages/TopicMaterial";
import Quiz from "../../pages/quiz";
import NotFoundPage from "../common/Errors/NotFoundPage";
import Settings from "../../pages/Settings";
import { LanguageSwitcher } from "../../pages/languageSwitcher";

export function Routes() {
  const authRoutes = [
    { path: '/login', component: Login, title: "Login | LMS" },
    { path: '/forgot-password', component: ForgotPassword, title: "Forgot Password | LMS" },
    { path: '/change-password', component: ChangePassword, title: "Change Password | LMS" },
  ];

  const appRoutes = [
    { path: '/dashboard', component: NewDashboard },
    { path: '/my-courses', component: MyCourses },
    { path: '/courses', component: Courses },
    { path: '/certificate-list', component: CertificateList },
    { path: '/reports', component: Reports },
    { path: '/surveys', component: Survey },
    { path: '/support-ticket', component: SupportTicket },
    { path: '/support', component: Support },
    { path: '/notifications', component: NotificationDetail },
    { path: '/course-material/content', component: TopicMaterial },
    { path: '/my-profile', component: Settings },
    { path: '/quiz/:mode/:slug', component: Quiz },
    {path: '/change-language', component: LanguageSwitcher},
  ];

  return (
    <BrowserRouter>
      <Switch>
        {/* Auth Routes */}
        {authRoutes.map((ele, index) => (
          <PublicRoute
            exact
            key={`auth-${index}`}
            path={ele.path}
            component={ele.component}
            title={ele.title}
          />
        ))}

        {/* App Routes */}
        <Route
          path="/"
          render={() => (
            <MainContent>
              <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop>
                  <Switch>
                    {appRoutes.map((ele, index) => (
                      <PrivateRoute
                        exact
                        key={`app-${index}`}
                        path={ele.path}
                        component={ele.component}
                      />
                    ))}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ScrollToTop>
              </Suspense>
            </MainContent>
          )}
        />

        {/* Default Route Redirection */}
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
