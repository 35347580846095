import { getSurveyApi } from "../services/surveyService";

export const getSurveyAction = () => (dispatch) => {
    dispatch(request());
    return getSurveyApi()
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "SURVEY_REQUEST" };
    }

    function success(response) {
        return { type: "SURVEY_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "SURVEY_FAILURE", payload: error };
    }
};
