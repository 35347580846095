import status from '../constants/actionStatus';

const initialState = {
    defaultSeoSettings: [],
    status: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case 'GET_DEFAULT_SEO_SETTINGS_REQUEST':
        return {
            ...state,
            status: status.PENDING
        };

    case 'GET_DEFAULT_SEO_SETTINGS_SUCCESS':
        return {
            ...state,
            status: status.SUCCESS,
            defaultSeoSettings: action.payload.data
        };

    case 'GET_DEFAULT_SEO_SETTINGS_FAILURE':
        return {
            ...state,
            status: status.ERROR
        };

    default:
        return state;
    }
};
