import axios from "./axiosConfig";

/**
 * change password
 * @param old_password
 * @param new_password
 * @param confirm_password
 * @param email_param
 * @returns {response}
 */
export function passwordChangedNewUser(userData) {
    const { email, mobileNumber, oldPassword, confirmPassword, newPassword, subdomain } = userData;
    let request = { email, mobileNumber, oldPassword, confirmPassword, password: newPassword, subdomain };

    return axios
        .post("/changePassword/newUser", request)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(response?.data?.message || "Failed to change password");
            }
        })
        .catch((error) => {
            console.error("API Error:", error);
            throw error;
        });
}

export function forgotPasswordNewUser(userData) {
    const { email, mobileNumber, oldPassword, confirmPassword, newPassword, subdomain } = userData;
    let request = { email, mobileNumber, oldPassword, confirmPassword, password: newPassword, subdomain };

    return axios
        .post("/forgotPassword/newUser", request)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

/**
 * Change password
 * @param password
 * @param confirmPassword
 * @param token
 * @returns {response}
 */
export const changePassword = (password, confirmPassword, token) => {
    let data = {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
    };
    return axios.post("/auth/password/verifyResetPasswordToken", data);
};

/**
 * request Otp for forgot password
 * @param userInput
 * @param emailOrMobile
 * @returns {response}
 */
export const requestOtpforForgotPassword = (data) => {
    return axios.post("/auth/password/resetPassword", data)
        .then((response) => {
            return response.data;
        })
        ;
};

/**
 * Get Current User
 * @param userId
 * @returns {response}
 */
export function currentUser(userId) {
    return axios
        .get(userId ? "/api/v1/me?user=" + userId : "/api/v1/me")
        .then(function (response) {
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            return error;
        });
}

/**
 * Update User
 * @param userId
 * @param data
 * @returns {response}
 */
export function updateSingleUser(userId, data) {
    return axios({
        method: "put",
        url: "/api/v2/user/" + userId,
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
}

export const updateProfile = async (data) => {
    return axios({
        method: "put",
        url: "/api/v1/updateProfile/" + (data && data.id),
        data: data.value,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
};

export const updateProfileImage = async (userData) => {
    let data = {
        image: userData.imageName,
        base64: userData.image,
    };
    return axios({
        method: "put",
        url: "/api/v1/updateProfile/" + userData.id,
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

/**
 * Get Me
 * @returns {response}
 */
export function getMe() {
    return axios
        .get("/api/v1/me")
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
}

/**
 * Update Password
 * @param userData
 * @returns {response}
 */
export function updatePassword(userData) {
    return axios
        .post("/auth/password/changePassword", userData)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
}
