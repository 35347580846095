import React from "react";
import { Box } from "@material-ui/core";
import {
  useStyles,
  useTranslations,
  useUserLanguage,
} from "examsbook-react-sdk/custom-hooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ColorsConstant } from "../constants/colorConstant";

export const LanguageSwitcher = () => {
  const t = useTranslations();
  const { language, setUserLanguage } = useUserLanguage();
  const styles = {
    languageChange: {
      padding: "30px 20px",
      "& h6": {
        fontSize: 20,
        margin: "0 0 30px",
      },
      "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        "& li": {
          border: `1px solid ${ColorsConstant.cardBorders}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          marginBottom: 15,
          borderRadius: 5,
          minHeight: 66,
          "& span": {
            fontSize: 16,
          },
          "& svg": {
            color: ColorsConstant.defaultBlue,
            display: "none",
          },
          "&.active": {
            border: `1px solid ${ColorsConstant.defaultBlue}`,
            "& svg": {
              display: "flex",
            },
          },
        },
      },
    },
  };
  const classes = useStyles(styles)();
  console.log(language);
  return (
    <Box className={classes.languageChange}>
      <h6>Language</h6>
      <ul>
        <li
          className={language === "en" ? "active" : ""}
          onClick={() => setUserLanguage("en")}
        >
          <span>{t("english")}</span>
          <CheckCircleIcon />
        </li>
        <li
          className={language === "hn" ? "active" : ""}
          onClick={() => setUserLanguage("hn")}
        >
          <span>{t("hindi")}</span>
          <CheckCircleIcon />
        </li>
      </ul>
    </Box>
  );
};
