export default (state = {
    id: {},
    givenAnswer: []
}, action = {}) => {
    switch (action.type) {
    case 'CHANGE_SELECTED_QUESTION': {
        return action.payload;
    }
    case 'QUESTION_TIMER_START': {
        state.timeSpent = state.timeSpent ? state.timeSpent : 0;
        return state;
    }
    case 'UNSAVE_QUESTION_SUCCESS': {
        try {
            let updatedQuestionId = action.payload.questionId;
            // questions data
            let question = { ...state };// copy
            if (question.id._id !== updatedQuestionId) {
                return question;
            }
            question.isSaved = !question.isSaved;
            return question;

        } catch (error) {
            console.log('error-------', error);
            return state;
        }
    }
    case 'QUESTION_TIMER_TICK': {
        state.timeSpent = state.timeSpent + 1;
        return state;
    }
    default:
        return state;
    }
}
