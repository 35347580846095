export default function getQuizStatus(questions) {

    let status = {
        answered: 0,
        marked: 0,
        notVisited: 0,
        markedAndAnswered: 0,
        notAnswered: 0,
        totalMarks: 0,
        obtained: 0,
        timeSpentArray: 0,
        notAnsweredVisited: 0
    };
    if (questions && questions.length > 0) {
        questions.forEach((data) => {
            status = data.questions.reduce((total, item) => {
                if (item.answered) {
                    total.answered++;
                }
                if (item.marked) {
                    total.marked++;
                }
                if (item.visited && !item.answered) {
                    total.notAnsweredVisited++;
                }
                if (!item.visited) {
                    total.notVisited++;
                }
                if (item.marked && item.answered) {
                    total.markedAndAnswered++;
                }
                if (!item.answered) {
                    total.notAnswered++;
                }
                if (item.marked || !item.marked) {
                    total.totalMarks = total.totalMarks + item.mark;

                }
                if (item.givenAnswer && item.givenAnswer.length) {
                    item.options.map((option) => {
                        if (item.givenAnswer[0] === option._id && option.correct === true) {
                            total.obtained = total.obtained + item.mark;
                        }
                        if (item.givenAnswer[0] === option._id && !option.correct === true) {
                            total.obtained = total.obtained - item.penalty;
                        }
                    });
                }
                return total;
            }, status);
        });
    }
    return status;
}
