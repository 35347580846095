import { getActivePromotions } from "../services/promtionsService";

export const getPromotions = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: 'PROMOTIONS_WAITING',
            payload: []
        });

        getActivePromotions()
            .then((promotionsList) => {
                // handle success
                dispatch({
                    type: 'PROMOTIONS_SUCCESS',
                    payload: promotionsList
                });
                resolve(promotionsList);
            })
            .catch((error) => {
                // handle error
                dispatch({
                    type: 'PROMOTIONS_ERROR',
                    payload: []
                });
                reject(error);
            });
    });
};
