import React from "react";
import useStyles from './styles'
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import CommonButton from "../../../constants/CommonButton";
import { Box, Button, Typography } from "@material-ui/core";

const ContinueVideoDialog = (props) => {
  const { 
    showModal, 
    closeModal, 
    handleYes,
    handleNo
  } = props;

  const classes = useStyles();
  const t = useTranslations();
  
  return (
    <Box className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
      <Box className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <Box className={classes.modalHeading}>
          <Typography variant="h2" className={classes.modalText}>{t("Do you want to continue watching this video?")}</Typography>
          <Box className={classes.buttonGroup}>
            <CommonButton onClick={handleYes} title={t('Yes')} classStyleHeading={classes.yesButton} />
            <Button onClick={handleNo} className={classes.noButton}>{t('No')}</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContinueVideoDialog