/**
 * Block Developer tool (inspect element window)
 * @constructor
 */
function blockDeveloperTool() {
    document.onkeydown = function (e) {
        if (e.keyCode === 123) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
            return false;
        }
    };
    // Restrict context menu in quiz section
    document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });
}

export default blockDeveloperTool;
