import status from '../constants/actionStatus';
import { saveQuizResponses } from "../services/quizService";

export const saveQuizResponse = (responseObj) => {
    return (dispatch, getState) => {
        //format user response ===
        const { selectedQuestion } = getState();
        let response = [];
        if (responseObj?.response && responseObj?.response?.length > 0) {
            response = responseObj?.response?.map((questionSection) => {
                let section = {
                    section: questionSection?.section,
                    _id: questionSection?._id,
                    questions: []
                };
                if (questionSection?.questions) {
                    section.questions = questionSection.questions.map((question) => {
                        let timeSpent = question?.timeSpent;
                        if (question?._id === selectedQuestion?._id) {
                            timeSpent = selectedQuestion?.timeSpent;
                        }
                        return {
                            active: question?.active,
                            givenAnswer: question?.givenAnswer,
                            answered: question?.answered,
                            mark: question?.mark,
                            visited: question?.visited,
                            penalty: question?.penalty,
                            title: question?.title,
                            marked: question?.marked,
                            id: question?._id,
                            timeSpent: timeSpent
                        };
                    });
                }
                return section;
            });
        }
        responseObj.response = response;
        if (responseObj?.completed) {
            dispatch({
                type: 'SAVE_QUIZ_REQUEST',
                payload: {
                    status: status?.PENDING
                }
            });
        }
        return saveQuizResponses(responseObj).then(function (resp) {
            if (responseObj?.completed) {
                dispatch({
                    type: 'SAVE_QUIZ_SUCCESS',
                    payload: {
                        data: resp?.data,
                        status: status?.SUCCESS
                    }
                });
                return resp?.data;
            } else if (resp?.data) {
                if (resp?.data?.alreadyCompleted) {
                    //redirect to view page.
                    let url = window?.location?.href;
                    let slug = url?.substring(url?.lastIndexOf('/') + 1);
                    window.location.href = '/quiz/view/' + slug;
                }
            }
        })
            .catch(function (error) {
                dispatch({
                    type: 'SAVE_QUIZ_ERROR',
                    payload: {
                        status: status.ERROR
                    }
                });
            });
    };

};
