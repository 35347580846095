export default (state = {}, action = {}) => {
    switch (action.type) {
    case 'GET_QUIZ_RESPONSE_REQUEST':
        return action.payload;
    case 'GET_QUIZ_RESPONSE_SUCCESS':
        return action.payload;
    case 'GET_QUIZ_RESPONSE_ERROR':
        return action.payload;
    default:
        return state;
    }
}
