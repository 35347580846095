import {
    getCourseDetails,
    getUserCourses,
    getTopics,
    getSubjects,
    getFilteredCourses,
    getAllUserCourses,
    addToCourseApi,
} from "../services/courseService";

export const getCourseList = (id) => (dispatch) => {
    dispatch(request());
    return getAllUserCourses(id)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "COURSE_LIST_REQUEST" };
    }

    function success(response) {
        return { type: "COURSE_LIST_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "COURSE_LIST_FAILURE", payload: error };
    }
};

export const addToCoures = (courseId, userId) => (dispatch) => {
    dispatch(request());
    return addToCourseApi(courseId, userId)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "ADD_TO_COURSE_REQUEST" };
    }

    function success(response) {
        return { type: "ADD_TO_COURSE_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "ADD_TO_COURSE_FAILURE", payload: error };
    }
};

export const getCourse = (courseId) => (dispatch) => {
    dispatch(request());
    getCourseDetails(courseId)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "COURSE_REQUEST" };
    }

    function success(response) {
        return { type: "COURSE_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "COURSE_FAILURE", payload: error };
    }
};

export const getUserCourse = (userId) => (dispatch) => {
    dispatch(request());
    return getUserCourses(userId)
        .then((response) => {
            // handle success
            dispatch(success(response));
            dispatch(setActive(response));
            return response;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "USER_COURSE_REQUEST" };
    }

    function success(response) {
        return { type: "USER_COURSE_SUCCESS", payload: response };
    }

    function setActive(response) {
        return dispatch({
            type: "SELECT_USER_COURSE_SUCCESS",
            payload: response.data[0].courses[0],
        });
    }

    function failure(error) {
        return { type: "USER_COURSE_FAILURE", payload: error };
    }
};

export const getCourseSubjects = (courseId) => (dispatch) => {
    dispatch(request());
    getSubjects(courseId)
        .then((response) => {
            // handle success
            dispatch(success(response));
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "SUBJECT_REQUEST" };
    }

    function success(response) {
        return { type: "SUBJECT_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "SUBJECT_FAILURE", payload: error };
    }
};

export const getCourseTopics = (courseId, subjectId) => (dispatch) => {
    dispatch(request());
    getTopics(courseId, subjectId)
        .then((response) => {
            // handle success
            dispatch(success(response));
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "TOPIC_REQUEST" };
    }

    function success(response) {
        return { type: "TOPIC_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "TOPIC_FAILURE", payload: error };
    }
};

export const getFilteredCourseList = (id, examId) => (dispatch) => {
    dispatch(request());
    getFilteredCourses(id, examId)
        .then((response) => {
            // handle success
            dispatch(success(response));
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "FILTERED_COURSES_REQUEST" };
    }

    function success(response) {
        return { type: "FILTERED_COURSES_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "FILTERED_COURSES_FAILURE", payload: error };
    }
};
