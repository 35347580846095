import React, { useState, useRef } from 'react';
import { FormControl, TextField, MenuItem, Button, IconButton, makeStyles, Box, Typography, Input } from '@material-ui/core';
import { AttachFile as AttachFileIcon } from '@material-ui/icons';
import './support.scss';
import { useDispatch } from 'react-redux';
import { saveFeedbackForm } from 'examsbook-react-sdk/actions/saveFeedback';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import OverlayButton from '../../constants/OverlayButton';
import { useTheme } from '../../constants/themeProvider';

const Support = () => {
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [subject, setSubject] = useState('');
    const [others, setOthers] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const t = useTranslations();
    const theme = useTheme();
    const history = useHistory();
    const [services, setServices] = useState([]);
    const [isOthersSelected, setIsOthersSelected] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [serviceOptions, setServiceOptions] = useState([]);
    const classes = useStyles();
    const [isModalVisible, setModalVisible] = useState(false);
    const [isDeparmentVisible, setDepartmentVisible] = useState(false);
    const [isSubjectVisible, setSubjectVisible] = useState(false);
    const [isEmailVisible, setEmailVisible] = useState(false);
    const [isMessageVisible, setMessageVisible] = useState(false);
    const [isServiceVisible, setServiceVisible] = useState(false);
    const [isOthersVisible, setOthersVisible] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [fileExtensionError, setFileExtensionError] = useState(false);
    const [fileExtension, setFileExtension] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const options = [
        { value: 'support', label: t('Support') },
        { value: 'billing', label: t('Billing') },
        { value: 'technical', label: t('Technical') },
    ];

    const handleChange = (option) => {
        setSelectedOption(option);
        handleDepartmentChange(option);
    };

    const handleDepartmentChange = (option) => {
        setSelectedOption(option);
        setSelectedService(null);
        setIsOthersSelected(false);
        let services;
        if (option.value === 'support') {
            services = [t('Assessment Support'), t('Account Management'), t('Billing and Payment'), t('Content Support'), t('Course Enrollment'), t('General Enquiry'), t('Others')];
            setSelectedDepartment(option.value);
            setDepartmentVisible(false);
        } else if (option.value === 'billing') {
            services = [t('Billing Inquiries'), t('Invoice Request'), t('Payment Issue'), t('Refund Request'), t('Others')];
            setSelectedDepartment(option.value);
            setDepartmentVisible(false);
        } else if (option.value === 'technical') {
            services = [t('Course material Access'), t('Integration Support'), t('Login Issue'), t('Software Update'), t('System Error'), t('Others')];
            setSelectedDepartment(option.value);
            setDepartmentVisible(false);
        }
        setServiceOptions(services.map(service => ({ value: service, label: t(service) })));
    };

    const handleServiceChange = (option) => {
        setSelectedService(option);
        setIsOthersSelected(option.value === 'Others' || option.value === 'अन्य');
        setServiceVisible(false);
    };

    const handleInputChange = (event, setter, maxLength, errorSetter) => {
        if (event.target.value.length <= maxLength) {
            setter(event.target.value);
            if (errorSetter) {
                errorSetter(false);
            }
        }
    };
    
    const validateFile = (file) => file.size < 5 * 1024 * 1024;
    
    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleFileIconClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setModalVisible(false);
        setFileExtensionError(false);
        
        const file = event.target.files[0];
        
        if(!file) {
            return;
        }
        
        const allowedExtensions = ['.jpeg', '.jpg', '.gif', '.png', '.pdf'];
        const ext = file?.name?.substring(file?.name?.lastIndexOf('.'));

        if (!allowedExtensions.includes(ext?.toLowerCase())) {
            setFileExtensionError(true);
            setSelectedFile(null);
            setSelectedFileName(null);
            return;
        } else if (!validateFile(file)) {
            setModalVisible(true);
            setSelectedFile(null);
            setSelectedFileName(null);
            return;
        }
        let reader = new FileReader();
        reader.onloadend = function() {
            setSelectedFile(reader.result);
            setSelectedFileName(file.name);
        }
        reader.readAsDataURL(file);
        setModalVisible(false);
        setFileExtensionError(false);
        setFileExtension(ext?.toLowerCase()); 
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleSubmit = () => {
        let data = {
            department: selectedDepartment,
            subject: subject,
            email: email,
            message: message,
            service: selectedService?.value,
            file: selectedFile,
            serviceMessage: isOthersSelected ? others : ''
        }
        if (data.department && data.subject && data.message && data.service && validateEmail(email) && (!isOthersSelected || (isOthersSelected && data.serviceMessage))) {
            dispatch(saveFeedbackForm(data));
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
                history.push('/support-ticket');
                setIsSubmitting(false);
            }, 2000);
        } if (!data.department) {
            setDepartmentVisible(true);
        } if (!data.subject) {
            setSubjectVisible(true);
        } if (!validateEmail(email)) {
            setEmailVisible(true);
        } if (!data.message) {
            setMessageVisible(true);
        } if (!data.service) {
            setServiceVisible(true);
        } if (isOthersSelected && !data.serviceMessage.length) {
            setOthersVisible(true);
            setIsSubmitting(false);
        }

    };

    return (
        <Box className="support-container">
            <Box className="support-ticket-box">
            {showNotification && (
                <Box className={classes.notification}>
                    {t('Support ticket created successfully!')}
                </Box>
            )}
                <Box className='description'>
                {t('Provide ticket details below. When reporting a problem make sure to provide as much relevant information as possible.')}
                </Box>
                <Box className='form-box'>
                    <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Department')}</Typography><Typography component="label" className='red-star'> *</Typography>
                    <FormControl fullWidth>
                        <Select 
                        labelId="department-label"
                        placeholder={t('Select')}
                        id="department"
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        styles={{
                            option: (provided) => ({
                            ...provided,
                            fontWeight: '400'
                            }),
                        }}
                        />
                    </FormControl>
                    {selectedOption && (
                        <Box>{`Queries related to ${selectedOption.label} (Issues, Suggestion, Questions)`}</Box>
                    )}
                    {isDeparmentVisible && <Typography variant="p" className='validation-text'>
                        {t('Select Department')}
                    </Typography>}
                </Box>
                <Box className='form-box'>
                    <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Subject')}</Typography><Typography component="label" className='red-star'> *</Typography>
                    <TextField
                        id="subject"
                        fullWidth
                        placeholder={t('Enter Subject')}
                        className="subject-input"
                        value={subject}
                        onChange={(event) => handleInputChange(event, setSubject, 50, setSubjectVisible)}
                    />
                    {isSubjectVisible && <Typography variant="p" className='validation-text'>
                        {t('Enter Subject')}
                    </Typography>}
                </Box>
                <Box className='form-box'>
                    <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Email')}</Typography><Typography component="label" className='red-star'> *</Typography>
                    <TextField
                        id="email"
                        fullWidth
                        placeholder={t('Enter Email')}
                        className="email-input"
                        value={email}
                        onChange={(event) => handleInputChange(event, setEmail, 50, setEmailVisible)}
                    />
                    {isEmailVisible && <Typography variant="p" className='validation-text'>
                        {t('Enter valid Email')}
                    </Typography>}
                </Box>
                <Box className='form-box'>
                    <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Message')}</Typography><Typography component="label" className='red-star'> *</Typography>
                    <TextField
                        id="message"
                        placeholder={t('Enter Message')}
                        fullWidth
                        multiline
                        rows={6}
                        className="message-input"
                        value={message}
                        onChange={(event) => handleInputChange(event, setMessage, 250, setMessageVisible)}
                    />
                    {isMessageVisible && <Typography variant="p" className='validation-text'>
                        {t('Enter Message')}
                    </Typography>}
                </Box>
            </Box>
            <Box className="support-service-box new-service">
                <Box className='service-title'>{t('Concerned Service')}</Box>
                <Box className='form-box'>
                    <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Select the related service')}</Typography><Typography component="label" className='red-star'> *</Typography>
                    <FormControl fullWidth>
                        <Select 
                            labelId="service-label"
                            id="service"
                            placeholder={t('Select')}
                            value={selectedService}
                            onChange={handleServiceChange}
                            options={serviceOptions}
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    fontWeight: '400',
                                }), 
                                menu: (provided) => ({
                                    ...provided,
                                    overflow: 'auto',
                                    maxHeight: 160,
                                })
                            }}
                        />
                    </FormControl>
                    {services.map((service, index) => (
                        <MenuItem key={index} value={service}>{service}</MenuItem>
                    ))}
                    {isServiceVisible && <Typography variant="p" className='validation-text'>
                        {t('Choose Service')}
                    </Typography>}
                </Box>
                {isOthersSelected && (
                    <Box className='form-box'>
                        <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Please provide more details')}</Typography><Typography component="label" className='red-star'> *</Typography>
                        <TextField
                            id="others"
                            placeholder='Enter more details'
                            fullWidth
                            multiline
                            rows={6}
                            className="message-input"
                            value={others}
                            onChange={(event) => handleInputChange(event, setOthers, 250, setOthersVisible)}
                        />
                        {isOthersSelected && isOthersVisible && <p className='validation-text'>
                        {t('Enter more details')}
                    </p>}
                    </Box>
                )}
                <Box>
                <Typography component="label" className='form-label' style={{color: theme?.secondaryColor}}>{t('Upload File')}</Typography>
                <Box className="attachment-section">
                    <Input
                        id="selectedAttachment"
                        inputRef={fileInputRef}
                        type="file"
                        accept=".jpeg, .jpg, .gif, .png, .pdf"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <IconButton onClick={handleFileIconClick} component="label">
                        <AttachFileIcon />
                    </IconButton>
                    {selectedFileName && (
                        <Box>{`Attached file: ${selectedFileName}`} &nbsp;</Box>
                    )}
                    <Box className="attachment-message">{t('Allowed files')}: .jpeg, .jpg, .pdf, .png, .gif</Box>
                    <Box className="file-size">{t('Max 5 MB')}</Box>
                </Box>
                {selectedFile && fileExtension !== '.pdf' && (
                    <Box component="img" src={selectedFile} alt="Preview" style={{maxWidth: '100%', maxHeight: '200px'}} />
                )}
                </Box>
                {selectedFile && fileExtension === '.pdf' && (
                    <object data={selectedFile} type="application/pdf" maxWidth="100%" maxHeight="200px">
                        <embed src={selectedFile} type="application/pdf" />
                    </object>
                )}
               {isModalVisible && <Typography variant="p" className='file-size'>
                    {t('File is too large!')}
                </Typography>}

                {fileExtensionError && <p className='file-size'>{t('You cannot upload this type of file!')}</p>}
            </Box>
            <Box className='submit-button'>
                <Button variant="outlined" onClick={handleBack} className='back-btn'>
                {t('Back')}
                </Button>
                <OverlayButton onClick={handleSubmit} title={t('Submit')} classStyleHeading={!fileExtensionError && !isModalVisible ? classes.submitButton : classes.submitButton + " " + classes.buttonDisabled} disabled={isSubmitting || fileExtensionError || isModalVisible}/>
            </Box>
        </Box>

    );
}

const useStyles = makeStyles(theme => ({
    submitButton: {
        // backgroundColor: '#0C3450',
        textTransform: 'capitalize',
        display: 'block',
        color: '#fff',
        marginBottom: '10px',
        borderRadius: '5px',
        '&:hover': {
            background: '#0c3450e0 !important'
        }
    },
    notification: {
        right: '20px',
        padding: '10px',
        position: 'fixed',
        top: '76px',
        zIndex: '1',
        backgroundColor: 'green',
        color: 'white',
        borderRadius: '20px',
        width: '300px',
        textAlign: 'center',
        fontWeight: '600'
    },
    buttonDisabled: {
        opacity: "0.4",
        cursor: "not-allowed",
    },
    
}));

export default Support;