import { useState } from "react";
import { saveErrorReport } from "../services/reportError";
import { reactGaEvent } from "examsbook-react-sdk/actions";

export default function useFeedbackModel() {
    const [ isFeedbackModelOpen, setFeedbackOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ questionId, setQuestionId ] = useState('');

    function openFeedbackModel(errorData) {
        setFeedbackOpen(true);
        setMessage(errorData.message);
        setQuestionId(errorData.questionId);
        setTitle(errorData.title);
    }

    function reportErrorFn(errorMsg) {
        const errorObj = {
            questionId: questionId,
            title: title,
            message: message + ' : ' + errorMsg
        };
        saveErrorReport(errorObj).then(() => {
            reactGaEvent("Report", "Submit Error");
            closeFeedBackModel();
        });
    }

    function closeFeedBackModel() {
        setFeedbackOpen(false);
    }

    return {
        isFeedbackModelOpen,
        openFeedbackModel,
        reportErrorFn,
        closeFeedBackModel
    };
}
