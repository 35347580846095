import React, { useEffect, useState } from 'react';
import './survey.scss';
import { Box, Button, Typography, Modal, TextField, makeStyles } from '@material-ui/core';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { useDispatch } from 'react-redux';
import { getSurveyAction } from 'examsbook-react-sdk/actions/getSurveyAction';
import { useTheme } from '../../constants/themeProvider';
import { useSearchQuery } from '../../services/SearchQueryContext';
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/close.svg";
import NotFound from '../NotFound';

const MyModal = ({ showModal, closeModal, setModalVisible }) => {
    const t = useTranslations();
    return (
      <Modal
        open={showModal}
        onClose={closeModal}
        className={`modal-bg ${showModal ? 'show' : ''}`}
        onClick={closeModal}
      >
        <Box
          className='modal-content copy-link-modal'
          onClick={(e) => e.stopPropagation()}
        >
          <Box className='heading'>
            <Typography variant="inherit" className='heading-text'>
              {t('Link_copied_to_clipboard!')}
            </Typography>
            <Box className='ok-align'>
              <Box
                onClick={() => { setModalVisible(false) }}
                className='ok-btn'
              >
                {t('Ok')}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
};

const Survey = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [surveyData, setSurveyData] = useState([]);
    const [showComponent, setShowComponent] = useState(false);
    const theme = useTheme();
    const { searchQuery, setSearchQuery } = useSearchQuery();
    const t = useTranslations();
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const classes = useStyles(isFirefox);

    function stripHtml(html) {
        let tmp = document.createElement("p");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
      }

      function clearField(searchQuery) {
        if(searchQuery.length)
           setSearchQuery('')
      };

    const copyLink = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
            openModal();
        } catch (err) {
            window.alert('Failed to copy link: ', err);
        }
    };

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    useEffect(()=> {
        dispatch(getSurveyAction()).then((response) => {
            try {
              if (Array?.isArray(response?.data)) {
                setSurveyData(response?.data);
              }
            } catch (error) {
              console.log('Error - ', error.message);
            }
        })
    },[])

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowComponent(true);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);

    const lowerSearchQuery = searchQuery?.toLowerCase().trim();
    const filteredSurveyData = surveyData?.filter((item, index) => {
        const title = item?.title;
        const titleText = stripHtml(title).toLowerCase();
        const lowerTitle = title.toLowerCase();
        return (
        lowerTitle.includes(lowerSearchQuery) ||
        titleText.includes(lowerSearchQuery)
        );
    });
    const filteredSurveys =
    searchQuery && searchQuery.trim() !== ""
    ? filteredSurveyData
    : surveyData;

    useEffect(() => {
        setSearchQuery('')
        return setSearchQuery('');
    }, []);

    return (
        <Box className="survey-container">
            <Box className="course-search-box no-mobile-padding">
                <TextField
                    variant="outlined"
                    placeholder={t('Search')}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event?.target?.value)}
                    fullWidth
                    className="inner-search-box"
                    type="text"
                />
                <Box className="course-search-img-box">
                {searchQuery?.length ? (
                    <Button onClick={()=>clearField(searchQuery)} style={{border:'none'}}>
                      <CrossIcon className="cross-icon" alt="Cross Icon" />
                    </Button>
                  )
                :
                (<SearchIcon className="search-icon" alt="Search Icon" />)
                }
                </Box>
            </Box>
            {
                filteredSurveys && filteredSurveys?.length > 0 ? (
                    filteredSurveys?.sort((a, b) => new Date(b?.updated?.on) - new Date(a?.updated?.on)).map((item, index) => {
                        const currentTime = Date.now();
                        const scheduledDate = Date.parse(item?.updated?.on);
                        const differenceInMilliseconds = Math.abs(currentTime - scheduledDate);
                        const differenceInSeconds = differenceInMilliseconds / 1000;
                        const differenceInMinutes = differenceInSeconds / 60;
                        const differenceInHours = differenceInMinutes / 60;
                        const differenceInDays = differenceInHours / 24;

                        let timeAgo;
                        if (differenceInMinutes < 60) {
                            timeAgo = `${Math.floor(differenceInMinutes)} min ago`;
                        } else if (differenceInHours < 24) {
                            timeAgo = `${Math.floor(differenceInHours)} h ago`;
                        } else {
                            timeAgo = `${Math.floor(differenceInDays)} d ago`;
                        }
                        return (
                            <Box className="survey-detail-items" key={index}>
                                <Box className="survey">
                                    <Box className="survey-detail-profile-pic">
                                        <Typography className='count-data' style={{color: theme?.primaryColor}}>
                                        {index < 9 ? `0${index + 1}` : index + 1}
                                        </Typography>
                                    </Box>
                                    <Box className="survey-text">
                                    <Box className="survey-content">
                                        <Typography component="a" href={item?.url} target="_blank" className="survey-detail-heading">
                                        {item?.title}
                                        </Typography>
                                        <Typography component="p" className="survey-detail-info">
                                        {timeAgo}
                                        </Typography>
                                    </Box>
                                        <Typography component="a" href={item?.url} target="_blank" className="survey-detail-heading">
                                            <Button className="survey-time">
                                                {t('Open')}
                                            </Button>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                ) : showComponent && (
                    <Box className={classes.centerAlign}><NotFound /></Box>
                )
            }
            {isModalVisible && <MyModal
                showModal={isModalVisible}
                closeModal={closeModal}
                setModalVisible={setModalVisible}
            />}
        </Box>
    );
}
const useStyles = makeStyles(() => ({
  centerAlign: isFirefox => ({
    textAlign: isFirefox ? '-moz-center' : '-webkit-center',
  }),
}));
export default Survey;
