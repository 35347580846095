export default (state = {}, action = {}) => {
    switch (action.type) {
    case 'GET_CURRENT_USER':
        return action.payload;
    case 'USER_UPDATE':
        return action.payload;
    default:
        return state;
    }
}
