import React, { useEffect, useState } from 'react';
import { useTranslations, useStyles, useCourseContent } from "examsbook-react-sdk/custom-hooks";
import TabContainer from "./TabContainer";
import NoData from "../common/Nodata/noData";
import QuizListSection from '../QuizList/QuizListSection';
import CertificateCard from './CertificateCard';
import SelfLearningCard from './SelfLearningCard';
import { Box } from '@material-ui/core';
import LiveClassCard from './LiveClassCard'
import "./SelfLearning.scss";
import { getSelfLearningAction } from 'examsbook-react-sdk/actions/getSelfLearningAction';
import { useDispatch } from 'react-redux';
import { useSearchQuery } from '../../services/SearchQueryContext';

/**
 * Course Material Container.
 * @param props
 * @returns {*}
 * @constructor
 */

function TopicMaterialContainer(props) {
    const {
        selectedIndex,
        course_id,
        subject_id,
        topic_id,
        setdownloadCertificateButton,
        downloadCertificateButton,
        onQuizCardClick,
        videoRefresh,
        pdfOpen
    } = props;
    const t = useTranslations();
    const classes = useStyles(styles)();

    const [mySectionData, setMySectionData] = useState([]);

    const [refresh, setRefresh] = useState(true);
    const handleRefresh = () => setRefresh(!refresh);
    const dispatch = useDispatch();
    const [currentActiveSection, setCurrentActiveSection] = useState(props?.activeItem?.data);

    const { content } = useCourseContent(course_id, subject_id, topic_id, refresh);
    const { data: courseDetail } = content;

    const [quiz, setQuiz] = useState([]);
    const passingPercent = quiz[0]?.testId?.passingPercent;
    const { setNewFlag } = useSearchQuery();

    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        setNewFlag(true);
        return () => setNewFlag(false);
    }, []);

    const getCourseSectionApiData = async () => {
    try {
      if (course_id !== undefined) {
        dispatch(getSelfLearningAction(course_id)).then((res) => {
            setMySectionData(res);
            if (!props.activeItem && typeof res != "undefined") {
                props.setActiveItem({
                  data:res?.data?.section[0],
                  sectionData:res
                });
            }
        });
      }
    } catch (error) {
        console.log('Error - ', error.message);
    }
  };

  useEffect(() => {
      getCourseSectionApiData();
  }, [course_id, videoRefresh, pdfOpen]);
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    useEffect(() => {
        if (mySectionData && mySectionData?.data?.evaluationTest) {
            setQuiz(mySectionData?.data?.evaluationTest);
        } else {
            setQuiz([]);
        }
    }, [courseDetail,mySectionData]);

    return (
        <div>
      {/* {selectedIndex === 0 && (
        <TabContainer>
            <div className={classes.alignHeight}>
                    <div className={classes.heading}>{t('SELF LEARNING')}</div>
                            {mySectionData?.data?.section !== undefined ? mySectionData?.data?.section?.map((item, index) => {
                                return(<SelfLearningCard currentActiveSection={currentActiveSection} setCurrentActiveSection={setCurrentActiveSection} setActiveItem={props.setActiveItem} activeItem={props.activeItem} index={index} data={item} sectionData={mySectionData?.data} activeIndex={activeIndex} setActiveIndex={setActiveIndex} onVideoRefresh={props?.onVideoRefresh} videoRefresh={props?.videoRefresh} pdfOpen={props?.pdfOpen} onPdfOpen={props?.onPdfOpen} sectionChanged={props?.sectionChanged} setSectionChanged={props?.setSectionChanged}/>)
                             }) : <></>}</div>
        </TabContainer>
      )} */}
                {/* {selectedIndex === 1 && (
                <>
                    <TabContainer className="SavedQuiz">
                        <div className={classes.heading}>{t('LIVE_CLASS')}</div>
                        <Box className={classes.cardBox} >
                            {mySectionData?.data?.liveClass?.length ? mySectionData?.data?.liveClass.map((item, index) => {
                                return (<LiveClassCard key={index} data={item} sectionData={mySectionData?.data} />)
                            }) : <><div className={classes.noLiveClassText}>{t('No Live Class Found!')}</div></>}
                        </Box>
          </TabContainer>
        </>
      )} */}
      {/* {selectedIndex === 2 && (
        <>

                    <div className={classes.heading}>{t('EVALUATION TEST')}</div>
                    {passingPercent && <p className={classes.redText}>*{t('Score minimum')} <span className={classes.passPercent}>{passingPercent}% </span>{t('in any one mock test to unlock certificate')}</p>}
                    <TabContainer className="SavedQuiz">
                        <Box className={classes.cardBox}>
                            {quiz?.length ? <div className={classes.mainCertificatebox}>
                                {quiz.length ? (
                                <QuizListSection
                                    hideViewMore={false}
                                    quizSectionList={quiz}
                                    showVertical={true}
                                    handleRefresh={handleRefresh}
                                    onQuizCardClick={onQuizCardClick}
                                />
                                ) : (
                                <NoData message={t("no_data")} />
                                )}
                            </div> : <><div className={classes.noLiveClassText}>{t('No Evaluation Test Found!')}</div></>}
                        </Box>
          </TabContainer>
        </>
      )} */}

      {/* {selectedIndex === 3 && (
        <>
          <div className={classes.mainDiv}>
                        <div className={classes.heading}>{t('CERTIFICATE')}</div>
                        <TabContainer className="SavedQuiz" >
                                <CertificateCard setdownloadCertificateButton={setdownloadCertificateButton} downloadCertificateButton={downloadCertificateButton} sectionData={mySectionData?.data} />
                        </TabContainer>
                    </div>
                </>
      )} */}
        </div>
    );
}

const styles = {
    heading: {
        paddingTop: 30,
        color: '#051733',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 20
    },
    noLiveClassText: {
        paddingTop: 30,
        color: '#051733',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 20,
        textAlign: 'center'
    },
    liveclassbox: {
        border: "1px solid #E7E7E7",
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor:'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#f1f1f1',
          },
    },
    mainDiv: {
        display: 'grid',
    },
    alignHeight: {
        paddingBottom: '10px'
    },
    redText: {
        color: '#F75151',
        fontWeight: '300',
        fontSize: '10px',
        fontStyle: 'italic',
        margin: '0px',
        lineHeight: '15px',
        marginTop: '-15px'
    },
    passPercent: {
        fontSize: '12px',
        fontWeight: 'bold',
    },
    mainCertificatebox: {
        overflow: 'auto'
    },
    completion: {
        display: 'flex',
        color: ' #84818A',
        fontWeight: 500,
        justifyContent: 'center',
        alignItems: 'center'

    },
    certificateTextdiv: {
        display: 'flex',
        gap: '4rem'
    },
    liveText: {
        color: '#84818A',
    },
    line: {
        height: '7vh'
    },
    scrollableContainer: {
        height: '200px',
        overflow: 'auto',
        display: 'flex',
        gap: '10rem',
    },
    divcertificate: {
        display: 'flex',
        flexDirection: 'column',
    },
    timeDiv: {
        color: '#84818A',
    },
    uiImg: {
        height: '10vh'
    },
    Imgcertificate: {
        height: '18rem'
    },
    bestScore: {
        color: '#84818A',
        fontWeight: '300',
    },
    CLPara: {
        fontWeight: '400',
        fontSize: '8px'
    },
    CLtext: {
        color: '#0C3450',
        fontWeight: '700',
        textAlign: 'center'
    },

    ul: {
        listStyleType: "none",
        padding: '0px'
    },

    downloadBtn: {
        width: '140px',
        height: '28px',
        flexShrink: 0,
        borderRadius: '5px',
        border: '1px solid #0C3450',
        color: '#0C3450',
        boxShadow: '2px 4px 8px 0px rgba(157, 172, 254, 0.20)',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    pdfTitle: {
        textTransform: 'capitalize',
        marginTop: 0,
        marginLeft: 14,
    },
    videoTitle: {
        textTransform: 'capitalize',
        marginTop: 0
    },
    subjectDiv: {
        border: '1px solid #E7E7E7',
        backgroundColor: '#fff',
        minHeight: '200px',
        padding: '20px',
        margin: '15px',
        maxWidth: '320px',
        cursor: 'pointer'
    },
    pdfDiv: {
        border: '1px solid #E7E7E7',
        backgroundColor: '#fff',
        minHeight: '154px',
        padding: '15px',
        margin: '15px',
        maxWidth: '320px',
    },
    leftContainer: {
        display: 'flex',
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 24,
    },
    pdfBtn: {
        width: '140px',
        height: '28px',
        flexShrink: 0,
        borderRadius: '5px',
        background: '#0C3450',
        color: '#fff',
        boxShadow: '2px 4px 8px 0px rgba(157, 172, 254, 0.20)',
        display: 'flex',
        alignItems: 'center',
        "&:hover": {
            backgroundColor: '#0C3450',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    icon: {
        fontSize: '35px'
    },
    detailBtn: {
        border: '1px solid grey',
        backgroundColor: '#fff',
        color: '#000',
        borderRadius: '50px',
        padding: '1px 12px',
        fontSize: '14px',
        margin: '10px 5px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#fff",
        },
    },
    contentBtn: {
        border: '1px solid grey',
        backgroundColor: '#5F4BAB',
        color: '#fff',
        borderRadius: '50px',
        padding: '1px 12px',
        fontSize: '14px',
        margin: '10px 5px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#5F4BAB",
        },
    },
    backBtn: {
        padding: '1px 5px',
        fontSize: '15px',
        margin: '10px 2px',
        textTransform: 'capitalize',
        "&:hover": {
            background: "#fff",
        },
    },
    link: {
        color: '#000',
        textDecoration: 'none'
    },
    disableBtn: {
        color: 'black',
        padding: '10px',
    },
    enableBtn: {
        background: 'white',
        color: 'black',
        padding: '10px',
        "&:hover": {
            background: "#E6E6E6",
            color: 'black'
        },
    },
    btnBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        position: 'sticky',
        bottom: '5%'
    },
    btnBoxInside: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        background: 'white',
        boxShadow: '0px 0px 20px 0 rgb(16 36 94 / 30%)',
        borderRadius: '8px'
    },
    crossIcon: {
        color: 'color',
        background: 'white',
        fontWeight: 'bold',
        margin: '5px 0px 10px 0px',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        padding: '5px',
        "&:hover": {
            background: "#5F4BAB",
            color: 'white'
        },
    },
    crossBox: {
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex'
    },
    page: {
        fontSize: '15px',
        margin: '0px 10px'
    },
    Loader: {
        background: 'white',
        padding: '10px 100px',
        borderRadius: '10px'
    },
    cardBox: {
        height: "20vh",
        overflowY: "scroll",
        display: 'grid'
    }
}

export default TopicMaterialContainer;
