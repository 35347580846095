import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Box, Paper } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useDispatch } from "react-redux";
import { getPromotions } from "examsbook-react-sdk/actions";

const BannerCard = () => {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const [promotionData, setPromotionData] = useState([]);

    const getPromotionApiData = async () => {
        try {
            dispatch(getPromotions()).then((res) => {
                setPromotionData(res);
            });
        } catch (error) {
            console.log('Error -', error.message);
        }
    };

    useEffect(() => {
        getPromotionApiData();
    }, []);

    return (
    <>
        {promotionData?.length > 0 && <Grid container className={classes.wrapper}>
            <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={false} showIndicators={promotionData?.length > 1 ? true : false}>
                 {promotionData?.map((promotion, index)=> (
                    <Paper key={index}>
                        <Box>
                            <img alt="banner" className={classes.imageHeight} src={promotion?.imageRawUrl}/>
                        </Box>
                    </Paper>
                ))}
            </Carousel>
        </Grid>}
    </>
    );
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'block',
        '@media (max-width: 959px)': {
           marginBottom: '0px',
           marginTop: '20px'
        },
    },
    imageHeight: {
        maxHeight: '172px'
    }
}));


export default BannerCard;