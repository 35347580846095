import {
    saveFeedbacksForm,
} from "../services/feedbackService";

export const saveFeedbackForm = (data, cb) => (dispatch) => {
    dispatch(request(data));
    return saveFeedbacksForm(data)
        .then((feedbackRes) => {
            // handle success
            dispatch(success(feedbackRes));
            return feedbackRes;
        })
        .catch((error) => {
            // handle error
            dispatch(failure(error.toString()));
        });

    function request(feedbackData) {
        return { type: "FEEDBACK_REQUEST", feedbackData };
    }

    function success(feedbackRes) {
        return { type: "GET_FEEDBACK_SUCCESS", feedbackRes };
    }

    function failure(error) {
        return { type: "GET_FEEDBACK_FAILURE", error };
    }
};
