import axios from "./axiosConfig";

export function getLiveVideoDetails(courseId) {
    return axios
        .get("/api/v1/live-class/" + courseId)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error;
        });
}

export function liveTrackingApi(data) {
    return axios
        .post("/api/v1/userliveTracking", data)
        .then(function (response) {
            // handle success
            let data = response.data;
            return data;
        })
        .catch(function (error) {
            // handle error
            return error;
        });
}