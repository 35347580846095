import React, { useEffect, useState } from 'react';
import LanguageSwitcher from "./LanguageSwitcher";
import UserMenuList from "./UserMenuList";
import { useDispatch, useSelector } from "react-redux";
import UserNotification from './UserNotification';
import { getNotifications } from 'examsbook-react-sdk/actions';
import { Box, List, ListItem } from '@material-ui/core';

/**
 * Notification component
 * @param props
 * @returns {*}
 * @constructor
 */
function NotificationBar(props) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { quizPage } = props;
    const [ length, setLength ] = useState(20);
    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {
        if (user) {
            dispatch(getNotifications(length))
            .then(response => {
                setNotificationList(response);
            })
            .catch(error => {
                console.error('Failed to fetch notifications:', error);
            });
        }
    }, []);

    return (

        <List className="right-menus" id='rightMenusId' >
            {!quizPage && <ListItem id="userNotificationId">
                <UserNotification userNotification={notificationList} setLength={setLength}/>
            </ListItem> }
            {!quizPage && <ListItem id="languageSwitchId">
                <LanguageSwitcher/>
            </ListItem> }
            <ListItem className="login-field" id="loginFieldId">
                <Box className='user-detail-box'>
                <UserMenuList user={user} profile={true} openFeedbackModel={props.openFeedbackModel}/>
                <UserMenuList user={user} profile={false} openFeedbackModel={props.openFeedbackModel}/>
                </Box>
            </ListItem>
        </List>

    );
}

export default NotificationBar;
