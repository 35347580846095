import axios from "./axiosConfig";

export function getSelfLearningApi(courseId) {
    return axios
        .get("/api/v1/getCourseSection/" + courseId)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}

export const progressBarApi = (data) => {
    return axios
        .put(`/api/v1/updateCourseProgress`, data)
        .then(function (response) {
            // handle success
            let data = response.data;
            return data;
        })
        .catch(function (error) {
            // handle error
            return error;
        });
};
