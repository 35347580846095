import React, { useEffect, useState } from "react";
import { MyCoursesCard } from "../MyCourseCard/MyCoursesCard";
import "../CoursesList/coursesList.scss";
import TestSeriesLoader from "../common/loaders/TestSeriesLoader";
import { useHistory } from "react-router-dom";
import { useUserCourse } from "examsbook-react-sdk/custom-hooks";
import {  useDispatch, useSelector } from "react-redux";
import { useSearchQuery } from "../../services/SearchQueryContext";
import NotFound from "../../pages/NotFound";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/close.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { certificateListAction } from "examsbook-react-sdk/actions/certificateAddResponse";
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { Box, Button, Input, makeStyles } from "@material-ui/core";

function stripHtml(html) {
  let tmp = document.createElement("p");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText;
}

function MyCoursesList() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(1);
  const user = useSelector((state) => state.user);
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const t = useTranslations();
  const { isLoading, userCourses } = useUserCourse(user._id);
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const location = useLocation();
  const quizMode = localStorage.getItem('quizMode')
  const slug = localStorage.getItem('slug')
  const [certificateListing, setCertificateListing] = useState([]);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const classes = useStyles(isFirefox);

  const lowerSearchQuery = searchQuery.toLowerCase().trim();
  const filteredUserCourses = userCourses.filter((data, index) => {
    const title = data?.courses[index]?.courseId?.name;
    const description = data?.courses[index]?.courseId?.description;
    const descriptionText = stripHtml(description)?.toLowerCase();
    const lowerTitle = title?.toLowerCase();
    return (
      lowerTitle.includes(lowerSearchQuery) ||
      descriptionText.includes(lowerSearchQuery)
    );
  });
  const coursesToDisplay =
    searchQuery && searchQuery.trim() !== ""
      ? filteredUserCourses
      : userCourses;

  function clearField(searchQuery) {
    if(searchQuery.length)
       setSearchQuery('')
  };

  useEffect(() => {
    setSearchQuery('')
    return setSearchQuery('');
  }, []);

  useEffect(() => {
    getCertificateListingData();
  }, []);


useEffect(() => {
  const timer = setTimeout(() => {
    setShowComponent(true);
  }, 300);
  return () => clearTimeout(timer);
}, []);


useEffect(()=>{
  if(location?.state === undefined || location?.state?.params === `/quiz/${quizMode}/${slug}`) {
    return () => {
      history.replace("/dashboard", {params: window.location.pathname});
    }
  }
},[])

const getCertificateListingData = async () => {
  try {
    dispatch(certificateListAction()).then((res) => {
      if (Array.isArray(res?.data)) {
        setCertificateListing(res?.data);
      } else {
        console.error('No certificates found:', res.data);
      }
    });
  } catch (error) {
    console.error(error.message);
  }
};

  function renderList() {
    return (
      <React.Fragment>
        {selectedTab === 1 && (
          <>
            <Box className="main-div">
              <Box className="course-search-box">
                <Input
                  className="inner-search-box"
                  style={{ border: "none" }}
                  type="text"
                  placeholder={t('Search')}
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <Box className="course-search-img-box">
                {searchQuery.length ? (
                    <Button onClick={()=>clearField(searchQuery)} style={{border:'none'}}>
                      <CrossIcon className="cross-icon" alt="Cross Icon" />
                    </Button>
                  )
                :
                <SearchIcon className="search-icon" alt="Search Icon" />
                }
                </Box>
              </Box>
              <Box className="card-list-div">
                {
                  coursesToDisplay && coursesToDisplay.length > 0 ? (
                    <Box className="card-view-new test-series-new ">
                      {coursesToDisplay.map((data, index) => {
                        return <MyCoursesCard key={index} testData={data} dueDateinDays={data?.dueDateinDays} certificateListing={certificateListing}/>;
                      })}
                    </Box>
                  ) : showComponent && (
                    <>{<Box className={classes.centerAlign}><NotFound /></Box>}</>
                  )
                }
              </Box>
            </Box>
          </>
        )}
      </React.Fragment>
    );
  }

  function renderLoader() {
    //we will show three cards to fill this space.
    return (
      <Box className="card-view test-series">
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
        <TestSeriesLoader />
      </Box>
    );
  }

  return (
    <React.Fragment>
      {!isLoading ? renderList() : coursesToDisplay.length > 0 && renderLoader()}
    </React.Fragment>
  );
}

const useStyles = makeStyles(() => ({
  centerAlign: isFirefox => ({
    textAlign: isFirefox ? '-moz-center' : '-webkit-center',
  }),
}));
export default MyCoursesList;
