import status from "../constants/actionStatus";

export default (state = {
    status: status.PENDING,
    data: {}
}, action = {}) => {
    switch (action.type) {
    case 'GET_QUIZ_RESULT_START':
        return {
            status: 'PENDING',
            data: action.payload
        };
    case 'GET_QUIZ_RESULT_SUCCESS':
        return {
            status: 'SUCCESS',
            data: action.payload
        };
    case 'GET_QUIZ_RESULT_FAIL':
        return {
            status: 'ERROR',
            data: action.payload
        };
    default:
        return state;
    }
}
