import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl } from '@material-ui/core';
import Select from 'react-select';
import { useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import './reports.scss';

function SectionCard(props) {
    const {courseResultData, setActiveAttempts, setActiveKey} = props
    const t = useTranslations();
    const { language } = useUserLanguage();
    
    // Prepare options for the Select component
    const options = courseResultData?.map((item, index) => ({
        value: item,
        label: `Attempt ${index+1}`
    }));

    const [selectedAttempt, setSelectedAttempt] = useState(options[0]);

    useEffect(() => {
        handleAttemptChange(selectedAttempt);
    },[])

    const handleAttemptChange = (selectedOption) => {
        const index = options.indexOf(selectedOption);
        setActiveKey(index)
        setActiveAttempts(selectedOption?.value)
        setSelectedAttempt(selectedOption);
    };

    return (
        <Box className="attempt-card-box graph-box-container">
            <TableContainer component={Paper} className='table-container'>
                <div className="dropdown-container">
                    <h2>{t('Section_wise_score')}</h2>
                    <FormControl className='dropdown-data'>
                    <Select 
                        id="department"
                        value={selectedAttempt}
                        onChange={handleAttemptChange}
                        options={options}
                        styles={{
                            option: (provided) => ({
                            ...provided,
                            fontWeight: '400'
                            }),
                            menuPortal: base => ({ ...base, zIndex: 9999 })
                        }}
                        menuPortalTarget={document.body}
                    />
                    </FormControl>
                </div>
                <Table className='table-data'>
                    <TableHead>
                        <TableRow>
                            <TableCell className="column-header">{t('Section')}</TableCell>
                            <TableCell className="column-header">{t('score')}</TableCell>
                            <TableCell className="column-header">{t('total_marks')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedAttempt?.value?.length> 0 &&  selectedAttempt?.value?.map((item, index) => (
                            <TableRow key={index} className="body-row">
                                <TableCell>{language === "en" ? item?.section?.en : item?.section?.hn}</TableCell>
                                <TableCell>{item?.score}</TableCell>
                                <TableCell>{item?.total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default SectionCard;
