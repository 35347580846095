import status from "../constants/actionStatus";

export default (state = {
    questions: [],
    totalQuestions: 0,
    id: '',
    status: status.PENDING
}, action = {}) => {
    switch (action.type) {
    case 'GET_QUIZ_SUCCESS':
        return action.payload;
    case 'UNSAVE_QUESTION_SUCCESS': {
        try {
            let updatedQuestionId = action.payload.questionId;
            let quizData = { ...state };

            quizData.questions = quizData.questions.map((quizSectionData) => {
                quizSectionData.questions = quizSectionData.questions.map((quizQuestion) => {
                    quizQuestion = { ...quizQuestion };// copy
                    if (quizQuestion.id._id !== updatedQuestionId) {
                        return quizQuestion;
                    } else {
                        //toggle status.
                        quizQuestion.isSaved = !quizQuestion.isSaved;
                        return quizQuestion;
                    }
                });
                return quizSectionData;
            });
            return quizData;

        } catch (error) {
            console.log('error-------', error);
            return state;
        }
    }
    case 'GET_QUIZ_ERROR':
        return action.payload;
    case 'GET_QUIZ_REQUEST':
        return action.payload;
    case 'QUIZ_UPDATED':
        return action.payload;
    case 'QUESTION_ANSWERED':
        return JSON.parse(JSON.stringify(action.payload));
    default:
        return state;
    }
}
