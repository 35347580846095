import React, { createContext, useContext, useEffect, useState } from "react";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";

const ThemeContext = createContext();
function ThemeProvider({ children }) {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');

  const [primaryFontText, setPrimaryFontText] = useState('');
  const [secondaryFontText, setSecondaryFontText] = useState('');

  const dispatch = useDispatch()
  const [primaryBg, setPrimaryBg] = useState();
  const [secondaryBg, setSecondaryBg] = useState();
  const [primaryFontColor, setPrimaryFontColor] = useState();
  const [secondaryFontColor, setSecondaryFontColor] = useState();
  

  const getSettingApiData = async () => {
    try {
      const res = await dispatch(getSupportAction());
      const defaultValueRes = await dispatch(getDefaultSupportDataAction());      
      setPrimaryBg(res?.data?.find(subValue => subValue.key === "Primary Bg")?.value || defaultValueRes?.data?.['Background Theme']?.find(subValue => subValue.key === "Primary Bg")?.defaultValue)
      setSecondaryBg(res?.data?.find(subValue => subValue.key === "Secondary Bg")?.value || defaultValueRes?.data?.['Background Theme']?.find(subValue => subValue.key === "Secondary Bg")?.defaultValue)
      setPrimaryFontColor(res?.data?.find(subValue => subValue.key === "Primary Text")?.value || defaultValueRes?.data?.['Text Theme']?.find(subValue => subValue.key === "Primary Text")?.defaultValue)
      setSecondaryFontColor(res?.data?.find(subValue => subValue.key === "Secondary Text")?.value || defaultValueRes?.data?.['Text Theme']?.find(subValue => subValue.key === "Secondary Text")?.defaultValue)
    } catch (error) {
      console.log('Error - ', error.message);
    }
  };

  useEffect(() => {
    getSettingApiData();
  }, []);

  useEffect(() => {
    if(primaryBg && secondaryBg && primaryFontColor && secondaryFontColor){  
      const dynamicBgColorPrimary =  primaryBg;
      const dynamicBgColorSecondary = secondaryBg;
     
      const dynamicFontColorPrimary = primaryFontColor;
      const dynamicFontColorSecondary = secondaryFontColor;
  
      setPrimaryColor(dynamicBgColorPrimary);
      setSecondaryColor(dynamicBgColorSecondary);
  
      setPrimaryFontText(dynamicFontColorPrimary);
      setSecondaryFontText(dynamicFontColorSecondary);
    }
    let theme = {
      primaryBg: primaryBg, 
      secondaryBg: secondaryBg, 
      primaryFontColor: primaryFontColor,
      secondaryFontColor: secondaryFontColor
    }
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [primaryBg, secondaryBg, primaryFontColor, secondaryFontColor])

    // Don't render the children if the theme data is not available
  if (!primaryColor || !secondaryColor || !primaryFontText || !secondaryFontText) {
    return null;
  }

  return (
    <ThemeContext.Provider value={{ primaryColor, secondaryColor, primaryFontText, secondaryFontText }}>
      {children}
    </ThemeContext.Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;
