import status from "../constants/actionStatus";

export default (
    state = {
        courseDetail: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "COURSE_REQUEST":
        return {
            status: status.PENDING,
            courseDetail: {},
        };
    case "COURSE_SUCCESS":
        return {
            status: status.SUCCESS,
            courseDetail: action.payload,
        };

    case "COURSE_FAILURE":
        return {
            status: status.ERROR,
            courseDetail: {},
        };
    default:
        return state;
    }
};
