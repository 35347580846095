
import React, { useEffect, useState } from 'react';
import UserProfile from "../components/Profile/UserProfile";
import { useAnalyticsPageView, useTranslations } from "examsbook-react-sdk/custom-hooks";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";

/**
 * Setting component. right now it has only user profile section and selected exams of a user.
 * @param props
 * @returns {*}
 * @constructor
 */
function Settings() {
    const t = useTranslations();
    const history = useHistory();
    useAnalyticsPageView();
    const [isMobile, setIsMobile] = useState(false);


    function goToDashboard() {
        history.push('/dashboard');
    }
    useEffect(() => {
        function handleResize() {
          setIsMobile(window.innerWidth <= 768)
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
      }, [])

    return (
        <>
        {isMobile ? null : (
            <>
            <div className="mobile-back-to-test">
                <ArrowBackIcon onClick={goToDashboard}/>
                <span>{t('my_profile')}</span>
            </div>
            </>
        )}
            <div className="main-div" style={{minHeight:'auto'}}>  
                <UserProfile/>
            </div>
        </>
    );
}

export default Settings;
