import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationBar from "./NotificationBar";
import { useSelector } from "react-redux";
import LanguageSwitcher from "./LanguageSwitcher";
import { Modal } from "@material-ui/core";
import {
  useStyles as useStylesForSdk,
  useTranslations,
} from "examsbook-react-sdk/custom-hooks";
import NotificationScreen from "../../PaymentSection/NotificationScreen";
import COLORS from "examsbook-react-sdk/constants/colors";
import "./Header.scss";
import { useSearchQuery } from "../../../services/SearchQueryContext";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MobileViewSideBar from "./MobileViewSideBar";
import { ReactComponent as CrossIcon } from "../../../assets/images/close.svg";
import { useTheme } from "../../../constants/themeProvider";
import Tooltip from "@material-ui/core/Tooltip";
import BrandLogo from "../../../assets/images/LearnifyLogoLight.png";
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    appBar: {
      width: "100%",
    },
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
const drawerWidth = 300;
function CHeaderBarContent(props) {
  const {
    open,
    openFeedbackModel,
  } = props;
  const t = useTranslations();
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const [isMobile, setIsMobile] = useState(false);
  const [openToggler, setOpenToggler] = useState(false);
  let imageUrl;
  if (user && user.image) {
    imageUrl = user?.image?.largeImage?.webP;
  }

  const toggleDrawer = (isOpen) => {
    setOpenToggler(isOpen);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 959);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const history = useHistory();
  const courseId = localStorage.getItem("courseId");
  const courseName = localStorage.getItem("courseName");


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    history.push("/my-profile");
  };

  const handleClose = () => {
    setOpenModal(false);
    setRefresh(!refresh);
  };

  const goToCourse = () => {
    history.push('/course-material/content', {courseId:courseId, subjectId: '', topicId:'', subjectDetail:'', subjectName:'', topicName:''});
  }
  const classesList = useStylesForSdk(styles)();

  const [currentTime, setCurrentTime] = useState(new Date());
  const hour = currentTime.getHours();

  let greeting = `${t("Good Morning")}`;
  if (hour >= 12 && hour < 16) {
    greeting = `${t("Good Afternoon")}`;
  } else if (hour >= 16) greeting = `${t("Good Evening")}`;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function clearField(searchQuery) {
    if (searchQuery.length) setSearchQuery("");
  }

  return (
    <Toolbar
      disableGutters={!open}
      className="main-header-section"
      id="mainHeaderSectionId"
      style={{display: window.location.pathname == '/course-material/content' ? 'none' : 'flex'}}
    >
      {isMobile ? (
        <>
        <AppBar style={{ width: "100%", background: theme?.primaryColor }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </div>

              <Box className="text-box-mobile">
                    <Typography
                        className="greeting-text-mobile"
                    >
                        {greeting}, <Tooltip title={user.name} aria-label="add"><Typography variant="span">{user.name}</Typography></Tooltip>
                </Typography>
                </Box>
              <Box component="img" src={BrandLogo} className="brand-header-logo" />
              <Box className="mobile-notification-screen">
                <IconButton onClick={() => history.push("/notifications")}>
                  <NotificationsActiveOutlinedIcon />
                </IconButton>
              </Box>
              <div style={{ display: isMobile ? "block" : "none" }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <img
                    src={imageUrl}
                    alt="user image"
                    className="my-image"
                    style={{borderRadius:'180px', height:'50px', width:'50px'}}
                     />
                    <div id="contentBlockId" style={{paddingLeft: '10px', flex: 'none', display: isMobile ? "none" : "block"}}>
                      <p id="contentId">{user.name}</p>
                    </div>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <MobileViewSideBar
            openToggler={openToggler}
            toggleDrawer={toggleDrawer}
            openFeedbackModel={openFeedbackModel}
            classes={classes}
          />
        </>
      ) : (
        <>
        <Box className="text-box" style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
          {window.location.pathname === "/reports" ? (
            <Typography className="greeting-text">
              <Box className="reports-navigation">
                <Typography className="reportsTitle" onClick={goToCourse}>{courseName}</Typography> / Reports
              </Box>
            </Typography>
          ) : window.location.pathname === "/courses" ? (
            <Typography className="greeting-text">
              {t("All Courses")}
            </Typography>
          ) : window.location.pathname === "/my-courses" ? (
            <Typography className="greeting-text">
              {t("My Courses")}
            </Typography>
          ) : window.location.pathname === "/surveys" ? (
            <Typography className="greeting-text">
              {t("Surveys")}
            </Typography>
          ) : window.location.pathname === "/support" ? (
            <Typography className="greeting-text">
              {t("New Support Ticket")}
            </Typography>
            ) : window.location.pathname === "/support-ticket" ? (
              <Typography className="greeting-text">
                {t("Support Ticket")}
              </Typography>
            ) : window.location.pathname === "/certificate-list" ? (
            <Typography className="greeting-text">
              {t('My Certificates')}
            </Typography>
          ) : (
            <Typography className="greeting-text">
              {greeting}, <Tooltip title={user.name} aria-label="add"><Typography variant="span">{user.name}</Typography></Tooltip>
            </Typography>
          )}

          {(window.location.pathname === "/courses" || window.location.pathname === "/surveys" || window.location.pathname === "/support-ticket" || window.location.pathname === "/my-courses" || window.location.pathname === "/certificate-list") && (
              <div className="outer-search-box">
                <input
                  className="search-box"
                  style={{ border: "none" }}
                  type="text"
                  placeholder={t('Search')}
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <div className="search-img-box">
                  {searchQuery.length ? (
                    <button
                      onClick={() => clearField(searchQuery)}
                      style={{ border: "none" }}
                    >
                      <CrossIcon className="cross-icon" alt="Cross Icon" />
                    </button>
                  ) : (
                    <SearchIcon className="search-icon" alt="Search Icon" />
                  )}
                </div>
              </div>
          )}
        </Box>
          <Box className="notification-bar">
            <NotificationBar openFeedbackModel={openFeedbackModel} />
            <div className="mobile-language-header-new">
              <ul className="right-menus-new">
                <li className="">
                  <LanguageSwitcher />
                </li>
              </ul>
            </div>
          </Box>
        </>
      )}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descpoppinsription"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        className={classesList.modal}
      >
        <div className={classesList.box}>
          <div className={classesList.inside}>
            <NotificationScreen
              refresh={refresh}
              setRefresh={() => setRefresh(!refresh)}
            />
          </div>
        </div>
      </Modal>
    </Toolbar>
  );
}

const styles = (theme) => ({
  box: {
    backgroundColor: "white",
    position: "absolute",
    top: "65px",
    right: "70px",
    boxShadow: theme.shadows[4],
  },
  inside: {
    overflowY: "scroll",
    maxHeight: "570px",
    width: "460px",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  span: {
    backgroundColor: COLORS.THEME_COLOR,
    fontSize: "12px",
    color: "white",
    position: "absolute",
    bottom: "35px",
    fontWeight: "bold",
    padding: "3px 4px",
    borderRadius: "50%",
    marginLeft: "-4px",
  },
  bellBox: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    minWidth: "40px",
    height: "40px",
    cursor: "pointer",
    "@media (max-width:767px)": {
      display: "none",
    },
  },
});

export default CHeaderBarContent;
