import { createSelector } from 'reselect';
import getQuestionLocationIndex from "../common/getQuestionLocationIndex";

const getQuiz = state => state.quiz;
const getActiveQuestionIndex = state => state.activeQuestionIndex;

export const getQuestionLocation = createSelector(
    [ getQuiz, getActiveQuestionIndex ],
    getQuestionLocationIndex
);
