import status from "../constants/actionStatus";

export default (
    state = {
        courseMeta: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "ADD_MY_COURSE_REQUEST":
        return {
            status: status.PENDING,
            courseMeta: {},
        };
    case "ADD_MY_COURSE_SUCCESS":
        return {
            status: status.SUCCESS,
            course: action.payload,
        };

    case "ADD_MY_COURSE_FAILURE":
        return {
            status: status.ERROR,
            courseMeta: {},
        };
    default:
        return state;
    }
};
