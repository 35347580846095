import { getQuestionLocationIndex } from "examsbook-react-sdk/common";
import { setActiveQuestionIndex } from "./setActiveQuestionIndex";

export const toggleMarkForReview = () => {
    return (dispatch, getState) => {
        const { quiz, isViewMode, activeQuestionIndex, selectedQuestion } = getState();
        if (!isViewMode) {
            //mark selected question visited.
            let questions = quiz.questions;
            const { sectionNo, questionNo } = getQuestionLocationIndex(quiz, activeQuestionIndex);
            questions[sectionNo].questions[questionNo].marked = !questions[sectionNo].questions[questionNo].marked;
            questions[sectionNo].questions[questionNo].timeSpent = selectedQuestion.timeSpent;
            quiz.questions = questions;
            //dispatch
            dispatch({
                type: 'QUESTION_ANSWERED',
                payload: quiz
            });
            return dispatch(setActiveQuestionIndex(activeQuestionIndex));
        } else {
            return Promise.resolve();
        }
    };
};
