import status from "../constants/actionStatus";

export default (
    state = {
        courses: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "USER_COURSE_REQUEST":
        return {
            status: status.PENDING,
            courses: {},
        };
    case "USER_COURSE_SUCCESS":
        return {
            status: status.SUCCESS,
            courses: action.payload,
        };

    case "USER_COURSE_FAILURE":
        return {
            status: status.ERROR,
            courses: {},
        };
    default:
        return state;
    }
};
