//add jquery
import $ from 'jquery';

/**
 * math editor
 * @param event
 */
var editor = function (event) {
    const jsDemoImagesTransform = document.createElement('script');
    jsDemoImagesTransform.type = 'text/javascript';
    jsDemoImagesTransform.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    document.head.appendChild(jsDemoImagesTransform);
    // This needs to be included before the '@wiris/mathtype-tinymce6' is loaded synchronously
    window.$ = $;
    window.tinymce = require('tinymce');
    require('@wiris/mathtype-tinymce6');
    const content = (event);
    // Init the editor and define its options
    const options = {
        height: 10,
        menubar: false,
        external_plugins: {
            'tiny_mce_wiris': `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
        },
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount '
        ],
        toolbar: [
            ' bold italic |' +
            'tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry '
        ],
        htmlAllowedTags: [ '.*' ],
        htmlAllowedAttrs: [ '.*' ],
        extended_valid_elements: '*[.*]',
    };
};

export default editor;
