import React, { memo } from "react";
import useStyles from "./styles";
import ContinueVideoDialog from "./ContinueVideoDialog";
import { Box, Typography } from "@material-ui/core";
import { VideoPlayer } from "../../../elements";

function VideoSelfLearning(props) {
  const { 
    playerRef,
    currentVideo,
    videoPlayback,
    setVideoPlayback,
    handleVideoPlayback,
    handleVideoOnReady,
    showPopup,
    closePopup,
    handleResume,
    handleStartFromBeginning,
    callProgressApi,
    defaultVideoPlayerSettings,
    dynamicVideoPlayerSettings,
    isVideoLoaded,
  } = props;

  const classes = useStyles();
  // const dynamicVideoControl = (typeof dynamicVideoPlayerSettings != "undefined" || typeof defaultVideoPlayerSettings != "undefined") && dynamicVideoPlayerSettings !== "false" ? JSON.parse(dynamicVideoPlayerSettings) : JSON.parse(defaultVideoPlayerSettings);
  const dynamicVideoControl = typeof dynamicVideoPlayerSettings !== "undefined" || typeof defaultVideoPlayerSettings !== "undefined" && dynamicVideoPlayerSettings !== "false" ? JSON.parse(dynamicVideoPlayerSettings) : typeof defaultVideoPlayerSettings !== "undefined" ? JSON.parse(defaultVideoPlayerSettings) : false;

    return (
    <Box className={classes.videoWrapper}>
            {/* Video Description */}
            <Box style={{width: '90%'}}>
        <Box className={classes.titleBox}>
          <Typography variant="h5" className={classes.videoTitle}>
            {currentVideo?.contentData?.title}
          </Typography>
        </Box>
        <Box className={classes.descriptionBox}>
          <Typography className={classes.videoDescription} component="p"  dangerouslySetInnerHTML={{ __html: currentVideo?.contentData?.description }}>
          </Typography>
        </Box>
      </Box>

      {/* Video Player */}
      {currentVideo && <VideoPlayer
        type={currentVideo.type}
        url={currentVideo.videoUrl}
        controls={dynamicVideoControl}
        playing={videoPlayback}
        onReady={handleVideoOnReady}
        isVideoLoaded={isVideoLoaded}
        videoPlayerRef={playerRef}
        handlePlaying={handleVideoPlayback}
        onPause={() => {
          setVideoPlayback(false); 
          callProgressApi({})}}
        onEnded={() => callProgressApi({})}
      />}

      {/* Video Popup */}
      {isVideoLoaded && showPopup && (
        <ContinueVideoDialog
          showModal={showPopup}
          closeModal={closePopup}
          handleYes={handleResume}
          handleNo={handleStartFromBeginning}
        />
      )}
    </Box>
  );
}
export default memo(VideoSelfLearning);
