import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import courseAssignedImg from "../../assets/images/courseAssigned.png"
import courseExpiringImg from "../../assets/images/courseExpiring.png"
import courseCompletedImg from "../../assets/images/courseCompleted.png"
import courseAssignedGif from "../../assets/images/courseAssigned.gif"
import courseExpiringGif from "../../assets/images/courseExpiring.gif"
import courseCompletedGif from "../../assets/images/courseCompleted.gif"

import { useDispatch, useSelector } from "react-redux";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../constants/themeProvider";
import { getUserCourse } from "examsbook-react-sdk/actions";

function InfoCard({ setCourseData, setCategorySelect }) {
  const theme = useTheme();
  const [courseCount, setCourseCount] = useState("");
  const [count, setCount] = useState(0);
  const [courseCompletedCount, setCourseCompletedCount] = useState(0);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState({
    assigned: false,
    expiring: false,
    completed: false,
  });

  const handleMouseEnter = (category) => {
    setIsHovered(prevState => ({ ...prevState, [category]: true }));
  };

  const handleMouseLeave = (category) => {
    setIsHovered(prevState => ({ ...prevState, [category]: false }));
  };
  
  const [selectedCategory, setSelectedCategory] = useState('assigned');
  const t = useTranslations();
  const user = useSelector((state) => state.user);

  const handleCourseExpiryDate = (courses = []) => {
    const currentDate = Date.now();
    const { expiringSoonCount, courseCompleted } = courses.reduce((acc, { endDate, progressPercent }) => {
        if (!endDate) return acc;
        const endDateTimestamp = typeof endDate === "number" ? endDate : Date.parse(endDate);
        if (isNaN(endDateTimestamp)) return acc;
        const differenceInDays = Math.ceil((endDateTimestamp - currentDate) / (1000 * 60 * 60 * 24));
        if (currentDate > endDateTimestamp || progressPercent === 100) {
          acc.courseCompleted += 1;
        } else if (differenceInDays <= 7) {
          acc.expiringSoonCount += 1;
        }
        return acc;
      },
      { expiringSoonCount: 0, courseCompleted: 0 }
    );
    setCount(expiringSoonCount);
    setCourseCompletedCount(courseCompleted);
  };

  const getUserCourseApiData = async (userId) => {
    try {
      dispatch(getUserCourse(userId))
      .then((res) => {
        setCourseCount(res?.data[0]?.courses);
        handleCourseExpiryDate(res?.data[0]?.courses);
        setCourseData(res?.data[0]?.courses);
      });
    } catch (error) {
      console.log('Error', error.message);
    }
  };

  useEffect(() => {
    if (user?._id) {
      getUserCourseApiData(user?._id);
    }
  }, [user._id]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCategorySelect(category);
  };


  const styles = {
    title: {
      color: "#0C3450",
      fontSize: "34px",
      fontWeight: 400,
      lineHeight: '36px',
      margin: '0px 4px',
      '@media (max-width:900px)': {
        fontSize: "30px",
      },
      '@media (max-width:1441px)': {
        fontSize: '1.8rem'
      },
      '@media (max-width:767px)': {
        fontSize: "24px",
        margin: 0,
        textAlign: 'center',
        paddingBottom: 6
      },
    },
    subtitle: {
      color: `${theme?.primaryColor}`,
      fontSize: "14px",
      fontWeight: 600,
      margin: '0px 4px',
      lineHeight: 'normal',
      '@media (max-width:1441px)': {
        fontSize: '1rem',
        lineHeight: '18px'
      },
      '@media (max-width:555px)': {
        minHeight: "40px",
        fontSize: '14px',
        padding: '0px 4%'
      },
      '@media (min-width:960px) and (max-width:1200px)': {
        fontSize: '1em',
      },
      '@media (max-width:959px)': {
        fontSize: '1.2em',
      },
      '@media (min-width:550px) and (max-width:600px)': {
        padding: '0px 16px'
      },
      '@media (max-width: 767px)': {
        display: 'none'
      }
    },

    mainDiv: {
      paddingBottom: '16px',
      "&.MuiGrid-spacing-xs-5": {
        width: "calc(100% + 10px)",
      },
      '@media (max-width:960px)': {
        paddingTop: "20px",
      },
      '@media (max-width: 767px)': {
        justifyContent: 'space-between',
        '& > .MuiGrid-item': {
          maxWidth: '32%',
          flexBasis: '32%'
        }
      }
    },
    fragment: {
      '@media (min-width:1200px)': {
        marginLeft: "21px !important",
      },
      '@media (min-width:900px) and (max-width:1200px)': {
        marginLeft: "19px !important",
      },
      '@media (max-width:900px)': {
        textAlign: "center",
        paddingTop: "0px !important",
      },
      '@media (max-width:700px)': {
        padding: "0px 10px 15px !important",
      },
    },
    imgBlock: {
      '@media (min-width:1920px)': {
        textAlign: "center",
      },
      '@media (max-width:900px)': {
        textAlign: "center",
        paddingBottom: "4px !important",
      },
      '@media (max-width:700px)': {
        paddingTop: "15px !important",
        paddingBottom: "0px !important",
      },
      "& svg": {
        '@media (min-width:900px) and (max-width:1200px)': {
          width: "65px",
          height: "65px",
        },
        '@media (max-width:700px)': {
          width: "30px",
          height: "30px",
        },
      },
    },
    imgAlign: {
      height: '48px',
      width: '48px',
      padding: '4px',
      borderRadius: '6px',
      background: 'aliceblue',
      '@media (max-width: 767px)': {
        background: 'none',
        padding: 0,
        height: 'auto',
        width: '40px'
      }
    },
    iconDiv: {
      display: 'flex',
      justifyContent: 'center'
    },
    selectedCourseBox: {
      border: `1px solid ${theme?.secondaryColor}`,
      boxShadow: '1px 1px 8px 1px #0C345033',
    },
    courseNameStatus: { 
      display: 'flex', 
      alignItems: 'center',
      gap: '6px',
      '@media (max-width: 767px)': {
        gap: 0
      }
    },
    courseCountHead: {
      fontSize: '12px',
      fontWeight: '600',
      '@media (max-width: 767px)': {
        display: 'none'
      }
    },
    mobileCourseCountHead: {
      display: 'none',
      '@media (max-width: 767px)': {
        display: 'block'
      }
    }
  }

  const classes = useStyles(styles)();

  return (
    <Grid container spacing={2} className={classes.mainDiv}>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('assigned')}>
        <Grid container className={classes.wrapper}>
          <Grid className={`course-count-main-box ${selectedCategory === 'assigned' ? classes.selectedCourseBox : ''}`}
            onMouseEnter={() => handleMouseEnter('assigned')}
            onMouseLeave={() => handleMouseLeave('assigned')}
            style={{ flexDirection: 'column', color: `${theme?.primaryColor}` }}

          >
            <Grid className={classes.courseNameStatus}>
              <Grid>
                <img className={classes.imgAlign} src={isHovered.assigned ? courseAssignedGif : courseAssignedImg} />
              </Grid>
              <Grid>
                <p className={classes.subtitle}>{t('Assigned')}</p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid>
                <Typography className={classes.title} style={{ color: theme?.secondaryColor }}>
                  {courseCount?.length !== undefined ? courseCount?.length : 0}
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.courseCountHead}> {t('Courses')}</Typography>
                <Typography className={`${classes.courseCountHead} ${classes.mobileCourseCountHead}`}> {t('Assigned')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('expiring')}>
        <Grid container className={classes.wrapper}>
          <Grid className={`course-count-main-box ${selectedCategory === 'expiring' ? classes.selectedCourseBox : ''}`}
            onMouseEnter={() => handleMouseEnter('expiring')}
            onMouseLeave={() => handleMouseLeave('expiring')}
            style={{ flexDirection: 'column' }}

          >
            <Grid className={classes.courseNameStatus}>
              <Grid>
                <img className={classes.imgAlign} src={isHovered.expiring ? courseExpiringGif : courseExpiringImg} />
              </Grid>
              <Grid>
                <p className={classes.subtitle}>{t('Expiring')}</p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid>
                <Typography className={classes.title} style={{ color: theme?.secondaryColor }}>
                  {count}
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.courseCountHead}> {t('Courses')}</Typography>
                <Typography className={`${classes.courseCountHead} ${classes.mobileCourseCountHead}`}> {t('Expiring')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.dataGrid} onClick={() => handleCategoryClick('completed')}>
        <Grid container className={classes.wrapper}>
          <Grid className={`course-count-main-box course-completed-align ${selectedCategory === 'completed' ? classes.selectedCourseBox : ''}`}
            onMouseEnter={() => handleMouseEnter('completed')}
            onMouseLeave={() => handleMouseLeave('completed')}
            style={{ flexDirection: 'column' }}

          >
            <Grid className={classes.courseNameStatus}>
              <Grid>
                <img className={classes.imgAlign} src={isHovered.completed ? courseCompletedGif : courseCompletedImg}/>

              </Grid>
              <Grid>
                <p className={classes.subtitle}>{t('Completed')}</p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid>
                <Typography className={classes.title} style={{ color: theme?.secondaryColor}}>
                  {courseCompletedCount}
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.courseCountHead}> {t('Courses')}</Typography>
                <Typography className={`${classes.courseCountHead} ${classes.mobileCourseCountHead}`}> {t('Completed')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoCard;
