import status from "../constants/actionStatus";

export default (
    state = {
        addCourse: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "ADD_TO_COURSE_REQUEST":
        return {
            status: status.PENDING,
            addCourse: {},
        };
    case "ADD_TO_COURSE_SUCCESS":
        return {
            status: status.SUCCESS,
            addCourse: action.payload,
        };
    case "ADD_TO_COURSE_FAILURE":
        return {
            status: status.ERROR,
            addCourse: {},
        };
    default:
        return state;
    }
};
