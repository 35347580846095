import status from "../constants/actionStatus";

export default (
    state = {
        practiceQuiz: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "PRACTICE_QUIZ_REQUEST":
        return {
            status: status.PENDING,
            practiceQuiz: {},
        };
    case "PRACTICE_QUIZ_SUCCESS":
        return {
            status: status.SUCCESS,
            practiceQuiz: action.payload,
        };

    case "PRACTICE_QUIZ_FAILURE":
        return {
            status: status.ERROR,
            practiceQuiz: {},
        };
    default:
        return state;
    }
};
