import axios from './axiosConfig';

export function getSurveyApi() {
    return axios
        .get("api/v1/coreGetSurvey")
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}
