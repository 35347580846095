import React from "react";
import { CHeader } from "./c/CHeader";
import CDrawer from "./CDrawer";
import FeedbackModel from "../../Feedback/FeedbackModel";
import { saveFeedbackForm } from "examsbook-react-sdk/actions/saveFeedback";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core";
import { useSearchQuery } from "../../../services/SearchQueryContext";
import { ColorsConstant } from "../../../constants/colorConstant";

const drawerWidth = 240;

/**
 * Main Header component.
 */

function SideBar(props) {
  const { isFeedbackModelOpen, setIsFeedbackModelOpen } = useSearchQuery();
  const { classes, openSidebar, handleDrawerClose, handleDrawerOpen } = props;
  const dispatch = useDispatch();

  function openFeedbackModel() {
    setIsFeedbackModelOpen(true);
  }

  function closeFeedBackModel() {
    setIsFeedbackModelOpen(false);
  }

  function submitFeedbackForm(message) {
    dispatch(saveFeedbackForm({ message: message }));
    closeFeedBackModel();
  }

  return (
    <React.Fragment>
        <CHeader classes={classes}>
        <>
            <CDrawer
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              open={openSidebar}
              classes={classes}
              openFeedbackModel={openFeedbackModel}
            />
          <FeedbackModel
            isFeedbackModelOpen={isFeedbackModelOpen}
            closeFeedBackModel={closeFeedBackModel}
            submitForm={submitFeedbackForm}
          />
        </>
        </CHeader>
    </React.Fragment>
  );
}

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    position: "absolute",
    width: theme.spacing(1) * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "16px 32px",
    outline: "none",
  },
  appBar: {
    backgroundColor: "#F2F1F4",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `100%`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: ColorsConstant.defaultBlue,
    position: "fixed",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "@media (max-width: 767px)": {
      width: "80%",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(1) * 7,
    '& .nav-menus': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "@media (max-width: 959px)": {
      width: "0",
      zIndex: "1202",
      display: 'none'
    },
    [theme.breakpoints.up("lg")]: {
      width: "50px",
      zIndex: "1200",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0",
    minHeight: 66,
    position: "relative",
    justifyContent: "flex-end",
    top: 66,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      top: 0,
      minHeight: 54,
    },
  },
  content: {},
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    padding: 0,
  },
  menuButtonHidden: {
    display: "none",
  },
  HeaderMenuButton: {
    backgroundColor: "transparent !important",
    float: "left",
    color: ColorsConstant.white,
    "@media (max-width: 1024px)": {
      marginTop: "10px",
    },
    "@media (max-width: 767px)": {
      color: "#2962ff",
      "& svg": {
        fontSize: 30,
        color: "#fff",
      },
    },
  },
});

export default withStyles(styles, { withTheme: true })(SideBar);
