import { useState } from "react";
import { timeRemainingFromNow } from "examsbook-react-sdk/common";
import useInterval from "./useInterval";

/**
 * Not sure how much correct it is as it was taken directly from component.
 * @param testData
 * @returns {any}
 */
export default function useTimeRemainingQuiz(testData) {
    const [ timeRemaining, setTimeRemaining ] = useState(getRemainingTime());
    useInterval(countDown, 1000);

    function getRemainingTime() {
        if (testData && testData.startDate > new Date().getTime()) {
            return timeRemainingFromNow(testData.startDate);
        } else if (testData && testData.resultDate && testData.resultDate > new Date().getTime()) {
            return timeRemainingFromNow(testData.resultDate);
        } else {
            return 0;
        }
    }

    function countDown() {
        setTimeRemaining(getRemainingTime());
    }

    return timeRemaining;
}
