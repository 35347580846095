import React from "react";
import webNotFoundImage from "../assets/images/web-not-found.png";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { Box } from "@material-ui/core";
import CommonButton from "../constants/CommonButton";
import { useHistory } from 'react-router-dom';

function WebNotFound() {
    const t = useTranslations();
    const classes = useStyles(styles)();
    const history = useHistory();

    const handleLogout = () => {
        localStorage.clear();
        history.replace('/login');
    };

    return (
        <>
            <Box sx={{float: 'right'}}>
                <Box><CommonButton onClick={handleLogout} title={t('Logout')} classStyleHeading={classes.logoutButton} classStyleText={classes.logoutButtonText}/></Box>
            </Box>
            <Box className={classes.container}>
                <Box className={classes.textAlign}>
                    <h2 className={classes.headingText}>
                        {t('Only available on mobile')}
                    </h2>
                    <p className={classes.descriptionText}>
                        {t('The web version is currently unavailable.')}
                    </p>
                    <p className={classes.descriptionText}>
                        {t('Kindly Contact with Administrator to get the mobile Application.')}
                    </p>
                </Box>
                <Box className={classes.ImageContainer}>
                    <img src={webNotFoundImage} className={classes.Img} alt="Not Found" />
                </Box>
            </Box>
        </>
    );
}

const styles = {
    container: {
        display: 'flex',
        position: 'relative',
        top: '20vh',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
        }
    },
    headingText: {
        fontSize: '3rem',
        color: '#404041',
        fontWeight: '600',
        margin: '0px',
        paddingBottom: '16px',
        textAlign: 'left',
        lineHeight: '50px',
        width: '40%',
        "@media (max-width: 768px)": {
            textAlign: 'center',
            width: '90%'
        }
    },
    descriptionText: {
        color: '#868686',
        fontSize: '1rem',
        margin: '0px',
        textAlign: 'left',
        width: '40%',
        "@media (max-width: 768px)": {
            textAlign: 'center',
            width: '100%'
        }
    },
    ImageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Img: {
        width: '100%',
        height: 'auto'
    },
    heading: {
        fontWeight: '700',
        fontSize: '20px',
    },
    content: {
        fontSize: '14px',
        textAlign: 'center',
        color: '#84818A',
        margin: '-17px',
    },
    textAlign: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 16px',
        width: '-webkit-fill-available',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoutButton: {
        border: 'none',
        margin: '10px 15px 10px 15px',
        display: 'flex',
        padding: '7px 27px',
        alignItems: 'center',
        cursor: 'pointer',
        background: '#0c3450',
        '&:hover': {
          background: '#0c3450e0 !important'
        }
    },
      logoutButtonText: {
        fontWeight: '600',
        display: 'block',
        color: '#ffffff',
        alignItems: 'center',
        fontSize: '14px',
        textTransform: 'capitalize'
    },
};

export default WebNotFound;