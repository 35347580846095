class TimeUtils {
    static secondsToTime(secs) {
        let obj = {
            "h": 0,
            "m": 0,
            "s": 0
        };
        if (secs > 0) {
            let hours = Math.floor(secs / (60 * 60));

            let divisor_for_minutes = secs % (60 * 60);
            let minutes = Math.floor(divisor_for_minutes / 60);

            let divisor_for_seconds = divisor_for_minutes % 60;
            let seconds = Math.ceil(divisor_for_seconds);
            obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
        }
        return obj;
    }

    static changeTimeFormat(timeSpent) {
        if (!timeSpent) {
            return "00:00";
        }
        const minutes = Math.floor(timeSpent / 60);
        const secs = Math.floor(timeSpent - (minutes * 60));
        const m = (minutes < 10) ? '0' + minutes : minutes;
        const s = (secs < 10) ? '0' + secs : secs;
        return m + ':' + s;
    }
}

export default TimeUtils;
