import status from "../constants/actionStatus";

const initialState = {
    status: status.PENDING
};
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case 'RESET_QUIZ_SUBMISSION':
        return initialState;
    case 'SAVE_QUIZ_SUCCESS':
        return action.payload;
    case 'SAVE_QUIZ_ERROR':
        return action.payload;
    default:
        return state;
    }
}
