/**
 * Check if given answer is correct or not.
 * @param givenAnswer
 * @param options
 * @param type
 * @returns {boolean}
 */
function checkIfAnswerInCorrect(givenAnswer, options, type) {
    if (!givenAnswer || givenAnswer.length === 0) {
        return false;
    }
    let correctOptions = [];
    options.forEach((item) => {
        if (item.correct) {
            correctOptions.push(item._id);
        }
    });
    if (type.toLowerCase() === 'singlechoice') {
        return JSON.stringify(correctOptions[0]) === JSON.stringify(givenAnswer[0]);
    } else {
        if (givenAnswer) {
            givenAnswer = givenAnswer.filter((item) => {
                return (correctOptions.indexOf(item) > -1);
            });
            return givenAnswer.length === correctOptions.length;
        } else {
            return false;
        }
    }
}

export default checkIfAnswerInCorrect;
