import React from 'react';
import { useTranslations, useUserLanguage } from "examsbook-react-sdk/custom-hooks";
import translatorLogo from "../../../assets/images/newTranslatorLogo.png"
import { Box, List, ListItem, Typography } from '@material-ui/core';

/**
 * Language switcher.
 * @returns {*}
 * @constructor
 */
function LanguageSwitcher() {
    const t = useTranslations();
    const { language, setUserLanguage } = useUserLanguage();
    return (
        <React.Fragment>
            <Box component="img" id="languageImageId" src={translatorLogo} className="languageImage" alt=""/>
            {/* <span>{language === 'en' ? t('english') : t('hindi')}</span> */}
            <List className="sub-menus">
                <ListItem className={language === 'en' ? 'selected' : ''} onClick={() => {
                    setUserLanguage('en');
                }}><Typography variant="span">{t('english')}</Typography></ListItem>
                <ListItem className={language === 'hn' ? 'selected' : ''} onClick={() => {
                    setUserLanguage('hn');
                }}><Typography variant="span">{t('hindi')}</Typography></ListItem>
            </List>
            <Typography variant="span"></Typography>
        </React.Fragment>
    );
}

export default LanguageSwitcher;
