import status from "../constants/actionStatus";

export default (
    state = {
        status: status.PENDING,
        category: [],
    },
    action = {}
) => {
    switch (action.type) {
    case "CATEGORY_REQUEST":
        return {
            status: status.PENDING,
            category: [],
        };
    case "CATEGORY_SUCCESS":
        return {
            status: status.SUCCESS,
            category: action.payload,
        };

    case "CATEGORY_FAILURE":
        return {
            status: status.ERROR,
            category: [],
        };
    default:
        return state;
    }
};
