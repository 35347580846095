import axios from './axiosConfig';

export function saveFeedbacksForm(data) {
    return axios.post('/api/v1/feedback', data)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            // handle error
            return error;
        });
}

export function getSupportsApi(userId) {
    return axios
        .get(`/api/v1/corefeedbacks?userId=${userId}`)
        .then((response) => {
            // handle success
            return response.data;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}

export function getSupportApi() {
    return axios
        .get("api/v1/dynamic-seoSetting")
        .then((response) => {
            // handle success
            return response;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}

export function getDefaultSupportDataApi() {
    return axios
        .get("api/v1/seoSetting")
        .then((response) => {
            // handle success
            return response;
        })
        .catch((error) => {
            // handle error
            return error.data;
        });
}
