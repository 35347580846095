import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserCourse } from "examsbook-react-sdk/actions";
import status from "../constants/actionStatus";

/**
 * get course detail.
 * @returns {*}
 */
export default function useUserCourse(userId, isRefresh) {
    const dispatch = useDispatch();
    useEffect(() => {
        const ac = new AbortController();
        userId && dispatch(getUserCourse(userId));

        return () => ac.abort();
    }, [ isRefresh, userId ]);
    let courseData = useSelector((state) => state.userCourses);
    let count = courseData && courseData.courses && courseData.courses.count;
    let isLoading = courseData && courseData.status === status.PENDING;
    let userCourses = [];
    let userCoursesData =
        courseData &&
        courseData?.courses &&
        courseData?.courses.data &&
        courseData?.courses?.data?.length &&
        courseData?.courses?.data.forEach((element) => {
            let modifiedData = element?.courses.map((course, idx) => {
                return {
                    ...element,
                    ...element.courses[idx]
                };
            });
            userCourses = [ ...userCourses, ...modifiedData ];
        });
    return {
        count,
        isLoading,
        userCourses,
    };
}
