import React, { useState, useEffect } from "react";
import useInterval from "./useInterval";

export default function useQuizCountDown(duration, usedTime, pauseQuiz, viewMode, reset, selectTimeFor) {
    //initialize
    let remainingSecondsTime = (duration * 60);
    if (viewMode) {
        remainingSecondsTime = 0;
    } else if (usedTime > 0) {
        remainingSecondsTime = (duration * 60) - usedTime;
    }
    const [ secondsLeft, setSecondsLeft ] = useState(remainingSecondsTime);

    useEffect(() => {
        if (selectTimeFor === "QUESTION") {
            setSecondsLeft(remainingSecondsTime);
        }
    }, [ reset, remainingSecondsTime, selectTimeFor ]);

    useInterval(countDown, 1000);

    function countDown() {
        if (!viewMode) {
            //only if this is quiz mode.
            // Remove one second, set state so a re-render happens.
            if (secondsLeft > 0 && !pauseQuiz) {
                setSecondsLeft(secondsLeft - 1);
            }
        }
    }

    return secondsLeft;
}
