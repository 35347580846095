import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTranslations,
  useScreenWidth,
} from "examsbook-react-sdk/custom-hooks";
import CourseCard from "../components/Tabs/InternalCourse";
import TopicMaterialContainer from "../components/Tabs/TopicMaterialContainer";
import TabsLandingPage from "../components/Tabs/TabsLandingPage";
import NotificationBar from "../components/common/Header/NotificationBar";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Tooltip
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ReactComponent as SelfLearning } from "../../../web-app/src/assets/images/new-self.svg";
import { ReactComponent as Live } from "../assets/images/new-live.svg";
import { ReactComponent as MockTest } from "../../../web-app/src/assets/images/new-eval-test.svg";
import { ReactComponent as Certificate } from "../../../web-app/src/assets/images/new-certify.svg";
import ".././components/common/Header/Header.scss";
import ActiveSelfLearn from "../assets/images/Group.png";
import ActiveLiveClass from "../assets/images/LiveclassImg.png";
import ActiveMockTest from "../assets/images/MockText.png";
import ActiveCertificate from "../assets/images/ActiveCertificate.png";
import {
  getUserCourse,
  recentCourseListAction,
  selfLearningVideoUrl,
} from "examsbook-react-sdk/actions";
import { getSelfLearningAction } from "examsbook-react-sdk/actions/getSelfLearningAction";
import SelfLearningCard from "../components/Tabs/SelfLearningCard";
import { useTheme } from "../constants/themeProvider";
import highlightCourse from "../assets/images/highlightCourse.png";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as LiveIcon } from "../assets/images/live-video.svg";
import { ReactComponent as CertificateBread } from "../assets/images/certificate-bread.svg";
import { ReactComponent as Frame } from "../assets/images/frame.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getProgressBarDetails } from "examsbook-react-sdk/actions/getSelfLearningAction";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    zIndex: "auto",
    "& div": {
      "& div": {
        "& span": {
          "&.PrivateTabIndicator-colorPrimary-34": {
            backgroundColor: "#fff !important",
          },
        },
      },
    },
  },
  card: {
    // background: "#fff",
  },
  card1: {
    [theme.breakpoints.up("md")]: {
      background: "#fff",
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      right: 0,
      background: "#fff",
      paddingRight: "37px",
    },
  },
  fragment: {
    overflowX: "hidden",
    '@media (max-width: 767px)': {
      width: '100%',
      margin: 0
    }
  },
  sectionNextBtn: {
    marginBottom: "10px",
    backgroundColor: "rgb(12, 52, 80)",
    textTransform: "capitalize",
    padding: "4px 35px",
    display: "flex",
    marginLeft: "auto",
    position: "fixed",
    bottom: 30,
    right: '23%',
    "&:hover": {
      backgroundColor: "rgb(12, 52, 80)",
    },
  },
  mainFragmentExpand: {},
  cardExpand: {
    "@media (min-width: 960px) and (max-width: 1000px)": {
      width: "calc(371px - 7px)",
    },
    "@media (min-width: 1001px) and (max-width: 1023px)": {
      width: "calc(391px - 7px)",
    },
  },
  mainDiv: {
    background: "#FAFAFA",
    "@media (max-width: 959px)": {
      paddingRight: "16px",
      paddingLeft: "16px",
    },
  },
  contentDiv: {
    backgroundColor: "#fff",
    "@media (max-width: 959px)": {
      paddingTop: "10px",
      paddingRight: "16px",
      paddingLeft: "16px",
    },
    "@media (min-width: 960px)": {
      width: "450px",
      right: "0",
      paddingTop: "80px",
      position: "fixed",
    },
  },
  activeIcon: {
    height: "30px",
    width: "35px",
  },
  breadcrumsAlign: {
    textDecoration: "none !important",
    textTransform: "capitalize",
    fontSize: "24px",
    fontWeight: "600",
    color: "#202020",
    whiteSpace: "nowrap",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    '& ol': {
      '& li span': {
        color: 'rgba(0,0,0,0.87) !important',
      },
    },
    "& ol.MuiBreadcrumbs-ol li.MuiBreadcrumbs-separator:nth-last-of-type(2)": {
      display: "none !important",
    },
  },
  courseProgressAlign: {
    margin: "10px 0px",
    "& > div": {
      backgroundColor: "#ffffff !important",
    },
  },
  percentageColor: {
    color: "#ffffff !important",
    fontSize: "14px",
    fontWeight: "500",
  },
  "@media (max-width: 768px )": {
    heading: {
      fontSize: "16px",
      padding: '0 18px'
    },
  },
  scrollContent: {
    padding: "0px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    height: "auto !important",
    marginBottom: "0px",
  },
  mainLeftContainer: {
    position: "relative",
    height: "calc(100vh - 100px)",
    overflow: 'auto',
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff'},
  moduleContentContainer: {
    height: 'calc(100svh - 300px)',
    overflow: 'auto'
  }
}));

/**
 * Topic Material Tab-screen
 * @returns {*}
 * @constructor
 */

function TopicMaterial(props) {
  const location = useLocation();
  const subjectDetail = location?.state?.subjectDetail;
  const course_id = localStorage.getItem("courseId");
  const subject_id = location.state.subjectId;
  const topic_id = location.state.topicId;
  const [isMobile, setIsMobile] = useState(false);
  const [videoProgress, setVideoProgress] = useState({});
  const [isDropDown, setIsDropDown] = useState({});
  const handleVideoProgress = (videoKey, percent) => {
    if (videoKey) {
      setVideoProgress((prev) => ({
        ...prev,
        [videoKey]: percent,
      }));
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const windowScreenWidth = useScreenWidth();
  const screenWidth = 900;

  function closeDrawer() {
    setOpenSidebar(false);
  }

  const [selectedQuizCard, setSelectedQuizCard] = useState(null);
  const [quizAttemptForResult, setQuizAttemptForResult] = useState(null);
  const [activeItem, setActiveItem] = useState(false);
  const [mySectionData, setMySectionData] = useState("");
  const t = useTranslations();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const [testQuiz, setTestQuiz] = useState(false);
  const [sidebarData, setSidebarData] = useState([]);
  const [videoRefresh, setVideoRefresh] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [sectionChanged, setSectionChanged] = useState(false);
  const [courseDataList, setCourseDataList] = useState([]);
  const [nextClick, setNextClick] = useState([]);
  const [showEvaluationTest, setShowEvaluationTest] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [startButtonClicked, setStartButtonClicked] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [test1, setTest1] = useState(false);
  const [openpdf, setOpenpdf] = useState(false);
  const [pdfPath, setpdfPath] = useState("");
  const [sectionNumber, setSectionNumber] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const handleOpenPdf = () => setOpenpdf(true);
  const handleClosePdf = () => setOpenpdf(false);
  let recentCourseData = useSelector((state) => state.recentCourseList);
  useEffect(() => {
    user?._id && dispatch(recentCourseListAction(user?._id));
  }, [user?._id]);

  const tabLabelsOuter = [
    { label: t("SELF LEARNING"), icon: <MenuIcon fontSize="small" /> },
    { label: t("LIVE_CLASS"), icon: <LiveIcon fontSize="small" /> },
    { label: t("EVALUATION TEST"), icon: <Frame fontSize="small" /> },
    { label: t("CERTIFICATE"), icon: <CertificateBread fontSize="small" /> },
  ];

  const tabLabels = [
    { id: 0, label: t("SELF LEARNING"), icon: <MenuIcon fontSize="small" /> },
    ...(mySectionData?.data?.liveClass?.length > 0
      ? [{ id: 1, label: t("LIVE_CLASS"), icon: <LiveIcon fontSize="small" /> }]
      : []),
    ...(mySectionData?.data?.evaluationTest?.length > 0
      ? [
          {
            id: 2,
            label: t("EVALUATION TEST"),
            icon: <Frame fontSize="small" />,
          },
        ]
      : []),
    {
      id: 3,
      label: t("CERTIFICATE"),
      icon: <CertificateBread fontSize="small" />,
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmenuClick = (data) => {
    setSidebarData(data?.data);
  };
  const handleMenuItemClick = (index) => {
    setSelectedTab(index); // Update the selectedTab when an item is clicked
    setShowEvaluationTest(false);
    handleClose();
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setSelectedTab(Number(savedTab));
    }
  }, [testQuiz]);

  const handleVideoRefresh = (value) => {
    setVideoRefresh(value);
  };

  const handlePdfOpen = (value) => {
    setPdfOpen(value);
  };

  function handleTabChange(value) {
    setSelectedTab(value);
  }

  let tabBarList = [
    {
      id: 0,
      label: t("SELF LEARNING"),
      icon: <SelfLearning className={classes.selfLearningIcon} />,
      activeIcon: (
        <img
          alt=""
          src={ActiveSelfLearn}
          className={classes.selfLearningIcon}
        />
      ),
      customBadgeClass: "customBadge1",
    },
    {
      id: 1,
      label: t("LIVE CLASSES"),
      icon: <Live className={classes.liveClassesIcon} />,
      activeIcon: (
        <img alt="" src={ActiveLiveClass} className={classes.liveClassesIcon} />
      ),
      customBadgeClass: "customBadge2",
    },
    {
      id: 2,
      label: t("EVALUATION TEST"),
      icon: <MockTest className={classes.evaluationTestIcon} />,
      activeIcon: (
        <img
          alt=""
          src={ActiveMockTest}
          className={classes.evaluationTestIcon}
        />
      ),
      customBadgeClass: "customBadge3",
    },
    {
      id: 3,
      label: t("CERTIFICATE"),
      icon: <Certificate className={classes.certificateTestIcon} />,
      activeIcon: (
        <img
          alt=""
          src={ActiveCertificate}
          className={classes.certificateTestIcon}
        />
      ),
      customBadgeClass: "customBadge4",
    },
  ];
  const [isFeedbackModelOpen, setIsFeedbackModelOpen] = useState(false);
  const [downloadCertificateButton, setdownloadCertificateButton] =
    useState(false);

  function goToDashboard() {
    history.goBack();
  }
  function openFeedback() {
    setIsFeedbackModelOpen(true);
  }
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 959);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onQuizCardClick(data) {
    setSelectedQuizCard(data);
    setTestQuiz(true);
  }

  useEffect(() => {
    dispatch(getSelfLearningAction(course_id)).then((res) => {
      setMySectionData(res);
      setSelectedQuizCard(res?.data?.evaluationTest[0]?.testId);
      setQuizAttemptForResult(res?.data?.evaluationTest[0]?.quizAttempts);
    });
  }, [course_id, videoRefresh, pdfOpen]);

  useEffect(() => {
    document.title = `${
      selectedTab === 0
        ? t("SELF LEARNING")
        : selectedTab === 1
        ? t("LIVE_CLASS")
        : selectedTab === 2
        ? t("EVALUATION TEST")
        : selectedTab === 3
        ? t("CERTIFICATE")
        : ""
    } | LMS`;
  }, [selectedTab]);

  const showInstructionScreen = () => {
    setShowInstructions(true);
  };

  const getCourseSectionApiData = async () => {
    try {
      if (course_id !== undefined || showInstructions) {
        dispatch(getSelfLearningAction(course_id)).then((res) => {
          setMySectionData(res);
          res?.data?.section.map((sectionData, index) => {
            setSectionNumber((preState) => [
              ...preState,
              sectionData.courseSectionData.length,
            ]);
            if (index === 0) {
              setNextClick([
                {
                  ...sectionData.courseSectionData[0],
                  sectionId: sectionData._id,
                  title: sectionData.title,
                },
              ]);
              sectionData.courseSectionData.shift();
            }
            setCourseDataList((preState) => [
              ...preState,
              ...[...sectionData.courseSectionData].map((data) => {
                return {
                  ...data,
                  sectionId: sectionData._id,
                  title: sectionData.title,
                };
              }),
            ]);
          });
          if (!props.activeItem && typeof res != "undefined") {
            setActiveItem({
              data: res?.data?.section[0],
              sectionData: res,
            });
          }
        });
      }
    } catch (error) {
      console.log("Error - ", error.message);
    }
  };

  const handleNextClick = (index) => {
    if (courseDataList.length !== 0) {
      const newEle = courseDataList.shift();
      if (sectionNumber.length && sectionNumber[0] === nextClick?.length) {
        sectionNumber.shift();
        setSectionNumber(sectionNumber)
        // setSectionName()
        setNextClick([newEle]);
        setSectionName(newEle.title.en)
        const sectionIndex = mySectionData.section.findIndex((arrData) => arrData._id === newEle.sectionId);
        const newObj = {};
        Object.keys(isDropDown).forEach((key) => {
          if (key == sectionIndex) {
            newObj[key] = true;
          } else {
            newObj[key] = false;
          }
        })
        setIsDropDown(newObj);
      } else {
        setNextClick((prevState) => [
          ...prevState,
          newEle
        ]);
      }
      setCourseDataList(courseDataList);
    }
    setTest1(true)
  };

  useEffect(() => {
    if(showEvaluationTest) {
      if (nextClick.length && nextClick[nextClick.length - 1].refModel === 'PdfNotes') {
        handleOnClick(nextClick.length - 1);
      } else if (nextClick.length && nextClick[nextClick.length - 1].refModel === 'TextNotes') {
        handleOnClick(nextClick.length - 1);
      } else if (nextClick.length && nextClick[nextClick.length - 1].refModel === 'RecordedVideo') {
        dispatch(selfLearningVideoUrl(nextClick[0]));
      }
    }
  }, [nextClick, showEvaluationTest]);

  const handleUpdateCourseProgress = (data) =>{
    dispatch(getProgressBarDetails(data)).then(
      () => dispatch(getSelfLearningAction(course_id)).then(
        res => setMySectionData(res)
      )
    );
  }

  const handleOnClick = (index) => {
    if (nextClick[index]?.refModel === "PdfNotes") {
      let percent = 100;
      let data = {
        sectionId: nextClick[index]?.sectionId,
        dataId: nextClick[index]?._id,
        percent: percent,
        courseId: mySectionData?.data?.courseId?._id,
        isRead: true,
      };
      handleUpdateCourseProgress(data);
      handlePdfOpen(pdfOpen);
      openPdfViewer(nextClick[index]?.contentData?.featured);
    } else if (nextClick[index]?.refModel === "TextNotes") {
      let percent = 100;
      let data = {
        sectionId: nextClick[index]?.sectionId,
        dataId: nextClick[index]?._id,
        percent: percent,
        courseId: mySectionData?.data?.courseId?._id,
        isRead: true,
      };
      handleUpdateCourseProgress(data);
    } else if (nextClick[index]?.refModel === "RecordedVideo") {
      let data = {
        sectionId: nextClick[index]?.sectionId,
        dataId: nextClick[index]?._id,
        percent: 0,
        courseId: mySectionData?.data?.courseId?._id,
      };
      handleUpdateCourseProgress(data);
    }
  };

  function handleCourseNavigation() {
    setShowEvaluationTest(false);
    setShowInstructions(false);
    setMySectionData("");
    setSidebarData([]);
  }

  const openPdfViewer = (path) => {
    setpdfPath(path);
    handleOpenPdf();
  };
  useEffect(() => {
    getCourseSectionApiData();
  }, [course_id, showInstructions]);
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  const drawerToggle = JSON.parse(localStorage.getItem("openDrawerStatus"));

  return (
    <>
      {isMobile ? (
        <Grid container spacing={3} className={classes.fragment}>
          <Box
            style={{
              padding: "0px",
              marginBottom: "0px",
              display: "flex",
              alignItems: "center",
              height: "60px",
              width: "100%",
              boxShadow: '0px -2px 12px 0px rgba(225, 225, 225, 0.50)'
            }}
            className={"container " + (openSidebar ? "open-drawer" : "")}
            onClick={
              windowScreenWidth <= screenWidth
                ? closeDrawer.bind(this)
                : () => null
            }
          >
            <Box className={classes.heading}>
              {mySectionData?.data?.courseId?.name && (
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography variant="span" color="inherit">
                    {mySectionData?.data?.courseId?.name}
                  </Typography>

                  {
                    <>
                      <IconButton
                        aria-controls="breadcrumb-menu"
                        aria-haspopup="true"
                        style={{ padding: 0 }}
                      >
                        <Typography
                          variant="span"
                          color="inherit"
                          style={{ fontSize: "1rem", paddingRight: "5px" }}
                        >
                          {tabLabels[selectedTab]?.label || ""}
                        </Typography>
                      </IconButton>
                      <IconButton
                        aria-controls="breadcrumb-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ padding: 0 }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </>
                  }

                  {/* Dropdown Menu */}
                  <Menu
                    id="breadcrumb-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ top: "30px" }}
                  >
                    {tabLabels.map((tab, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        <ListItemIcon>{tab.icon}</ListItemIcon>
                        <Typography variant="inherit">{tab.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Breadcrumbs>
              )}
            </Box>
          </Box>
          <Grid item md={8} xs={12} className={classes.mainDiv}>
            <>
              {nextClick.map((course, index) => {
                return (
                  <>
                    <TabsLandingPage
                      selectedIndex={selectedTab}
                      tabBarList={tabBarList}
                      selectedTab={selectedTab}
                      handleTabChange={handleTabChange}
                      setdownloadCertificateButton={
                        setdownloadCertificateButton
                      }
                      onQuizCardClick={onQuizCardClick}
                      onPdfOpen={handlePdfOpen}
                      course_id={course_id}
                      subject_id={subject_id}
                      topic_id={topic_id}
                      subjectDetail={subjectDetail}
                      history={history}
                      downloadCertificateButton={downloadCertificateButton}
                      goToDashboard={goToDashboard}
                      selectedQuizCard={selectedQuizCard}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      onVideoRefresh={handleVideoRefresh}
                      videoRefresh={videoRefresh}
                      sectionChanged={sectionChanged}
                      setSectionChanged={setSectionChanged}
                      courseName={mySectionData?.data?.courseId?.name}
                      quizAttemptForResult={
                        quizAttemptForResult != null && quizAttemptForResult
                      }
                      quizAttempCourseId={mySectionData?.data?.courseId?._id}
                      coursePercentage={mySectionData?.data?.progressPercent}
                      evaluationUnlockPercentage={
                        selectedQuizCard?.EvaluationUnlockPercent
                      }
                      pdfOpen={pdfOpen}
                      videoProgress={videoProgress}
                      handleVideoProgress={handleVideoProgress}
                      handleNextClick={handleNextClick}
                      item={course}
                      showEvaluationTest={showEvaluationTest}
                      setShowEvaluationTest={setShowEvaluationTest}
                      setStartButtonClicked={setStartButtonClicked}
                      startButtonClicked={startButtonClicked}
                      openpdf={openpdf}
                      handleClosePdf={handleClosePdf}
                      handleOpenPdf={handleOpenPdf}
                      pdfPath={pdfPath}
                      setpdfPath={setpdfPath}
                      sectionName={sectionName}
                      setSectionNumber={setSectionNumber}
                      setSectionName={setSectionName}
                      handleUpdateCourseProgress={handleUpdateCourseProgress}
                    />
                  </>
                );
              })}
            </>
          </Grid>
          <Grid item md={2} xs={12} className={classes.contentDiv}>
            <Box
              className={classes.card}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffff",
              }}
            >
              <Box
                className="notification-bar"
                style={{ marginLeft: "auto", marginRight: "0px" }}
              >
                <NotificationBar openFeedbackModel={openFeedback} />
              </Box>
              <Box className="backgroundDiv">
                {showEvaluationTest ? (
                  mySectionData?.data?.section !== undefined ? (
                    mySectionData?.data?.section.map((item, index) => {
                  return (
                    <SelfLearningCard
                      key={item._id || index} // Ensure unique key
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      index={index}
                      showEvaluationTest={showEvaluationTest}
                      data={item}
                      sectionData={mySectionData}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      onVideoRefresh={handleVideoRefresh}
                      videoRefresh={videoRefresh}
                      pdfOpen={pdfOpen}
                      handleNextClick={() => handleNextClick(index)}
                      onPdfOpen={handlePdfOpen}
                      sectionChanged={sectionChanged}
                      setSectionChanged={setSectionChanged}
                      setSectionName={setSectionName}
                      nextClick={nextClick}
                      setNextClick={setNextClick}
                      setIsDropDown={setIsDropDown}
                      setCourseDataList={setCourseDataList}
                      test1={test1}
                      sectionName={sectionName}
                      onSubmenuClick={handleSubmenuClick}
                      courseId={mySectionData?.data?.courseId?._id}
                      handleUpdateCourseProgress={handleUpdateCourseProgress}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  mySectionData !== "" && (
                  <CourseCard
                      courseData={mySectionData?.data?.courseId}
                      progressPercent={mySectionData?.data?.progressPercent}
                  />
                  )
                )}
              </Box>
            </Box>
            <Box className={classes.card1}>
              <TopicMaterialContainer
                selectedIndex={selectedTab}
                course_id={course_id}
                showImg={"UIImg"}
                subject_id={subject_id}
                topic_id={topic_id}
                subjectDetail={subjectDetail}
                history={history}
                setdownloadCertificateButton={setdownloadCertificateButton}
                downloadCertificateButton={downloadCertificateButton}
                goToDashboard={goToDashboard}
                onQuizCardClick={onQuizCardClick}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                onVideoRefresh={handleVideoRefresh}
                onPdfOpen={handlePdfOpen}
                pdfOpen={pdfOpen}
                videoRefresh={videoRefresh}
                sectionChanged={sectionChanged}
                setSectionChanged={setSectionChanged}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={0}
          className={
            drawerToggle ? classes.mainFragmentExpand : classes.fragment
          }
        >
          {showInstructions &&
          window.location.pathname !== "/course-material/content" ? (
            <></>
          ) : (
            <Box
            className={
              "container sticky-header" + (openSidebar ? "open-drawer" : "")
            }
            onClick={
              windowScreenWidth <= screenWidth
                ? closeDrawer.bind(this)
                : () => null
            }
          >
            <Box className={classes.heading}>
                {mySectionData?.data?.courseId?.name && (
                <Breadcrumbs aria-label="breadcrumb">
                    {showEvaluationTest || showInstructions ? (
                      <IconButton
                        aria-controls="breadcrumb-menu"
                        aria-haspopup="true"
                        style={{ padding: 0 }}
                        onClick={handleCourseNavigation}
                      >
                        <Typography
                          variant="span"
                          color="inherit"
                          style={{ fontSize: "16px", fontFamily: "Poppins" }}
                        >
                          {mySectionData?.data?.courseId?.name}
                        </Typography>
                      </IconButton>
                    ) : (
                      <Typography variant="span" color="inherit">
                        {mySectionData?.data?.courseId?.name}
                      </Typography>
                    )}

                    {
                      <>
                        <Typography
                          variant="span"
                          color="inherit"
                          style={{ fontSize: "1rem", paddingRight: "5px" }}
                        >
                          {tabLabelsOuter[selectedTab]?.label || ""}
                        </Typography>
                        {showEvaluationTest && (
                      <IconButton
                        aria-controls="breadcrumb-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ padding: 0 }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                        )}
                    </>
                    }

                  {/* Dropdown Menu */}
                  <Menu
                    id="breadcrumb-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                      style={{ top: "30px" }}
                  >
                    {tabLabels.map((tab, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMenuItemClick(tab?.id)}
                      >
                        <ListItemIcon>{tab.icon}</ListItemIcon>
                        <Typography variant="inherit">{tab.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Breadcrumbs>
              )}
            </Box>
            <Box
              className="notification-bar"
              style={{ marginLeft: "auto", marginRight: "0px" }}
            >
              <NotificationBar openFeedbackModel={openFeedback} />
            </Box>
            </Box>
          )}
          <Box className="main-division">
            <Grid container>
              <Grid item xs={12} md={!showInstructions ? 9 : 12} className={classes.mainLeftContainer}>
                {sidebarData?.refModel && showEvaluationTest && (
                  <Card
                    className="card-class"
                    style={{ background: "#fafafa", paddingBottom: "60px" }}
                  >
                    <CardContent
                      className="card-content-class"
                      style={{ padding: "0px" }}
                    >
                      <TabsLandingPage
                        selectedIndex={selectedTab}
                        course_id={course_id}
                        tabBarList={tabBarList}
                        subject_id={subject_id}
                        topic_id={topic_id}
                        subjectDetail={subjectDetail}
                        history={history}
                        downloadCertificateButton={downloadCertificateButton}
                        goToDashboard={goToDashboard}
                        setActiveItem={setActiveItem}
                        activeItem={activeItem}
                        onVideoRefresh={handleVideoRefresh}
                        videoRefresh={videoRefresh}
                        sectionChanged={sectionChanged}
                        setSectionChanged={setSectionChanged}
                        courseName={mySectionData?.data?.courseId?.name}
                        quizAttemptForResult={
                          quizAttemptForResult != null && quizAttemptForResult
                        }
                        quizAttempCourseId={mySectionData?.data?.courseId?._id}
                        coursePercentage={mySectionData?.data?.progressPercent}
                        pdfOpen={pdfOpen}
                        videoProgress={videoProgress}
                        handleVideoProgress={handleVideoProgress}
                        item={sidebarData}
                        showEvaluationTest={showEvaluationTest}
                        setStartButtonClicked={setStartButtonClicked}
                        startButtonClicked={startButtonClicked}
                        setShowInstructions={setShowInstructions}
                        showInstructions={showInstructions}
                        showInstructionScreen={showInstructionScreen}
                        openpdf={openpdf}
                        handleClosePdf={handleClosePdf}
                        handleOpenPdf={handleOpenPdf}
                        pdfPath={pdfPath}
                        setpdfPath={setpdfPath}
                        sectionName={sectionName}
                        setSectionNumber={setSectionNumber}
                        setSectionName={setSectionName}
                        handleUpdateCourseProgress={handleUpdateCourseProgress}
                      />
                    </CardContent>
                  </Card>
                )}
                {showEvaluationTest && !sidebarData?.refModel ? (
                  <Card
                    className="card-class"
                    style={{ background: "#fafafa" }}
                  >
                    {nextClick.map((course, index) => {
                      return (
                        <div key={index} id={`course-${index}`}>
                          <CardContent
                            className="card-content-class"
                            style={{ padding: "0px" }}
                          >
                            <CardContent
                              className={classes.scrollContent}
                            >
                              <TabsLandingPage
                                selectedIndex={selectedTab}
                                course_id={course_id}
                                tabBarList={tabBarList}
                                subject_id={subject_id}
                                topic_id={topic_id}
                                subjectDetail={subjectDetail}
                                history={history}
                                downloadCertificateButton={
                                  downloadCertificateButton
                                }
                                goToDashboard={goToDashboard}
                                setActiveItem={setActiveItem}
                                activeItem={activeItem}
                                onVideoRefresh={handleVideoRefresh}
                                videoRefresh={videoRefresh}
                                sectionChanged={sectionChanged}
                                setSectionChanged={setSectionChanged}
                                courseName={mySectionData?.data?.courseId?.name}
                                quizAttemptForResult={
                                  quizAttemptForResult != null &&
                                  quizAttemptForResult
                                }
                                quizAttempCourseId={
                                  mySectionData?.data?.courseId?._id
                                }
                                coursePercentage={
                                  mySectionData?.data?.progressPercent
                                }
                                pdfOpen={pdfOpen}
                                videoProgress={videoProgress}
                                handleVideoProgress={handleVideoProgress}
                                item={course}
                                showEvaluationTest={showEvaluationTest}
                                setStartButtonClicked={setStartButtonClicked}
                                startButtonClicked={startButtonClicked}
                                setShowInstructions={setShowInstructions}
                                showInstructions={showInstructions}
                                showInstructionScreen={showInstructionScreen}
                                openpdf={openpdf}
                                handleClosePdf={handleClosePdf}
                                handleOpenPdf={handleOpenPdf}
                                pdfPath={pdfPath}
                                setpdfPath={setpdfPath}
                                sectionName={sectionName}
                                courseDataList={courseDataList}
                                setCourseDataList={setCourseDataList}
                                setSectionNumber={setSectionNumber}
                                setNextClick={setNextClick}
                                isDropDown={isDropDown}
                                setIsDropDown={setIsDropDown}
                                setSectionName={setSectionName}
                                sectionNumber={sectionNumber}
                                handleUpdateCourseProgress={handleUpdateCourseProgress}
                              />
                            </CardContent>
                          </CardContent>
                        </div>
                      );
                    })}
                  </Card>
                ) : (
                  <Box className="main-body">
                    <TabsLandingPage
                      selectedIndex={selectedTab}
                      tabBarList={tabBarList}
                      selectedTab={selectedTab}
                      handleTabChange={handleTabChange}
                      setdownloadCertificateButton={
                        setdownloadCertificateButton
                      }
                      onQuizCardClick={onQuizCardClick}
                      onPdfOpen={handlePdfOpen}
                      course_id={course_id}
                      subject_id={subject_id}
                      topic_id={topic_id}
                      subjectDetail={subjectDetail}
                      history={history}
                      downloadCertificateButton={downloadCertificateButton}
                      goToDashboard={goToDashboard}
                      selectedQuizCard={selectedQuizCard}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      onVideoRefresh={handleVideoRefresh}
                      videoRefresh={videoRefresh}
                      sectionChanged={sectionChanged}
                      setSectionChanged={setSectionChanged}
                      courseName={mySectionData?.data?.courseId?.name}
                      quizAttemptForResult={
                        quizAttemptForResult != null && quizAttemptForResult
                      }
                      quizAttempCourseId={mySectionData?.data?.courseId?._id}
                      coursePercentage={mySectionData?.data?.progressPercent}
                      evaluationUnlockPercentage={
                        selectedQuizCard?.EvaluationUnlockPercent
                      }
                      pdfOpen={pdfOpen}
                      videoProgress={videoProgress}
                      handleVideoProgress={handleVideoProgress}
                      showEvaluationTest={showEvaluationTest}
                      setShowEvaluationTest={setShowEvaluationTest}
                      setStartButtonClicked={setStartButtonClicked}
                      startButtonClicked={startButtonClicked}
                      setShowInstructions={setShowInstructions}
                      showInstructions={showInstructions}
                      showInstructionScreen={showInstructionScreen}
                      openpdf={openpdf}
                      handleClosePdf={handleClosePdf}
                      handleOpenPdf={handleOpenPdf}
                      pdfPath={pdfPath}
                      setpdfPath={setpdfPath}
                      sectionName={sectionName}
                      courseDataList={courseDataList}
                      setCourseDataList={setCourseDataList}
                      setSectionNumber={setSectionNumber}
                      setNextClick={setNextClick}
                      isDropDown={isDropDown}
                      setIsDropDown={setIsDropDown}
                      setSectionName={setSectionName}
                      sectionNumber={sectionNumber}
                      handleUpdateCourseProgress={handleUpdateCourseProgress}
                    />
                  </Box>
                )}
              </Grid>
             {!showInstructions &&
              <Grid item xs={12} md={3}>
              <Box className="main-right-sidebar w-full">
                <Box
                  className={
                    drawerToggle && window.innerWidth <= 959
                      ? classes.cardExpand
                      : classes.card
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "16px",
                    position: "sticky",
                    height: " calc(100vh - 100px)",
                  }}
                >
                  {showEvaluationTest ? (
                    <Grid item xs={12}>
                <Card>
                        <CardContent
                          style={{ padding: "0px", background: "#fafafa" }}
                        >
                    {/* Progress Section */}
                          <Paper
                            style={{
                              backgroundColor: `${theme?.primaryColor}`,
                              padding: "var(--3, 24px) 10px",
                              backgroundImage: `url(${highlightCourse})`,
                              backgroundSize: "cover",
                              borderRadius: "10px",
                            }}
                          >
                            <Tooltip placement="top-start" title={mySectionData?.data?.courseId?.name}>
                              <Typography
                                variant="h6"
                                component="h2"
                                className={classes.ellipsisText}
                              >
                                {mySectionData?.data?.courseId?.name}
                              </Typography>
                            </Tooltip>
                            <LinearProgress
                              variant="determinate"
                              value={mySectionData?.data?.progressPercent}
                              className={classes.courseProgressAlign}
                            />
                            <Typography
                              variant="body1"
                              className={classes.percentageColor}
                            >
                              {Math.round(
                                mySectionData?.data?.progressPercent
                              )}
                              % {t("Completed")}
                            </Typography>
                          </Paper>
                          {/* Modules Section */}
                          <Paper
                            className="modules-full-box"
                            style={{
                              background: "#FFFFFF",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h2"
                              style={{
                                marginTop: "20px",
                                padding: "13px 10px 0px 10px",
                              }}
                            >
                              {t("Modules")}
                            </Typography>
                          <Box className={classes.moduleContentContainer}>
                            {mySectionData?.data?.section !== undefined ? (
                              mySectionData?.data?.section.map(
                                (item, index) => {
                        return (
                          <SelfLearningCard
                            key={item._id || index} // Ensure unique key
                            setActiveItem={setActiveItem}
                            activeItem={activeItem}
                            index={index}
                            data={item}
                            sectionData={mySectionData}
                            showEvaluationTest={showEvaluationTest}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            onVideoRefresh={handleVideoRefresh}
                            videoRefresh={videoRefresh}
                            pdfOpen={pdfOpen}
                            onPdfOpen={handlePdfOpen}
                            sectionChanged={sectionChanged}
                            setSectionChanged={setSectionChanged}
                            nextClick={nextClick}
                            test1={test1}
                            sectionName={sectionName}
                            onSubmenuClick={handleSubmenuClick}
                            courseDataList={courseDataList}
                            setCourseDataList={setCourseDataList}
                            setSectionNumber={setSectionNumber}
                            setNextClick={setNextClick}
                            isDropDown={isDropDown}
                            setIsDropDown={setIsDropDown}
                            setSectionName={setSectionName}
                            sectionNumber={sectionNumber}
                            courseId={mySectionData?.data?.courseId?._id}
                            handleUpdateCourseProgress={handleUpdateCourseProgress}
                            />
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                            </Box>
                          </Paper>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : (
                    mySectionData !== "" && (
                <CourseCard
                        courseData={mySectionData?.data?.courseId}
                        progressPercent={mySectionData?.data?.progressPercent}
                />
                    )
                  )}
              <TopicMaterialContainer
                selectedIndex={selectedTab}
                course_id={course_id}
                showImg={"UIImg"}
                subject_id={subject_id}
                topic_id={topic_id}
                subjectDetail={subjectDetail}
                history={history}
                    setdownloadCertificateButton={
                      setdownloadCertificateButton
                    }
                downloadCertificateButton={downloadCertificateButton}
                goToDashboard={goToDashboard}
                onQuizCardClick={onQuizCardClick}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                onVideoRefresh={handleVideoRefresh}
                videoRefresh={videoRefresh}
                onPdfOpen={handlePdfOpen}
                pdfOpen={pdfOpen}
                sectionChanged={sectionChanged}
                setSectionChanged={setSectionChanged}
              />
            </Box>
          </Box>
            </Grid>}
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
}

export default TopicMaterial;
