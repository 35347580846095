import status from "../constants/actionStatus";

export default (
    state = {
        subjectList: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "SUBJECT_REQUEST":
        return {
            status: status.PENDING,
            subjectList: {},
        };
    case "SUBJECT_SUCCESS":
        return {
            status: status.SUCCESS,
            subjectList: action.payload,
        };

    case "SUBJECT_FAILURE":
        return {
            status: status.ERROR,
            subjectList: {},
        };
    default:
        return state;
    }
};
