export default (state = [], action = {}) => {
    if (action.type === 'PROMOTIONS_WAITING') {
        return {
            pending: true,
            success: false,
            data: []
        };
    } else if (action.type === 'PROMOTIONS_SUCCESS') {
        return {
            pending: false,
            success: true,
            data: action.payload
        };
    } else if (action.type === 'PROMOTIONS_ERROR') {
        return {
            pending: false,
            success: false,
            data: []
        };
    }
    return state;
};
