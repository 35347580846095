import React, { useEffect, useState } from 'react';
import './ChangePassword.scss';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { getDefaultSupportDataAction, getSupportAction } from 'examsbook-react-sdk/actions/getSupportAction';
import { Box, Button, Grid, Icon, Input, InputLabel } from '@material-ui/core';
import { changePasswordNewUser, emailChangePasswordNewUser } from 'examsbook-react-sdk/actions/getUser';
import { useHistory, useLocation } from 'react-router-dom';
import LearnifyIcon from "../../assets/images/LearnifyLogoDark.png"

const ChangePassword = () => {
  const [supportData, setSupportData] = useState([]);
  const [defaultSupportData, setDefaultSupportData] = useState([]);;
  const [bgColor, setBgColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [productName, setProductName] = useState(null);
  const [formValues, setFormValues] = useState({newPassword: "", confirmPassword: ""});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const t = useTranslations();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const dynamicLogo = supportData?.data?.find((item) => item.key === "Image")?.value;
    const defaultProductName = defaultSupportData?.data?.['Product Name'][0]?.defaultValue;
    const dynamicProductName = supportData?.data?.find(item =>  item.key === 'Product Name')?.value;
    setProductName(dynamicProductName ? dynamicProductName : defaultProductName)
    setBgColor("#0C3450");
    setTextColor("#fff");
  },[defaultSupportData, supportData]);

useEffect(() => {
    getSettingApiData();
    getDefaultSeoSettingApiData();
    const emailFromUrl = new URLSearchParams(window.location.search).get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, []);

  const getSettingApiData = async () => {
      try {
          dispatch(getSupportAction()).then((res) => {
              setSupportData(res);
          });
      } catch (error) {
        console.log('Error -', error.message);
      }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const togglePasswordVisibility = (inputId, iconId) => {
    const passwordInput = document.getElementById(inputId);
    const icon = document.getElementById(iconId);
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      icon.classList.replace('fa-eye-slash', 'fa-eye');
    } else {
      passwordInput.type = 'password';
      icon.classList.replace('fa-eye', 'fa-eye-slash');
    }
  };

  const preventSpaceKey = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleSetPassword = async (e) => {
    e.preventDefault();
    const { confirmPassword, newPassword } = formValues;
    const { password, ...rest } = location?.state ?? {}
    const email = window.location.href.includes('?') ? window.location.href.split('?')?.slice(1)?.join('')?.split('=') || "" : "";

    const getSubdomain = () => {
      const host = window.location.hostname;
      const domainParts = host.split('.');
      if (domainParts.length > 2) {
        return domainParts[0];
      }
      return '';
    };

    const subdomain = getSubdomain();

    const changePasswordData = {
      ...rest,
      email: email || rest.email,
      oldPassword: password,
      subdomain,
      confirmPassword,
      newPassword
    };

    if (email && confirmPassword && newPassword) {
      dispatch(emailChangePasswordNewUser(changePasswordData)).then((res) => {
        if (res?.success === "success") {
          history.push('/login');
        }
      })
      return;
    }

    if (!email && confirmPassword && newPassword) {
      dispatch(changePasswordNewUser(changePasswordData)).then((res) => {
        if (res?.success === "success") {
          history.push('/login');
        }
      })
    }
  }

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    // Update the corresponding field in formValues
    setFormValues((prev) => {
      const updatedValues = { ...prev, [name]: value };

      // Validate newPassword strength
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      let errorMessage = "";
      let valid = true;

      // Check if `newPassword` meets the regex criteria
    if (!regex.test(updatedValues.newPassword)) {
      errorMessage =
        "Enter min 8 letters, with at least a symbol, upper and lower case letters, and a number.";
      valid = false;
    }

      // Check for password mismatch
      if (updatedValues.confirmPassword !== updatedValues.newPassword) {
        errorMessage = "Passwords do not match.";
        valid = false;
      }

      // Update error message and button state
      if (!valid) {
        document.getElementById("reset-password-error").innerHTML = `<div class="errorMsg">${errorMessage}</div>`;
        document.getElementById("reset-password-error").style.display = "block";
        document.getElementById("changePasswordBtn").setAttribute("disabled", "disabled");
        setIsButtonDisabled(true);
      } else {
        document.getElementById("reset-password-error").style.display = "none";
        document.getElementById("changePasswordBtn").removeAttribute("disabled");
        setIsButtonDisabled(false);
      }

      return updatedValues;
    });
  };

  return (
    <Grid className="change-password-parent-div">
        <Grid className="change-password-child-div">
          <Box component="img" id= "login-img-learn" className="change-password-logo" src={LearnifyIcon} alt={productName} />
        </Grid>
            <Grid className="change-password-right-side-box">
            <Box id="wrapper-title" className="mdl-card__supporting-text input-fields">
                  <Box className="change-title" style={{ color: `${bgColor}` }}>{t('Change Password')}</Box>
                <Box className="form-group error-msg" id="reset-password-error"></Box>
                    <Box id="form-login" className="newform">
                    <Box component="form" className="change-password-form" id="resetPasswordForm" onSubmit={handleSetPassword} method="post">
                        <Box className="otg otg-items-1 password-box">
                        <Box className="otg-item mdc-text-field mdl-js-textfield mdc-text-field--floating-label" id="wrapper">
                            <Input name="newPassword" className="input-box required_text" id="newPassword" type="password"     onChange={handlePasswordChange}
                              onKeyDown={preventSpaceKey} placeholder="New Password" />
                            <InputLabel className="mdc-text-field__label" style={{ color: `${bgColor}` }} htmlFor="newPassword">{t('New Password')}</InputLabel>
                            <Icon className="fa fa-eye-slash toggle-password-icon eye-icon-align" id="toggleNewPassword" onClick={() => togglePasswordVisibility('newPassword', 'toggleNewPassword')}></Icon>
                        </Box>
                        </Box>
                        <Box className="otg otg-items-1">
                        <Box className="otg-item mdc-text-field mdl-js-textfield mdc-text-field--floating-label" id="wrapper">
                            <Input name="confirmPassword" className="input-box required_text" id="confirmPassword" type="password"     onChange={handlePasswordChange}
                                placeholder="Confirm Password" onKeyDown={preventSpaceKey} />
                            <InputLabel className="mdc-text-field__label" style={{ color: `${bgColor}` }} htmlFor="confirmPassword">{t('Confirm Password')}</InputLabel>
                            <Icon className="fa fa-eye-slash toggle-password-icon eye-icon-align" id="toggleConfirmPassword" onClick={() => togglePasswordVisibility('confirmPassword', 'toggleConfirmPassword')}></Icon>
                        </Box>
                        </Box>
                        <Box id="wrapper-btn" className="otg otg-items-1 form-box text-center otg-v-30">
                        <Button
                          type="submit"
                          id="changePasswordBtn"
                          className={`change-password-submit-button ${isButtonDisabled ? 'disabled-btn' : ''}`}
                          style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
                          disabled={isButtonDisabled} // Add the disabled attribute
                        >
                          {t('Submit')}
                        </Button>
                        <span id="messageError"></span>
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Grid>
    </Grid>
  );
};

export default ChangePassword;
