import status from '../constants/actionStatus';

export default (state = {
    notification: []
}, action = {}) => {
    switch (action.type) {
    case 'GET_NOTIFICATION_REQUEST':
        return {
            status: status.PENDING,
            notification: []
        };
    case 'GET_NOTIFICATION_SUCCESS':
        const combinedNotifications = [ ...state.notification, ...action.notificationList.data ];
        const uniqueNotifications = Array.from(new Set(combinedNotifications.map(JSON.stringify))).map(JSON.parse);
        return {
            status: status.SUCCESS,
            notification: uniqueNotifications
        };
    case 'COURSE_ADDED_NOTIFICATION_SUCCESS':
        return {
            ...state,
            notification: [ action.notificationRes, ...state.notification ]
        };
    case 'GET_NOTIFICATION_FAILURE':
        return {
            status: status.ERROR,
            notification: state.notification
        };
    default:
        return state;
    }
};
