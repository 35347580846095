import CUserImageIcon from "../CUserImageIcon";
import React, { useState, useEffect } from 'react';
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { Box } from '@material-ui/core';
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { useDispatch, useSelector } from "react-redux";
import NotificationBar from "../common/Header/NotificationBar";
import { useSearchQuery } from "../../services/SearchQueryContext";
import { getUser, updateProfileImageDetails } from "examsbook-react-sdk/actions/getUser";
import editIcon from '../../assets/images/editIcon.png';
import defaultImage from "../../assets/images/student.png";
import { useTheme } from "../../constants/themeProvider";



function UserProfile() {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const t = useTranslations();
    const [errorMessage, setErrorMessage] = useState(null);
    const theme =useTheme();

    const { setNewFlag } = useSearchQuery();
    useEffect(() => {
        setNewFlag(true);
        return () => setNewFlag(false);
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1024 * 1024) {
                setErrorMessage(t("Please upload profile image less than 1 MB"));
                return;
            }
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(fileType)) {
                setErrorMessage(t("Invalid file type. Only jpeg, jpg and png images are allowed"));
                return;
            }
            setErrorMessage(null);

            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const res = await dispatch(updateProfileImageDetails(user?._id, e.target.result, file.name));
                    if (res) {
                        dispatch(getUser())
                    }
                } catch (error) {
                    console.error("Error updating profile image:", error.message);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 767)
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const styles = {
        fragment: {
            background: '#FAFAFA',
        },
        editIconAlign: {
            height: '40px !important',
            width: '40px !important',
            cursor: 'pointer',
            border: '0px !important'
        },
        uploadIconDiv: {
            alignSelf: 'end',
            marginBottom: '7px',
            marginLeft: '-40px'
        },
        header: {
            display: 'flex',
            position: 'sticky',
            top: '0px'
        },
        title: {
            marginTop: 9,
            padding: '0px 26px 15px 20px',
            color: '#202020',
            fontSize: '2rem',
            fontWeight: 500,
            lineHeight: 'normal',
        },
        userProfile: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
            "& img": {
                borderRadius: '105px',
                height: '200px',
                width: '200px',
                objectFit: 'contain',
                border: `2px solid ${theme?.secondaryColor}`
            },
        },
        fileUploadMsg: {
            color: 'red',
            textAlign: 'center',
            paddingTop: '10px'
        },
        profileSection: {
            width: '340px',
            border: '1px solid #D7D7D7',
            boxShadow: '1px 1px 26px 0px rgba(132, 129, 138, 0.30)',
            paddingBottom: 15,
            borderRadius: '6px'
        },
        wrapper: {
            paddingTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 280px) and (max-width: 767px)': {
                paddingTop: 100,
                paddingBottom: 80
            },
        },
        userName: {
            color: '#000',
            textAlign: 'center',
            fontSize: '25px',
            fontWeight: 600,
            paddingTop: '20px',
        },
        location: {
            color: '#84818A',
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 300,
            paddingBottom: 24,
        },
        inputBox: {
            display: 'block',
            marginLeft: '15px',
            marginRight: '15px',
            height: '62px',
            border: '1px solid #D7D7D7',
            background: 'rgba(241, 241, 241, 0.93)',
            marginBottom: 20,
            borderRadius: '4px'
        },
        inputLabel: {
            color: '#B2B2B2',
            fontSize: '14px',
            fontWeight: 300,
            margin: '10px 0px 2px 13px'
        },
        inputField: {
            color: '#6C6C6C',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: 400,
            margin: '0px 0px 10px 13px'
        },
        '@media (min-width: 768px) and (max-width: 900px)': {
            header: {
              marginTop: '75px',
              marginBottom: '10px'
            }
        },
        '@media (min-width: 900px) and (max-width: 900px)': {
            header: {
              marginTop: '29px',
              marginBottom: '10px'
            }
        },
        '@media (min-width: 901px) and (max-width: 959px)': {
            header: {
              marginTop: '74px',
              marginBottom: '10px'
            }
        }
    };
    const classes = useStyles(styles)();

    return (
        <>
            <div className={classes.header}>
                {!isMobile &&
                    <div className={classes.title}>{t("my_profile")}</div>
                }
                <Box className="notification-bar" style={{ marginLeft: 'auto', marginTop: '-5px', paddingRight: '4px' }}>
                    <NotificationBar />
                </Box>
            </div>
            <div className={classes.wrapper}>
                <Box className={classes.profileSection}>
                    <div className={classes.userProfile}>
                        <CUserImageIcon src={user.image !=='' ? `${user.image}`: defaultImage} />
                        <div className={classes.uploadIconDiv}>
                            <input
                                type="file"
                                accept=".jpeg, .jpg, .png"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                                id="upload-image"
                            />

                            <label htmlFor="upload-image" >
                                <img alt="" src={editIcon} className={classes.editIconAlign} />
                            </label>
                        </div>
                    </div>
                        {errorMessage && <div className={classes.fileUploadMsg}>{errorMessage}</div>}
                    <div className={classes.userName}>{user.name}</div>
                    <div className={classes.location}>{user.city}</div>
                    {user?.email &&
                    <Box className={classes.inputBox}>
                        <div className={classes.inputLabel}>{t("email")}</div>
                        <div className={classes.inputField} style={{textTransform: 'lowercase'}}>{user?.email}</div>
                    </Box>
                    }
                    {user?.mobileNumber &&
                    <Box className={classes.inputBox}>
                        <div className={classes.inputLabel}>{t("phone-number")}</div>
                        <div className={classes.inputField}>{user?.mobileNumber}</div>
                    </Box>
                    }
                    {user?.role &&
                    <Box className={classes.inputBox}>
                        <div className={classes.inputLabel}>{t("Role")}</div>
                        <div className={classes.inputField}>{user?.role}</div>
                    </Box>
                    }
                </Box>
            </div>
        </>
    );
}

export default UserProfile;
