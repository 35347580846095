import status from "../constants/actionStatus";

export default (
    state = {
        selfLearningData: {},
    },
    action = {}
) => {
    switch (action.type) {
    case "SELF_LEARNING_REQUEST":
        return {
            status: status.PENDING,
            selfLearningData: {},
        };
    case "SELF_LEARNING_SUCCESS":
        return {
            status: status.SUCCESS,
            selfLearningData: action.payload,
        };

    case "SELF_LEARNING_FAILURE":
        return {
            status: status.ERROR,
            selfLearningData: {},
        };
    case "CLEAR_SELF_LEARNING":
        return { status: status.PENDING, selfLearningData: {} };
    default:
        return state;
    }
};
