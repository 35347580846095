export default (state = {}, action = {}) => {
    switch (action.type) {
    case 'QUIZ_FEEDBACK_REQUEST':
        return { id: action.id, data: action.data };
    case 'GET_QUIZ_FEEDBACK_SUCCESS':
        return action.quizData;
    case 'GET_QUIZ_FEEDBACK_FAILURE':
        return state;
    case 'FEEDBACK_REQUEST':
        return { data: action.data };
    case 'GET_FEEDBACK_SUCCESS':
        return action.feedbackRes;
    case 'GET_FEEDBACK_FAILURE':
        return state;
    default:
        return state;
    }
}
