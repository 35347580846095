const videoPlayerStyles = {
  container: {
    position: "relative",
    width: "100%",
    height: 400,
    backgroundColor: "#121212",
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  video: {
    position: "absolute",
    top: "0",
    left: "0",
  },
};

export default videoPlayerStyles;
