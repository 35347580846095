import status from "../constants/actionStatus";

export default (
    state = {
        course: [],
    },
    action = {}
) => {
    switch (action.type) {
    case "COURSE_LIST_REQUEST":
        return {
            status: status.PENDING,
            course: [],
        };
    case "COURSE_LIST_SUCCESS":
        return {
            status: status.SUCCESS,
            course: action.payload,
        };

    case "COURSE_LIST_FAILURE":
        return {
            status: status.ERROR,
            course: [],
        };
    default:
        return state;
    }
};
