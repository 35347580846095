import { addToRecentCourse } from "../services/courseService";

export const recentCourseAction = (data) => (dispatch) => {
    dispatch(request());
    return addToRecentCourse(data)
        .then((response) => {
            // handle success
            dispatch(success(response));
            return response;
        })
        .catch((error) => {
            console.log("error", error);
            // handle error
            dispatch(failure(error.toString()));
        });

    function request() {
        return { type: "ADD_TO_RECENT_COURSE_REQUEST" };
    }

    function success(response) {
        return { type: "ADD_TO_RECENT_COURSE_SUCCESS", payload: response };
    }

    function failure(error) {
        return { type: "ADD_TO_RECENT_COURSE_FAILURE", payload: error };
    }
};
