import axios from "./axiosConfig";

export function genericLogin(data) {
    return axios
        .post("/auth/login", data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
