let drawer = false;
const initialState = !!drawer;
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case 'SHOW_DRAWER':
        drawer = state;
        return action.payload;

        // case 'HIDE_DRAWER':
        //     drawer = state;
        //     return action.drawer;

    default:
        return state;
    }
}
